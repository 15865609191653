<template lang="">
    <div class="flex flex-col gap-y-2">
        <div class="flex flex-col gap-y-3">
            <div class="flex flex-row justify-start items-center gap-x-2 text-slate-700 font-semibold">
                <h4 class="w-full bg-blue-50 p-2 font-bold">Mes factures</h4>
                <span></span>
            </div>
            <div class="flex flex-col " v-if="nbBasique != 0">
                <div class="w-full flex justify-between bg-slate-50 p-2 mb-1 rounded-sm items-center" @click="isOpen.basique=!isOpen.basique">
                    <div class="flex flex-row justify-start  items-center gap-x-2 text-slate-700 font-semibold">
                        <h4>Basique</h4>
                        <span>{{ nbBasique }}</span>
                    </div>
                    <div class="fas fa-angle-right transition duration-300" :class="isOpen.basique?'rotate-90':'rotate-0'">
                    </div>
                </div>
                <div class="flex flex-col gap-y-3" v-if="isOpen.basique">
                    <div v-if="nbBasique != 0">
                        <div class='bg-slate-100 border-slate-200 rounded-sm border w-full p-2' v-for="userBasic in userBasics" :key="userBasic.idabo">
                            <div class='w-full flex justify-between items-center'>
                                <span class=''>
                                
                                                                                    <span class='font-semibold'>Région : </span>
                                <span class='text-slate-500'> {{ userBasic.region }} </span>
                                </span>
                                <span class='font-semibold text-red-500 text-lg'>10</span>
                            </div>
                            <div class='flex justify-start items-center gap-x-20'>
                                <span class=''>
                                
                                                                                    <span class='font-semibold'>Début : </span>
                                <span class='text-slate-500'>{{ userBasic.dateDebut }}</span>
                                </span>
                                <span class=''>
                                
                                                                                    <span class='font-semibold'>Fin : </span>
                                <span class='text-slate-500'>{{ userBasic.dateFin }}</span>
                                </span>
                            </div>
                            <div class="w-full flex justify-end items-center">
                                <button @click="generer_pdf(nomUser,adresseUser,userBasic.description,userBasic.dateDebut,userBasic.dateFin,userBasic.montant)" class="flex justify-center items-center gap-x-3 h-7 px-3 text-blue-600">
                                        <span class="fas fa-download"></span>
                                        <span>Télécharger la facture</span>
                                    </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="flex flex-col " v-if="nbStandard != 0">
                <div class="w-full flex justify-between bg-slate-50 p-2 mb-1 rounded-sm items-center" @click="isOpen.standard=!isOpen.standard">
                    <div class="flex flex-row justify-start items-center gap-x-2 text-slate-700 font-semibold">
                        <h4>Standard</h4>
                        <span>{{ nbStandard }}</span>
                    </div>
                    <div class="fas fa-angle-right transition duration-300" :class="isOpen.standard?'rotate-90':'rotate-0'">
                    </div>
                </div>
                <div class="flex flex-col gap-y-3" v-if="isOpen.standard">
                    <div v-if="nbStandard != 0">
                        <div class='bg-slate-100 border-slate-200 rounded-sm border w-full p-2' v-for="userStandard in userStandards" :key="userStandard.idabo">
                            <div class='w-full flex justify-between items-center'>
                                <span class=''>
                                
                                                                                    <span class='font-semibold'>Province : </span>
                                <span class='text-slate-500'> {{ userStandard.province }} </span>
                                </span>
                                <span class='font-semibold text-red-500 text-lg'>{{userStandard.montant}}</span>
                            </div>
                            <div class='flex justify-start items-center gap-x-20'>
                                <span class=''>
                                
                                                                                    <span class='font-semibold'>Début : </span>
                                <span class='text-slate-500'>{{ userStandard.dateDebut }} </span>
                                </span>
                                <span class=''>
                                
                                                                                    <span class='font-semibold'>Fin : </span>
                                <span class='text-slate-500'> {{ userStandard.dateFin }} </span>
                                </span>
                            </div>
                            <div class="w-full flex justify-end items-center">
                                <button @click="generer_pdf(nomUser,adresseUser,userStandard.description,userStandard.dateDebut,userStandard.dateFin,userStandard.montant)" class="flex justify-center items-center gap-x-3 h-7 px-3 text-blue-600">
                                        <span class="fas fa-download"></span>
                                        <span>Télécharger la facture</span>
                                    </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="flex flex-col " v-if="nbPremium != 0">
                <div class="w-full flex justify-between bg-slate-50 p-2 mb-1 rounded-sm items-center" @click="isOpen.premium=!isOpen.premium">
                    <div class="flex flex-row justify-start items-center gap-x-2 text-slate-700 font-semibold">
                        <h4>Premium</h4>
                        <span>{{ nbPremium }}</span>
                    </div>
                    <div class="fas fa-angle-right transition duration-300" :class="isOpen.premium?'rotate-90':'rotate-0'">
                    </div>
                </div>
                <div class="flex flex-col gap-y-3" v-if="isOpen.premium">
                    <div v-if="nbPremium != 0">
                        <div class='bg-slate-100 border-slate-200 rounded-sm border w-full p-2'>
                            <div class='w-full flex justify-between items-center'>
                                <span class=''>
                                
                                                                                <span class='font-semibold'>Région : </span>
                                <span class='text-slate-500'> userBasic.region </span>
                                </span>
                                <span class='font-semibold text-red-500 text-lg'>10</span>
                            </div>
                            <div class='flex justify-start items-center gap-x-20'>
                                <span class=''>
                                
                                                                                <span class='font-semibold'>Début : </span>
                                <span class='text-slate-500'>userBasic.dateDebut </span>
                                </span>
                                <span class=''>
                                
                                                                                <span class='font-semibold'>Fin : </span>
                                <span class='text-slate-500'> userBasic.dateFin</span>
                                </span>
                            </div>
                            <div class="w-full flex justify-end items-center">
                                <button class="flex justify-center items-center gap-x-3 h-7 px-3 text-blue-600">
                                        <span class="fas fa-download"></span>
                                        <span>Télécharger la facture</span>
                                    </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import axios from "axios";
    import apiUrl from "@/ApiConfig";
    import jsPDF from 'jspdf';
    
    // eslint-disable-next-line no-unused-vars
    import autoTable from 'jspdf-autotable';
    export default {
        data() {
            return {
                nbBasique: '',
                nbStandard: '',
                nbPremium: '',
                userBasics: [],
                userStandards: [],
                isOpen: {
                    basique: false,
                    standard: false,
                    premium: false
                },
                iduser: "",
                adresseUser: "",
                nomUser: ""
            }
        },
        mounted() {
            this.getNombreBasique(), this.getNombreStandard(), this.getNombrePremium(), this.getUserBasique(), this.getUserStandard()
            this.getUserAdresse()
            this.getUserName()
        },
        methods: {
            getUserName() {
                axios.get(apiUrl + 'api/user_name', {
                    params: {
                        iduser: localStorage.getItem('iduser')
                    }
                }).then(response => {
                    this.nomUser = response.data[0].name;
                }).catch(error => {
                    console.log(error)
                })
            },
            getUserAdresse() {
                axios.get(apiUrl + 'api/user_adresse', {
                    params: {
                        iduser: localStorage.getItem('iduser')
                    }
                }).then(response => {
                    this.adresseUser = response.data[0].adresse;
                }).catch(error => {
                    console.log(error)
                })
            },
            getNombreBasique() {
                axios.get(apiUrl + 'api/nombre_basique_user', {
                    params: {
                        iduser: localStorage.getItem('iduser')
                    }
                }).then(response => {
                    this.nbBasique = response.data.nombreBasique;
                    // console.log(response.data)
                    // eslint-disable-next-line no-undef
                }).catch(error => {
                    console.log(error)
                })
            },
            getNombreStandard() {
                axios.get(apiUrl + 'api/nombre_standard_user', {
                    params: {
                        iduser: localStorage.getItem('iduser')
                    }
                }).then(response => {
                    this.nbStandard = response.data.nombreStandard;
                    // console.log(response.data)
                    // eslint-disable-next-line no-undef
                }).catch(error => {
                    console.log(error)
                })
            },
            getNombrePremium() {
                axios.get(apiUrl + 'api/nombre_premium_user', {
                    params: {
                        iduser: localStorage.getItem('iduser')
                    }
                }).then(response => {
                    this.nbPremium = response.data.nombrePremium;
                    // console.log(response.data)
                    // eslint-disable-next-line no-undef
                }).catch(error => {
                    console.log(error)
                })
            },
            getUserBasique() {
                axios.get(apiUrl + 'api/getUserBasique', {
                        params: {
                            iduser: localStorage.getItem('iduser'),
                        }
                    })
                    .then(response => {
                        this.userBasics = response.data
                        console.log(response.data)
                    })
                    .catch(error => {
                        console.log(error)
                    })
            },
            getUserStandard() {
                axios.get(apiUrl + 'api/getUserStandard', {
                        params: {
                            iduser: localStorage.getItem('iduser'),
                        }
                    })
                    .then(response => {
                        this.userStandards = response.data
                        console.log(response.data)
                    })
                    .catch(error => {
                        console.log(error)
                    })
            },
            generer_pdf: function(nomUser, adresseUser, description, dateDebut, dateFin, prix) {
                const pdf = new jsPDF('p', 'mm', [210, 297]);
                // const img = require('@/assets/logoAgri.jpg')
                let yPos = 20; // Position verticale de départ
                const margin = 10; // Marge supérieure
                yPos += 10; // Augmentez la position verticale pour la prochaine ligne de texte
                const pageHeight = pdf.internal.pageSize.height;
                if (yPos + 10 > pageHeight) {
                    pdf.addPage();
                    yPos = margin;
                }
                const date = new Date()
                const y = 10
                pdf.setFont('Arial', 'bold')
                pdf.setFontSize(20)
                pdf.setTextColor('blue')
                pdf.text('SEERA', 195, 20, 'right')
                pdf.setTextColor('blue')
                pdf.text('PAYEE', 30, 30, 'left')
                pdf.setFont('Arial', 'normal')
                pdf.setFontSize(12)
                pdf.setTextColor('#1B1B1B')
                pdf.text('Lot VK 17 à Fenomanana Mahazoarivo Antananarivo', 195, 30, 'right')
                pdf.text('Madagascar', 195, 38, 'right')
                pdf.setFont('Arial', 'bold')
                pdf.setFontSize(12)
                pdf.setTextColor('black')
                pdf.text('Facture d\'abonnement', 14, 48, 'left')
                pdf.setFont('Arial', 'normal')
                pdf.setFontSize(12)
                pdf.setTextColor('#1B1B1B')
                pdf.text('Date de facturation :', 14, 58, 'left')
                pdf.text(dateDebut, 60, 58), 'left';
                pdf.text('Date d\'echeance :', 14, 68, 'left')
                pdf.text(dateFin, 60, 69, 'left');
                pdf.setFont('Arial', 'bold')
                pdf.setFontSize(12)
                pdf.setTextColor('black')
                pdf.text('Facture à', 14, 83, 'left')
                pdf.setFont('Arial', 'normal')
                pdf.setFontSize(12)
                pdf.setTextColor('#1B1B1B')
                pdf.text("Nom: ", 14, 93, 'left')
                pdf.text("Adresse:", 14, 103, 'left')
                pdf.text("Description:", 14, 113)
                pdf.text(nomUser, 25, 93, 'left')
                pdf.text(adresseUser, 30, 103, 'left')
                pdf.text(description, 35, 113)
                pdf.setFontSize(12)
                const startY = 123
                pdf.autoTable({
                    head: [
                        ['Description', 'Total']
                    ],
                    body: [
                        ['Abonnement SEERA ', prix],
                        [{
                            content: 'Sous-total',
                            styles: {
                                halign: 'right'
                            }
                        }, prix],
                        [{
                            content: 'Total',
                            styles: {
                                halign: 'right'
                            }
                        }, prix]
                        
                    ],
                    startY: startY,
                });
                pdf.output('dataurlnewwindow')
            },
        }
    }
</script>
<style lang="">

</style>
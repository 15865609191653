<template>
    <div class="flex  justify-around items-end">
        <div class="flex gap-3 flex-wrap  justify-around  items-end">
            <div class="flex justify-end items-end gap-3">

                <div class="flex w-fit h-fit md:gap-10 justify-center items-center mx-2 mb-1 p-1 shadow shadow-gray-500 rounded">
                <div class="fa fa-table-cells  text-gray-500 text-xl " @click="modal_toggle_list(false)" v-if="mode==true"></div>
                <div class="fa fa-table-list  text-gray-500   text-xl " @click="modal_toggle_list(true)" v-else-if="mode==false"></div>

            </div>
        </div>
    </div>
        
    </div>
    

</template>
<script>
    export default{
        // eslint-disable-next-line vue/multi-word-component-names
        name:'Filtre',
        props:{
            show_btn_ajout:Boolean,
            
        },mounted(){
            this.$emit('data_categrorie',this.receive_data_categorie)
        },
        data(){
            return{
                btn_ajout:this.$props.show_btn_ajout,
                windowWidth:window.innerWidth,
                modal_ajout:false,
                toggleList:false,
                //donne de formulaire de recherche 
                inp_prix:'',
                inp_sel_categorie:'',
                inp_sel_prix:'',
                inp_search:'',
                mode:localStorage.getItem('seera_list_mode')!==null?JSON.parse(localStorage.getItem('seera_list_mode')):true,
            }
        },methods:{
            // eslint-disable-next-line no-unused-vars
            receive_data_categorie(data){
                this.Ca
        },
            modal_toggle_list(data){
                this.mode=data
                localStorage.setItem('seera_list_mode',data)
                this.$emit('data_list',data)
                
            },
            send_filter(){
                this.$emit('data_filter',this.inp_prix,this.inp_sel_categorie,this.inp_sel_prix,this.inp_search)
            },modal_ajout_show(){
                
                this.$emit('modal_ajout_show',!this.modal_ajout)
                
            },
        }
    }
</script>
<style>
   
</style>
<template>
  <div class="w-screen h-full ">
    <div id="Accueil" class="overflow-hidden px-2 md:h-[600px]">
      <div class="w-screen  h-full">
        <div class="flex flex-col overflow-hidden w-full sm:h-full">
          <div class="h-full w-full flex justify-center items-center overflow-hidden">
            <div class="text-white flex h-full  flex-col text-center justify-center gap-5 items-center max-sm:m-[50px] lg:mt-[1px]">
              <div class="text-center max-md:w-full  md:w-11/12 lg:w-8/12">
                <h1 class="font-extrabold max-md:text-lg md:text-2xl lg:text-4xl overflow-hidden ">
                  La technologie est au cœur de notre entreprise, nous permettant de fournir des informations agricoles.
                </h1>
                <p class="text-slate-300 test-sm pt-2" >
                  Inscrivez-vous pour accéder à des informations exclusives!
                </p>
              </div>
              <div class="flex max-md:flex-col max-md:w-11/12 md:w-10/12 lg:w-6/12 justify-center items-center gap-x-4 max-md:gap-y-2">
                <router-link to="/signup" class="border-2 active:bg-gray-600 active:bg-opacity-50 py-1 px-4 h-8 w-full border-white rounded">
                  S'inscrire
                </router-link>
                <router-link to="/signin" class="border-2 hover:bg-red-500 w-full active:bg-red-500 hover:border-red-500 active:border-red-500 bg-red-400  py-1 px-4 h-8 border-red-400 rounded">
                  Se connecter
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal_img   md:h-[600px] overflow-hidden">
        <img src="./../../assets/header_av.png" class="w-screen max-md:h-96 h-max md:h-full lg:full overflow-hidden" alt="img">
      </div>
    </div>
    <Header :modal_menu="modal_menu" @toggle="modal_menu=!modal_menu"></Header>
    <br><br>
    <div class="w-screen scroll-smooth transition-all duration-100 flex flex-col justify-center items-center">
      <Service></Service>
      <br><br>
      <Partenaire></Partenaire>
      <div>
      </div>
      <About></About>
      <div>
        <Pricing />
      </div>
      <Contact></Contact>
    </div>
    <Footer></Footer>
  </div>
  <Menu_landing :modal_menu="modal_menu" @close="modal_menu=false"></Menu_landing>
</template>
<script>
  // import Header from '@/components/Client/Header.vue';
  import Footer from '@/components/Client/Footer.vue';
  import $ from 'jquery';
  import About from '@/components/Client/About.vue';
  import Pricing from '@/components/Client/Pricing.vue';
  import Service from '@/components/Client/Service.vue';
  import Contact from '@/components/Client/Contact.vue';
  import Partenaire from '@/components/Client/Partenaire.vue';
  import Menu_landing from '@/components/Client/menu/Menu_lainding.vue'
  import Header from '@/components/Client/Header.vue';
  export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: 'Accueil',
    components: {
      Header,
      Footer,
      Pricing,
      Service,
      Contact,
      Partenaire,
      About,
      Menu_landing
    },
    data() {
      return {
        sections: [{
            id: 'Accueil',
            title: 'Accueil'
          },
          {
            id: 'Service',
            title: 'Service'
          },
          {
            id: 'About',
            title: 'A propos'
          },
          {
            id: 'Pricing',
            title: 'Nos offres'
          },
          {
            id: 'Contact',
            title: 'Contact'
          },
        ],
        // modal_menu: false,
        togglewidth: {
          width: '0'
        },
        position: {
          x: 0,
          y: 0
        },
        modal_menu: false,
      }
    },
    mounted() {},
    methods: {}
  }
</script>
<style scoped>
  * {
    overflow-x: hidden;
    transition: all 200ms;
    scroll-behavior: smooth;
  }
  .v-enter-from {
    transform: translateX(1000px);
  }
  .v-leave-to {
    transform: translateX(1000px);
  }
  .v-enter-active {
    transition: transform 0.5s;
  }
  .v-leave-active {
    transition: transform 0.5s;
  }
</style>
<template lang="">
    <div id="About">
        <div class="grid justify-center text-center">
          <div class="px-12 py-5">
            <h1 class="text-2xl font-bold">A propos</h1><br>
            <p class=" text-justify text-slate-500 ">
              Notre plateforme web est bien plus qu'un simple espace en ligne. Nous sommes une passerelle vers de nombreuses opportunités dans le monde de l'agribusiness. Grâce à notre service, vous pouvez accéder à une vaste liste de clients qui recherchent des produits
              de qualité et des partenariats solides. Nous mettons également à votre disposition un éventail de fournisseurs dans tous les domaines de l'agriculture et de l'élevage, garantissant que vous ayez accès aux intrants nécessaires pour soutenir
              votre activité. De plus, pour ceux qui cherchent à développer leurs projets agricoles, nous avons une communauté d'investisseurs prêts à soutenir des initiatives prometteuses. Notre engagement envers la réussite de nos utilisateurs est au
              cœur de notre mission, et nous sommes fiers de jouer un rôle essentiel dans la croissance et la prospérité de l'agribusiness. Rejoignez-nous dès aujourd'hui pour exploiter ces opportunités sans précédent.
            </p><br><br>
          </div>
        </div>
      </div>
</template>
<script>
export default {

}
</script>
<style lang="">

</style>
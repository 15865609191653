<template lang="">
    <div id="Service" class="flex flex-col w-full justify-start items-center text-center gap-y-6 bg-slate-50 py-7">
        <div class="max-md:mx-4">
          <h1 class="text-2xl font-bold">Nos services</h1><br>
          <p class=" ">
            Découvrez notre service, conçues pour répondre à vos besoins.
          </p>
        </div>
        <div class="flex md:h-72 max-md:flex-col max-md:gap-y-4  justify-center max-lg:gap-x-6 lg:gap-x-12 items-start p-2 w-11/12">
          <div class="w-full md:h-full  text-center flex flex-col justify-center items-center gap-2  bg-white shadow-sm shadow-gray-300 p-2  rounded">
            <div class="text-red-400 fas fa-hand-holding-droplet fa-2x"></div>
            <div class="">
              <div>
                <h1 class="font-bold">Client</h1><br>
              </div>
              <div class="break-all">
                <p class=" text-gray-600 ">
                  Découvrez notre large clientèle diversifiée,
                  englobant tous les domaines
                  de l'agriculture et de l'élevage.
                </p>
              </div>
            </div>
          </div>
          <div class="w-full md:h-full text-center flex flex-col justify-center items-center gap-2 bg-white shadow-sm shadow-gray-300 p-2 rounded">
            <div class="text-red-400 fas fa-hands-holding-circle fa-2x"></div>
            <div class="">
              <div>
                <h1 class="font-bold">Fournisseur</h1><br>
              </div>
              <div class="break-all">
                <p class=" text-gray-600">
                  Découvrez une multitude de fournisseurs dans
                  tous les domaines de l'agriculture
                  et de l'élevage sur notre plateforme.
                </p>
              </div>
            </div>
          </div>
          <div class="w-full md:h-full text-center flex flex-col justify-center  items-center gap-2  bg-white shadow-sm shadow-gray-300 p-2 rounded">
            <div class="text-red-400 fas fa-hand-holding-dollar fa-2x"></div>
            <div class="">
              <div>
                <h1 class="font-bold">Investisseur</h1><br>
              </div>
              <div class="break-all">
                <p class=" text-gray-600 ">
                  Découvrez un éventail d'investisseurs
                  enthousiastes et prêts à investir
                  dans des opportunités prometteuses.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="w-screen max-lg:px-8 lg:px-4 max-md:gap-y-5">
        <div class="w-full h-fit flex max-md:flex-col-reverse justify-center max-md:gap-y-3  items-center  my-8">
          <div class="flex flex-col justify-center  max-sm:p-3 md:p-2 items-center md:w-6/12">
            <div class="md:pl-9">
              <h1 class="max-md:text-xl md:text-3xl hidded font-semibold">Conseil en gestion agricole </h1>
              <p class="text-slate-500 text-justify">
                Nos services de conseil en gestion agricole sont conçus pour optimiser
                la rentabilité de votre exploitation, en vous offrant
                des solutions sur mesure pour une agriculture plus prospère.
              </p>
            </div>
          </div>
          <div class="flex justify-center items-center md:w-5/12">
            <img src="./../../assets/undraw_add_to_cart_re_wrdo.svg" alt="" class="max-md:w-full md:w-[50%]">
          </div>
        </div>
        <div class="w-full h-fit  flex justify-center items-center my-8  gap-4 max-md:flex-col">
          <div class="flex justify-center items-center md:w-3/12">
            <img src="./../../assets/undraw_business_shop_re_ruf4.svg" alt="" class="w-full">
          </div>
          <div class="flex justify-center  max-sm:p-6 md:p-2 items-center md:w-7/12">
            <div class="">
              <h1 class="max-md:text-xl md:text-3xl hidded font-semibold">Fourniture de matériel agricole</h1>
              <p class="text-slate-500 text-justify">
                Nous fournissons une vaste gamme de matériel agricole
                de haute qualité pour soutenir votre succès dans le secteur agricole,
                parce que le bon outil fait toute la différence.
              </p>
            </div>
          </div>
        </div>
        <div class="w-full  h-fit flex   justify-center items-center my-8 lg:px-8 max-md:flex-col-reverse max-md:gap-y-3">
          <div class="flex flex-col  h-fit max-md:px-3  justify-center items-center md:w-5/12">
            <div class="h-fit w-full">
              <h1 class="max-md:text-xl md:text-3xl hidded font-semibold w-full">Commercialisation des produits agricoles </h1>
              <p class="text-slate-500 text-justify">
                Nous mettons en œuvre des stratégies de commercialisation
                de produits pour assurer la réussite de votre entreprise,
                en maximisant la valeur de vos récoltes sur les marchés locaux.
              </p>
            </div>
          </div>
          <div class="flex justify-center items-center md:w-5/12">
            <img src="./../../assets/undraw_add_to_cart_re_wrdo.svg" alt="" class="max-md:w-full sm:w-[50%]">
          </div>
        </div>
      </div>
</template>
<script>
export default {

}
</script>
<style lang="">

</style>
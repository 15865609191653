<template>
    <div class="gray max-lg:w-screen lg:w-10/12 float-right h-screen flexbox  justify-center">
        <div class="bg-white max-sm:p-2 flex sm:h-fit mx-4  items-center rounded mb-3">
            <div class="flex p-2 justify-center items-center lg:hidden">
                <div class="fas fa-bars fa-2x text-gray-600 cursor-pointer" @click="modal_menu = !modal_menu"></div>
            </div>
            <Header :name_page="name_page" class="p-2"></Header>
        </div>
        <div class="bg-white h-fit  w-12/12 mx-4 justify-end items-center py-2 px-1 rounded ">
            <div class="flex justify-between w-12/12 px-1 items-center md:gap-6">
                <button class="flex gap-x-2  bg-blue-500 text-white h-8 px-3 rounded justify-center items-center cursor-pointer" @click="modal_ajout=true">
                        
                                                        <span class="fa fa-plus"></span>
                        
                                                        <span>
                        
                                                            Ajouter
                        
                                                        </span>
                        
                                                    </button>
                <div class="flex justify-end items-center w-7/12">
                    <div class="flex  items-center w-fit h-fit justify-end border rounded border-gray-400">
                        <div class="flex">
                            <input type="text" name="" id="" v-model="search" placeholder="Rechercher... " class="p-1 focus:outline-none placeholder:text-gray-300 ">
                        </div>
                        <div class="flex justify-end items-center ">
                            <div class="fa fa-search p-1 cursor-pointer "></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!--table-->
        <div class="bg-white h-fit mx-4 flex items-center justify-center rounded my-3 ">
            <div class="w-full p-2 ">
                <table class="max-w-full w-full ">
                    <thead>
                        <tr class="text-sm text-left ">
                            <th>ID</th>
                            <th>NOM</th>
                            <th>Lien</th>
                            <th>ACTIONS</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="partenaire in partenaires" :key="partenaire.id">
                            <td>{{partenaire.id}}</td>
                            <td>{{partenaire.nom}}</td>
                            <td>
                                <!-- <span v-if="partenaire.logo == undefined "></span>
                                <img v-else src="" /> -->
                                {{partenaire.lien}}
                            </td>
                            <!-- <td>{{partenaire.lien}}</td> -->
                            <td class=" flex justify-start items-center gap-x-2 ">
                                <button @click="modal_modif=true, id2 = partenaire.id, nom2 = partenaire.nom, lien2 = partenaire.lien" class="fa fa-pencil-alt text-orange-500 "></button>
                                <button @click="modal_suppr=true, id2 = partenaire.id" class="fa fa-trash text-red-600 "></button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
    <Menu></Menu>
    <Sidebar :modal_menu="modal_menu " @close="modal_menu=false "></Sidebar>
    <!-- Modal ajout -->
    <Modal :isOpen="modal_ajout " @close="modal_ajout=false ">
        <h1 class="w-full text-center font-bold ">Ajout de partenaire</h1>
        <form @submit.prevent="ajoutPartenaire " class="w-full flex flex-col justify-start items-start gap-y-3 ">
            <div class="flex flex-col justify-start items-start gap-y-1 w-full ">
                <label class="font-semibold text-slate-700 ">Nom</label>
                <input type="text " name="label " class="input text-slate-600 " v-model="nom" />
            </div>
            <div class="flex flex-col justify-start items-start gap-y-1 w-full">
                
                <label class="font-semibold text-slate-700">Lien</label>
                <input type="text" name="label" class="input text-slate-600" v-model="lien" />
            </div>
            <div class="flex flex-col justify-start items-start gap-y-1 w-full ">
                <label class="font-semibold text-slate-700 ">Logo</label>
                <input type="file" class="rounded border w-full border-gray-300 p-1" ref="imageInput" name="Fichier" @change="handleFileChange" >
            </div>
            <div class="w-full flex justify-between items-center gap-x-2 font-semibold">
                <button type="button" @click="modal_ajout=false" class="w-full btn border border-slate-600">Annuler</button>
                <button type="submit" class="btn w-full text-white bg-slate-800">Ajouter</button>
            </div>
        </form>
    </Modal>
    <Modal :isOpen="modal_modif" @close="modal_modif=false">
        <h1 class="w-full text-center font-bold">Modification de partenaire</h1>
        <form @submit.prevent="modificationPartenaire" class="w-full flex flex-col justify-start items-start gap-y-3">
            <div class="flex flex-col justify-start items-start gap-y-1 w-full">
                <div class="flex flex-col justify-start items-start gap-y-1 w-full">
                    <input type="hidden" name="label" class="input text-slate-600" v-model="id2" />
                </div>
                <label class="font-semibold text-slate-700">Nom</label>
                <input type="text" name="label" class="input px-2 text-slate-600" v-model="nom2" />
            </div>
            <div class="flex flex-col justify-start items-start gap-y-1 w-full">
                
                <label class="font-semibold text-slate-700">Lien</label>
                <input type="text" name="label" class="input px-2 text-slate-600" v-model="lien2" />
            </div>
            <!-- <div class="flex flex-col justify-start items-start gap-y-1 w-full">
                <label class="font-semibold text-slate-700">Logo</label>
                <input type="file" name="label" class="input" @change="(e)=>{update.logo=e.target.files[0]}" />
            </div> -->
            <div class="w-full flex justify-between items-center gap-x-2 font-semibold">
                <button type="button" @click="modal_modif=false" class="w-full btn border border-slate-600">Annuler</button>
                <button type="submit" class="btn w-full text-white bg-slate-800">Modifier</button>
            </div>
        </form>
    </Modal>
    <Modal :isOpen="modal_suppr" @close="modal_suppr =false">
        <h1 class="w-full text-center font-bold">Suppression de partenaire</h1>
        <form @submit.prevent="suppressionPartenaire" class="w-full flex flex-col justify-start items-start gap-y-3">
            
            <div class=" w-full flex items-center justify-center mt-4">
                <h2>Voulez-vous supprimer ce partenaire ?</h2>
                <input type="hidden" name="label" class="input text-slate-600" v-model="id2" />
            </div>
            <div class="w-full flex justify-between items-center gap-x-2 font-semibold">
                <button type="button" @click="modal_suppr=false" class="w-full btn border border-slate-600">Annuler</button>
                <button type="submit" class="btn w-full text-white bg-red-700">Supprimer</button>
            </div>
        </form>
    </Modal>
</template>

<script>
    import apiUrl from '../../../ApiConfig';
    import axios from 'axios'
    import Menu from '@/components/Admin/Menu.vue'
    import Header from '@/components/Admin/Header.vue'
    import Filtre from '@/components/Admin/Filtre.vue'
    import Modal from "@/components/modal/modal.vue"
    import Sidebar from '@/components/Admin/sidebar/sidebar.vue'
    
    import '@/assets/styleAdmin/page.css'
    export default {
        name: "admin_partenaire",
        components: {
            Header,
            Filtre,
            Modal,
            Sidebar,
            Menu
        },
        data() {
            return {
                nom: '', logo: '', lien: '',
                nom2: '', logo2: '', lien2: '', id2: '',
                modal_menu: false,
                modal_ajout: false,
                modal_modif: false,
                modal_suppr: false,
                partenaires: [],
                name_page: "PARTENAIRE",
                search: ""
            }
        },
        mounted() {
            this.fetchData()
        },
        methods: {
            fetchData() {
                try {
                    axios.get(apiUrl + "api/affichage_partenaire")
                        .then(response => {
                            this.partenaires = response.data
                        })
                        .catch(error => {
                            console.error(error)
                        })
                } catch (error) {
                }
            },

            handleFileChange(event) {
                this.logo = event.target.files[0];
            },

            ajoutPartenaire() {
                try {
                    const formData = new FormData();
                    formData.append("nom", this.nom);
                    formData.append("logo", this.logo);
                    formData.append("lien", this.lien);

                    axios.post(apiUrl + "api/ajout_partenaire", formData).then(response => {
                        //console.log('Partenaire ajouté avec succès');
                        this.modal_ajout=false;
                        this.fetchData();
                        this.nom = ""; this.logo = ""; this.lien = "";
                    }).catch(error => {
                        (console.log(error))
                    })
                } catch (error) {
                    console.error(error)
                }
            },
            
            modificationPartenaire() {
                try {
                    axios.post(apiUrl + 'api/modification_partenaire', {
                        id: this.id2,
                        nom: this.nom2, lien: this.lien2
                    }).then(response => {
                        this.modal_modif=false;
                        this.fetchData();
                        this.id2 = ""; this.nom2 = "", this.lien2 = ""
                    }).catch (error => {
                        (console.log(error))
                    })
                } catch (error) {
                    console.log(error)
                }
            },

            suppressionPartenaire() {
                try {
                    axios.post(apiUrl + 'api/suppression_partenaire', {
                        id: this.id2
                        
                    }).then(response => {
                        this.modal_suppr=false;
                        this.fetchData();
                        this.id2 = ""
                    }).catch (error => {
                        (console.log(error))
                    })
                } catch (error) {
                    console.log(error)
                }
            },
            delete_partenaire() {
                console.log(this.deletes)
                try {} catch (error) {
                    console.error(error)
                }
            },
        }
    }
</script>
<style lang="">

</style>
<template>
    <div class="flex justify-between items-center bg-white w-full h-fit">
       
        <div class=""><h1 class="text-left md:text-2xl text-gray-600 ml-1 font-semibold">{{ name_page }}</h1></div>
        
        <div class="flex justify-between items-center gap-x-4 lg:gap-x-8">
            
            <div class="">
                <button class="px-3 max-sm:text-xs h-9 bg-red-500 font-semibold rounded flex justify-center items-center gap-x-3 text-white" @click="$router.push('/Signin')">
                <span class="fas fa-arrow-right-from-bracket"></span>
                <span class="max-lg:hidden">Se déconnecter</span>  
                </button></div>
        </div>
    </div>
</template>
<script>
    export default{
        // eslint-disable-next-line vue/multi-word-component-names
        name:'Header',
        props:{
            name_page:String
        },
        data(){
            return{
                variable:false,
                // eslint-disable-next-line vue/no-dupe-keys
                name_page:this.$props.name_page,
                data:false
            }
        },methods:{
           
        }
    }
</script>
<template>
    <div ref="modal_menu" class="bg-opacity-0 z-10 text-white flex justify-center items-center modal_menu w-screen bg-white py-3 border-b border-slate-400/80 ">
        <div class="flex items-center justify-between w-full max-md:px-4 md:px-6">
            <!--logo seera-->
            
                <router-link to="/">
                    <img src="./../../assets/serra2.png" class="max-sm:w-20 sm:w-[10vw]">
                </router-link>
            
            <div class=" flex justify-end items-center gap-x-8">
                <div class="max-lg:hidden  flex justify-center  items-center gap-5 text-black  ">
                    <a v-for="(section, index) in sections" :key="index" class="hover:text-orange-500" :href="`#${section.id}`">
                        <router-link :to="section.link">
                            <div>{{ section.title }}</div>
                        </router-link>
                    </a>
                </div>
                <div class="flex justify-center items-center gap-x-3">
                    <router-link to="/profile" class="fa fa-user text-xl rounded-full text-slate-600 mr-2"></router-link>
                    <div class="max-lg:hidden">
                        
                            <button class="py-1 px-3 rounded bg-red-400   hover:bg-red-500 " @click="logout">Se déconnecter</button>
                        
                    </div>
                    <div class="lg:hidden flex justify-center items-center ">
                        <button class="fas fa-bars  text-xl rounded font-bold   text-slate-700 " @click="send_data_menu_mobile"></button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <br><br><br>
</template>
<script>
    export default {
        name: 'Header2',
        emits: ['data_menu_mobile2'],
        data() {
            return {
                sections: [{
                        link: '/home',
                        title: 'Accueil'
                    },
                    {
                        link: '/customer',
                        title: 'Client'
                    },
                    {
                        link: '/supplier',
                        title: 'Fournisseur'
                    },
                    {
                        link: '/investor',
                        title: 'Investisseur'
                    },
                    {
                        link: '/comparison',
                        title: 'Comparaison'
                    },
                ],
                modal_menu: false
            }
        },
        mounted() {
            // Add scroll event listener to the window
            window.addEventListener("scroll", () => {
                // Get the current scroll position
                const scrollY = window.scrollY;
                if (this.$refs.modal_menu !== null) {
                    // Check if the scroll has reached the bottom
                    if (scrollY < 90) {
                        // Add 'bg-opacity-0' class to the element with 'modal_menu' ref
                        this.$refs.modal_menu.classList.add('bg-opacity-0');
                        this.$refs.modal_menu.classList.add('border-white');
                        this.$refs.modal_menu.classList.add('shadow-transparent');
                        this.$refs.modal_menu.classList.remove('shadow-slate-400');
                    } else {
                        // Handle non-bottom scroll position
                        console.log('Scroll position:', scrollY);
                        this.$refs.modal_menu.classList.remove('bg-opacity-0');
                        this.$refs.modal_menu.classList.remove('border-white');
                        this.$refs.modal_menu.classList.remove('shadow-transparent');
                        this.$refs.modal_menu.classList.add('shadow-slate-400');
                    }
                }
            });
        },
        methods: {
            send_data_menu_mobile() {
                this.modal_menu = true
                // console.log(data)
                this.$emit('data_menu_mobile2', this.modal_menu)
            },

            logout() {
                this.$router.push("/");
                localStorage.clear();
            }
        }
    }
</script>
<style>
    .img {
        background-image: url('./../../assets/header_ap.png');
        background-attachment: local;
    }
</style>
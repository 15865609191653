<template>
    <div class="flex flex-row gap-3 justify-center items-center p-5"> 
        <div class="fa fa-forward rotate-180 bg-gray-100 p-2"></div>
        <div><p>1   2   3   ... 8   9   10</p></div>
        <div class="fa fa-forward bg-gray-100 p-2"></div>
    </div>
</template>
<script>
    export default {
        name:'Pagination',
        data(){

        }
    }
</script>
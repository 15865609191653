<template lang="">
    <div class='w-screen bg-slate-50 h-screen flex flex-col justify-start items-center pt-20 '>
        <!-- <types v-if="page===1" page='page'></types> -->
        <!-- page 1 -->
        <p class="hidden">{{ iduser }}</p>
        <Header :modal_menu="modal_menu" @toggle="modal_menu=!modal_menu"></Header>
        
        <div v-if="page===1" class='w-11/12 flex flex-wrap justify-center  items-start gap-3'>
            <!-- <h1 class='w-full text-center mb-5 font-bold text-2xl '>Liste des abonnements</h1> -->
            <div class='w-full flex justify-start items-center '>
                <router-link to="/signin" class="fa fa-arrow-left text-lg m-2"> </router-link>
                <h1 class='w-full text-center mb-5 font-bold text-2xl '>
                    Liste des abonnements
                </h1>
            </div>
            <div v-show="nbBasique>=1" class='w-[280px] min-h-[370px] h-[370px] hover:scale-105 transition-all duration-100 hover:shadow-sm hover:shadow-red-300/50 rounded-sm shadow-sm shadow-slate-300 bg-white  p-3'>
                <div class="flex flex-col justify-between h-full  gap-y-3 ">
                    <div>
                        <div class="flex gap-x-3 ">
                            <div class="icon">
                            </div>
                            <div>
                                <h1 class="font-bold  text-4xl">
                                    Basique({{ nbBasique }})
                                </h1>
                            </div>
                        </div>
                        <div>
                            <p>
                                Profitez d'un accès de base à notre plateforme. Idéal pour les bésoins simples.
                            </p>
                        </div>
                        <div>
                            <h3>
                                <span class="text-3xl font-bold">
                                            15 000 Ar
                                          </span>
                                <span class="text-xl font-semibold">
                                            /mois
                                          </span>
                            </h3>
                            <div class="flex flex-col gap-y-2 py-3">
                                <div class="flex justify-start gap-x-4 items-center">
                                    <svg class="w-5 h-5 text-red-400" viewBox="0 0 24 24" stroke-linecap="round" stroke-width="2">
                                              <polyline fill="none" stroke="currentColor" points="6,12 10,16 18,8"></polyline>
                                              <circle cx="12" cy="12" fill="none" r="11" stroke="currentColor"></circle>
                                            </svg>
                                    <span>
                                              30 clients
                                            </span>
                                </div>
                                <div class="flex justify-start gap-x-4 items-center">
                                    <svg class="w-5 h-5 text-red-400" viewBox="0 0 24 24" stroke-linecap="round" stroke-width="2">
                                              <polyline fill="none" stroke="currentColor" points="6,12 10,16 18,8"></polyline>
                                              <circle cx="12" cy="12" fill="none" r="11" stroke="currentColor"></circle>
                                            </svg>
                                    <span>
                                              30 fournisseurs
                                            </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <button @click='nextPage("basic")' class="w-full border rounded-sm font-bold h-9 hover:text-white hover:bg-slate-900  border-slate-900">
                                        Choisir
                                      </button>
                </div>
            </div>
            <div v-show="nbStandard>=1" class='w-[280px] min-h-[370px] h-[370px] hover:scale-105 transition-all duration-100 hover:shadow-sm hover:shadow-red-300/50 rounded-sm shadow-sm shadow-slate-300 bg-white  p-3'>
                <div class="flex flex-col h-full justify-between  items-end  gap-y-3 ">
                    <div class="h-full w-full ">
                        <div class="flex gap-x-3 ">
                            <div class="icon">
                            </div>
                            <div>
                                <h1 class="font-bold text-4xl">
                                    Standard({{ nbStandard }})
                                </h1>
                            </div>
                        </div>
                        <div>
                            <p>
                                Améliorez votre engagement avec notre abonnement standard et Bénéficiez d'un accès elargit à notre plateforme.
                            </p>
                        </div>
                        <div>
                            <h3>
                                <span class="text-3xl font-bold">
                                            50 000 Ar
                                          </span>
                                <span class="text-xl font-semibold">
                                            /mois
                                          </span>
                            </h3>
                            <div class="flex flex-col gap-y-2 py-3">
                                <div class="flex justify-start gap-x-4 items-center">
                                    <svg class="w-5 h-5 text-red-400" viewBox="0 0 24 24" stroke-linecap="round" stroke-width="2">
                                              <polyline fill="none" stroke="currentColor" points="6,12 10,16 18,8"></polyline>
                                              <circle cx="12" cy="12" fill="none" r="11" stroke="currentColor"></circle>
                                            </svg>
                                    <span>
                                              100 clients
                                            </span>
                                </div>
                                <div class="flex justify-start gap-x-4 items-center">
                                    <svg class="w-5 h-5 text-red-400" viewBox="0 0 24 24" stroke-linecap="round" stroke-width="2">
                                              <polyline fill="none" stroke="currentColor" points="6,12 10,16 18,8"></polyline>
                                              <circle cx="12" cy="12" fill="none" r="11" stroke="currentColor"></circle>
                                            </svg>
                                    <span>
                                              100 fournisseurs
                                            </span>
                                </div>
                                <div class="flex justify-start gap-x-4 items-center">
                                    <svg class="w-5 h-5 text-red-400" viewBox="0 0 24 24" stroke-linecap="round" stroke-width="2">
                                              <polyline fill="none" stroke="currentColor" points="6,12 10,16 18,8"></polyline>
                                              <circle cx="12" cy="12" fill="none" r="11" stroke="currentColor"></circle>
                                            </svg>
                                    <span>
                                              30 investisseurs
                                            </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <button @click='nextPage("standard")' class="w-full self-end border rounded-sm font-bold h-9 hover:text-white hover:bg-slate-900  border-slate-900">
                                        Choisir
                                      </button>
                </div>
            </div>
            <div v-show="nbPremium>=1" class='w-[280px] min-h-[370px] h-[370px] hover:scale-105 transition-all duration-100 hover:shadow-sm hover:shadow-red-300/50 rounded-sm shadow-sm shadow-slate-300 bg-white  p-3'>
                <div class="flex flex-col h-full justify-between gap-y-3 ">
                    <div>
                        <div class="flex gap-x-3 ">
                            <div class="icon">
                            </div>
                            <div>
                                <h1 class="font-bold text-4xl">
                                    Premium({{ nbPremium }})
                                </h1>
                            </div>
                        </div>
                        <div>
                            <p>
                                Profitez d'un accès de base à notre plateforme. Idéal pour les bésoins simples.
                            </p>
                        </div>
                        <div>
                            <h3>
                                <span class="text-3xl font-bold">
                                            100 000 Ar
                                          </span>
                                <span class="text-xl font-semibold">
                                            /mois
                                          </span>
                            </h3>
                            <div class="flex flex-col gap-y-2 py-3">
                                <div class="flex justify-start gap-x-4 items-center">
                                    <svg class="w-5 h-5 text-red-400" viewBox="0 0 24 24" stroke-linecap="round" stroke-width="2">
                                              <polyline fill="none" stroke="currentColor" points="6,12 10,16 18,8"></polyline>
                                              <circle cx="12" cy="12" fill="none" r="11" stroke="currentColor"></circle>
                                            </svg>
                                    <span>
                                              +200 clients
                                            </span>
                                </div>
                                <div class="flex justify-start gap-x-4 items-center">
                                    <svg class="w-5 h-5 text-red-400" viewBox="0 0 24 24" stroke-linecap="round" stroke-width="2">
                                              <polyline fill="none" stroke="currentColor" points="6,12 10,16 18,8"></polyline>
                                              <circle cx="12" cy="12" fill="none" r="11" stroke="currentColor"></circle>
                                            </svg>
                                    <span>
                                              +200 fournisseurs
                                            </span>
                                </div>
                                <div class="flex justify-start gap-x-4 items-center">
                                    <svg class="w-5 h-5 text-red-400" viewBox="0 0 24 24" stroke-linecap="round" stroke-width="2">
                                              <polyline fill="none" stroke="currentColor" points="6,12 10,16 18,8"></polyline>
                                              <circle cx="12" cy="12" fill="none" r="11" stroke="currentColor"></circle>
                                            </svg>
                                    <span>
                                              +100 investisseurs
                                            </span>
                                </div>
                                <div class="flex justify-start gap-x-4 items-center">
                                    <svg class="w-5 h-5 text-red-400" viewBox="0 0 24 24" stroke-linecap="round" stroke-width="2">
                                              <polyline fill="none" stroke="currentColor" points="6,12 10,16 18,8"></polyline>
                                              <circle cx="12" cy="12" fill="none" r="11" stroke="currentColor"></circle>
                                            </svg>
                                    <span>
                                              Bonus surprise
                                            </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <button @click='nextPage("premium");' class="w-full border rounded-sm font-bold h-9 hover:text-white hover:bg-slate-900  border-slate-900">
                                        Choisir
                                      </button>
                </div>
            </div>
        </div>
        <!-- page 2 -->
        <div v-if="page===2" class='w-11/12 pb-72 flex flex-col gap-y-1 justify-center  items-center gap-x-4'>
            <div class='w-full flex justify-start items-center '>
                <button @click='prevPage()' class='fa fa-arrow-left text-lg m-2'>
                                        
                                    </button>
                <h1 class='w-full text-center mb-5 font-bold text-2xl '>
                    Liste des
                    <span v-if="choice==='basic'">Régions </span>
                    <span v-if="choice==='standard'">Provinces</span>
                </h1>
            </div>
            <div class='w-full grid max-md:grid-cols-1 max-lg:grid-cols-2 lg:grid-cols-3 gap-2'>
                    <div v-if="choice==='basic'">
                        <button v-for="userBasic in userBasics" :key="userBasic.idabo" @click="selectRegion(userBasic.region)" class='bg-white border-l-4 border-red-500  hover:scale-[1.015] transition-all duration-100 hover:shadow-sm hover:shadow-red-300/50  p-5 w-full rounded-sm shadow-sm shadow-slate-500/50'>
                                    <div class='w-full flex justify-between items-center'>
                                        <span class=''>
                                            <span class='font-semibold'>Région : </span>
                                            <span class='text-slate-500'>{{ userBasic.region }}</span>
                                        </span>
                                        <span class='font-semibold text-red-500 text-lg'>10</span>
                                    </div>
                                    <div class='flex justify-start items-center gap-x-20'>
                                        <span class=''>
                                            <span class='font-semibold'>Début : </span>
                                            <span class='text-slate-500'>{{ userBasic.dateDebut }}</span>
                                        </span>
                                        <span class=''>
                                            <span class='font-semibold'>Fin : </span>
                                            <span class='text-slate-500'>{{ userBasic.dateFin }}</span>
                                        </span>
                                    </div>
                        </button>
                    </div>
            </div>
            
            <div class='w-full grid max-md:grid-cols-1 max-lg:grid-cols-2 lg:grid-cols-3 gap-2'>
                <div v-if="choice==='standard'">
                    <button v-for="userStandard in userStandards" :key="userStandard.idabo" @click="selectProvince(userStandard.province)" class='bg-white border-l-4 border-red-500  hover:scale-[1.015] transition-all duration-100 hover:shadow-sm hover:shadow-red-300/50  p-5 w-full rounded-sm shadow-sm shadow-slate-500/50'>
                                    <div class='w-full flex justify-between items-center'>
                                        <span class=''>
                                            <span class='font-semibold'>Province : </span>
                                            <span class='text-slate-500'>{{ userStandard.province }}</span>
                                        </span>
                                        <span class='font-semibold text-red-500 text-lg'>10</span>
                                    </div>
                                    <div class='flex justify-start items-center gap-x-20'>
                                        <span class=''>
                                            <span class='font-semibold'>Début : </span>
                                            <span class='text-slate-500'>{{ userStandard.dateDebut }}</span>
                                        </span>
                                        <span class=''>
                                            <span class='font-semibold'>Fin : </span>
                                            <span class='text-slate-500'>{{ userStandard.dateFin }}</span>
                                        </span>
                                    </div>
                    </button>
                </div>
            </div>
        </div>
        <Footer></Footer>
    </div>
    
    <Menu_landing :modal_menu="modal_menu" @close="modal_menu=false"></Menu_landing>
</template>
<script>
    import axios from 'axios';
    import apiUrl from '../../../ApiConfig';
    // import types from './type/type.vue'
    // import location from './location/location.vue'
   
   
    
    
    import Footer from '@/components/Client/Footer.vue'
    import Menu_landing from '@/components/Client/menu/Menu_lainding.vue'
    import Header from '@/components/Client/Header_all.vue';
  
    export default {
        name: 'list_subscription',
        components: {
            Menu_landing,
                Header,
                Footer
        },
        data() {
            return {
                page: 1,
                iduser: '',
                choice: 0,
                nbStandard: 0,
                nbBasique: 0,
                nbPremium: 1,
                userBasics: [],
                userStandards: [],
                modal_menu:false,
                typeAbo: ''
                
            }
        },
        mounted() {
            this.iduser = localStorage.getItem('iduser');
            this.getNombreBasique();
            this.getNombreStandard();
            this.getNombrePremium();
        },
        methods: {
            getNombreBasique() {
                axios.get(apiUrl + 'api/nombre_basique_user', {
                    params: {
                        iduser: localStorage.getItem('iduser')
                    }
                }).then(response => {
                    this.nbBasique = response.data.nombreBasique;
                    // console.log(response.data)
                    // eslint-disable-next-line no-undef
                }).catch(error => {
                    console.log(error)
                })
            },
            getNombreStandard() {
                axios.get(apiUrl + 'api/nombre_standard_user', {
                    params: {
                        iduser: localStorage.getItem('iduser')
                    }
                }).then(response => {
                    this.nbStandard = response.data.nombreStandard;
                    // console.log(response.data)
                    // eslint-disable-next-line no-undef
                }).catch(error => {
                    console.log(error)
                })
            },
            getNombrePremium() {
                axios.get(apiUrl + 'api/nombre_premium_user', {
                    params: {
                        iduser: localStorage.getItem('iduser')
                    }
                }).then(response => {
                    this.nbPremium = response.data.nombrePremium;
                    // console.log(response.data)
                    // eslint-disable-next-line no-undef
                }).catch(error => {
                    console.log(error)
                })
            },

            selectRegion(region) {
                localStorage.setItem('region', region);
                localStorage.removeItem('province');
                //console.log(localStorage.getItem('region'));
                this.$router.push('/home');
            },

            selectProvince(province) {
                localStorage.setItem('province', province);
                localStorage.removeItem('region');
                //console.log(localStorage.getItem('province'));
                this.$router.push('/home')
            },
            nextPage(message) {
                this.choice = message
                this.page = 2
                localStorage.setItem('pageAbonnemment', this.page)
                console.log(message)
                if (message === 'basic') {
                    try {
                        axios.get(apiUrl + 'api/getUserBasique', {
                                params: {
                                    iduser: localStorage.getItem('iduser'),
                                }
                            })
                            .then(response => {
                                this.userBasics = response.data;
                                localStorage.setItem('typeAbo', 'basic');
                                this.typeAbo = localStorage.getItem('typeAbo');
                                //console.log(response.data);
                                //console.log(this.typeAbo);
                            })
                            .catch(error => {
                                console.log(error)
                            })
                    } catch (error) {
                        console.error(error)
                    }
                }
                if (message === 'standard') {
                    try {
                        axios.get(apiUrl + 'api/getUserStandard', {
                            params: {
                                    iduser: localStorage.getItem('iduser'),
                                }
                        })
                            .then(response => {
                                this.userStandards = response.data;
                                localStorage.setItem('typeAbo', 'standard');
                                this.typeAbo = localStorage.getItem('typeAbo');
                                //console.log(response.data)
                            })
                            .catch(error => {
                                console.log(error)
                            })
                    } catch (error) {
                        console.error(error)
                    }
                }
                if (message === 'premium') {
                    this.$router.push('/home')
                    try {
                        axios.post('route')
                            .then(response => {})
                            .catch(error => {})
                    } catch (error) {
                        console.error(error)
                    }
                }
                // console.log(this.page)
            },
            prevPage() {
                this.page = 1
                localStorage.setItem('pageAbonnemment', this.page)
            }
        }
    }
</script>
<style lang="">

</style>
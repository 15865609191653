<template>
    <div class="h-screen  max-lg:hidden flex justify-start items-start flex-col gap-y-4">
        <div class="pl-4"><img src="./../../assets/serra2.png" alt="" class="max-sm:w-16 md:w-16  lg:w-20 m-2"></div>
        <div class="flex  items-center justify-start w-full px-1" v-show="width != 'w-0'">
            <div class="w-full">
                <ul class="flex flex-col items-start gap-y-1 w-full">
                    <li class="text-xl font-semibold pl-1 text-slate-600">Page</li>
                    <li v-for="item in menu_item"
                        class="  hover:text-blue-500 w-full text-slate-950 hover:bg-blue-50 transition-all p-1 py-1   rounded my-1 mr-2">
                        <router-link :to="item.link" class="w-full">
                            <div class="flex flex-row gap-x-2 justify-start items-center w-full">
                                <div :class="item.logo" class=""></div>
                                <div class="">{{ item.title }}</div>
                            </div>
                        </router-link>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>
<script>
import '@/assets/styleAdmin/Menu.css'
import '@/assets/styleAdmin/all.css'
export default {
    name: 'Menu',
    data() {
        return {

            menu_item: [{
                        title: 'Tableau de board',
                        link: '/Admin/Dashboard',
                        logo: 'fa fa-house '
                    },
                    {
                        title: 'Comparaison',
                        link: '/Admin/Comparaison',
                        logo: 'fas fa-chart-line'
                    },
                    {
                        title: 'Categorie',
                        link: '/Admin/Categorie',
                        logo: 'fa fa-list-check '
                    },
                    {
                        title: 'Utilisateur',
                        link: '/Admin/Utilisateur',
                        logo: 'fa fa-users '
                    },
                    {
                        title: 'Abonnement',
                        link: '/Admin/subscription',
                        logo: 'fa fa-dollar '
                    },
                    {
                        title: 'Client',
                        link: '/Admin/Client',
                        logo: 'fa fa-user'
                    },
                    {
                        title: 'Fournisseur',
                        link: '/Admin/Fournisseur',
                        logo: 'fa fa-house '
                    },
                    {
                        title: 'Investisseur',
                        link: '/Admin/Investisseur',
                        logo: 'fa fa-money-check '
                    }, {
                        title: 'Partenaire',
                        link: '/Admin/partenaire',
                        logo: 'fas fa-handshake '
                    },
                    {
                        title: 'Message',
                        link: '/Admin/Message',
                        logo: 'fas fa-message '
                    },
                ],
            width: '',
            show_mobile: false,
            data: false
        }
    },

    watch: {
        data(newData, oldData) {
            // newData est la nouvelle valeur de data
            // oldData est la valeur précédente de data
            if (newData !== oldData) {
                // La valeur de data a changé
                console.log('data changed')
            }
        }
    },
    mounted() {
        if (window.innerWidth <= 430) {

            this.width = 'w-0'
            this.show_mobile = true
        } else {
            this.width = 'w-2/12'
        }
        this.data = JSON.parse(localStorage.getItem('data_menu'))
    },
    methods: {
        toggleWidth: function () {

            if (window.innerWidth <= 430) {
                if (this.width == 'w-1/2') {
                    this.width = 'w-0'
                    this.show_mobile = true
                } else {
                    this.width = 'w-1/2'
                    this.show_mobile = false
                }
            } else {
                if (this.width == 'w-1/6') {
                    this.width = 'w-2/12'

                } else {
                    this.width = 'w-1/6'

                }
            }
            localStorage.setItem('data_menu', JSON.stringify(!this.data))


        }

    }
}
</script>
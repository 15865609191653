<template>
    <Transition>
        <div v-if="isOpen" class=" modal_ajout inset-x-0 z-20   flex justify-center items-start  ">
            <div class="p-3 modal_absolute max-md:w-11/12 md:w-6/12 mx-auto mt-16 flex flex-col  gap-y-1 z-50 bg-white shadow-sm shadow-slate-600/80">
                <div class="w-full flex justify-end items-center absolute z-[60]">
                    <button @click="close()" class="fa font-semibold fa-times "></button>
                </div>
                <slot></slot>
            </div>
        </div >
    </Transition>
</template>
<style scoped>
    .v-enter-from,
    .v-leave-to {
        transform: translateY(-20px);
        opacity: 0;
    }
    .v-enter-active,
    .v-leave-active {
        transition: all 0.1s;
    }
    .modal_ajout{
    position: fixed;
    top: 0;
    left: 0;
    z-index: 4;
}
</style>
<script>

    export default {
        props: {
            isOpen: {
                type: Boolean,
                required: true,
            }
        },
        components:{
            
        },
        // // mounted(){
            
        // //         setTimeout(()=>{
        // //             this.close()
        // //         },2000)
            
        // // },
        // computed(){
        //     if(this.isOpen==true){
        //         setTimeout(()=>{
        //             this.close()
        //         },2000)
        //     }
        // },
        methods: {
            close() {
                this.$emit('close');
            }
        }
    }
</script>
<style lang="">

</style>
<template>
    <Menu :modal_menu="modal_menu" @close="modal_menu=false">
    </Menu>
    <!--modal_details-->
    <Modal :isOpen="modal_details" @close="modal_details=false">
        <h3 class="font-bold text-xl w-full text-left">{{ nom }}</h3>
        <div class="text-sm w-full grid   md:grid-cols-2 items-start gap-3">
            <div class="flex flex-col items-start justify-start gap-y-3">
                <div class="items-center text-left text-sm py-1 underline font-semibold">Fiche du fournisseur</div>
                <div class="flex flex-col w-full border-b">
                    <div class=" font-semibold">Contact</div>
                    <div class="text-slate-500">{{ contact }}</div>
                </div>
                <div class="flex flex-col w-full border-b">
                    <div class="font-semibold">E-mail</div>
                    <div class="text-slate-500">{{ email }}</div>
                </div>
                <div class="flex flex-col w-full border-b">
                    <div class="font-semibold">Adresse</div>
                    <div class="text-slate-500">{{ adresse }}</div>
                </div>
                <div class="flex flex-col w-full border-b">
                    <div class="font-semibold">Lieu</div>
                    <div class="text-slate-500">{{ lieu }}</div>
                </div>
                <div class="flex flex-col w-full border-b">
                    <div class="font-semibold">Capacité</div>
                    <div class="text-slate-500">{{ capaciteProd }}</div>
                </div>
            </div>
            <div class="flex flex-col items-start justify-start gap-y-3">
                <div class="items-center text-left text-sm py-1 font-semibold underline">Fiche du produit
                </div>
                <div class="flex flex-col w-full border-b">
                    <div class="font-semibold">Produit disponible</div>
                    <div class="text-slate-500">{{ prodDispo }}</div>
                </div>
                <div class="flex flex-col w-full border-b">
                    <div class="font-semibold">Quantité</div>
                    <div class="text-slate-500">{{ quantite }} {{ unite }}</div>
                </div>
                <div class="flex flex-col w-full border-b">
                    <div class="font-semibold">Prix unitaire</div>
                    <div class="text-slate-500">{{ prixUnitaire }} Ar</div>
                </div>
                <div class="flex flex-col w-full border-b">
                    <div class="font-semibold">Description</div>
                    <div class="text-slate-500">{{ description }}</div>
                </div>
                <div class="flex flex-col w-full border-b">
                    <div class="font-semibold">Catégorie</div>
                    <div class="text-slate-500">{{ nomcat }}</div>
                </div>
            </div>
        </div>
    </Modal>
    <div class="">
        <Header2 @data_menu_mobile2="receive_data_menu_mobile"></Header2><br>
        <div class="w-screen flex items-center justify-center">
            <div class="flex   justify-center items-center w-full">
                <div class="flex max-md:flex-col max-md:items-end gap-y-2 py-4 max-md:justify-end md:justify-end   md:items-end w-full gap-x-2 px-5">
                    <!--  -->
                    <div class="flex max-md:flex-col max-md:items-end max-md:justify-center max-md:gap-y-2 md:justify-end md:items-end gap-3 w-full">
                        <div class="flex flex-col gap-3 max-md:w-full  justify-end items-center">
                            <div class="w-full text-start text-slate-500 font-semibold">
                                Categorie
                            </div>
                            <div class="max-md:w-full">
                                <select class="border border-gray-300 h-8 w-full rounded bg-gray-100 p-1" v-model="inpCat">
                                        <option value="">Tous</option>
                                            <option v-for="categorie in categories" :key="categorie.nomcat"
                                                :value="categorie.nomcat">{{ categorie.nomcat }}</option>
                                        </select>
                            </div>
                        </div>
                        <div class="flex  justify-center items-end gap-3">
                            <div class="w-full flex flex-col gap-3">
                                <div class="w-full text-start text-slate-500 font-semibold">
                                    Prix entre
                                </div>
                                <div>
                                    <input type="number" class="w-full border h-8 border-gray-300 rounded p-1" v-model="inpPrix1">
                                </div>
                            </div>
                            <div class="w-full flex flex-col gap-3">
                                <div class="w-full text-start text-slate-500 font-semibold">
                                et
                            </div>
                            <div>
                                <input type="number" class="w-full border border-gray-300 rounded h-8 p-1" v-model="inpPrix2">
                            </div>
                            </div>
                        </div>
                    </div>
                    <div class="flex justify-end items-end max-md:gap-x-2 max-md:w-full">
                        <div class="flex items-center max-md:w-full  justify-between h-fit  border border-gray-200 rounded ">
                            <div class="max-md:w-full">
                                <input type="text" placeholder="Produit disponible ..." class="w-full p-1 focus:outline-none focus:placeholder-transparent" v-model="inpProduit">
                            </div>
                            <div class="hover:bg-gray-200  h-8  flex justify-center items-center rounded active:bg-gray-300 placeholder:text-sm">
                                <div>
                                    <button type="button" class="fa fa-search text-lg text-gray-600 p-1"></button>
                                </div>
                            </div>
                        </div>
                        <div>
                            <Filtre @data_filter="receive_data_filter" :show_btn_ajout="false" @data_list="data_list_function"></Filtre>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="w-screen flex justify-center items-center bg-slate-50 md:p-2">
            <div v-show="!mode_list" class="grid max-md:grid-cols-1 w-full max-lg:grid-cols-2 lg:grid-cols-3 gap-5 max-md:p-4 md:px-6">
                <div class="flex border-l-4 border-red-500  group flex-col justify-between items-center rounded bg-white shadow-gray-300 shadow-sm p-2" v-for="item in filtered_data()" :key="item.idfourni" @click="modal_details = true, nom = item.nom, contact = item.contact, email = item.email, adresse = item.adresse, prodDispo = item.prodDispo, quantite = item.quantite, unite = item.unite, prixUnitaire = item.prixUnitaire, description = item.description, lieu = item.lieu, capaciteProd = item.capaciteProd, nomcat = item.nomcat">
                    <div class="flex flex-col w-full">
                        <div class="flex flex-col w-full">
                            <div class="flex w-full gap-x-3">
                                <div class="max-md:w-[60px] md:w-[80px] lg:w-[100px] h-full flex justify-center items-center">
                                    <img src="./../../assets/user.png" class="w-10/12" alt="">
                                </div>
                                <div class="w-full flex flex-col gap-y-2">
                                    <div class="w-full text-lg font-bold overflow-y-hidden">{{ item.nom }}</div>
                                    <div class="flex flex-col  h-fit">
                                        <div class="w-full flex justify-between items-start">
                                            <div class="w-full overflow-y-hidden flex justify-start items-center gap-x-3">
                                                <span class='font-semibold'>Produit :</span>
                                                <span class='text-slate-500'>{{ item.prodDispo }}</span>
                                            </div>
                                            <span class="mr-2 text-red-500 font-bold ">{{ item.prixUnitaire }}Ar</span>
                                        </div>
                                        <div class="w-full flex justify-between items-start">
                                            <div class="w-full overflow-y-hidden flex justify-start items-center gap-x-3">
                                                <span class='font-semibold'>Categorie:</span>
                                                <span class='text-slate-500'>{{ item.nomcat }}</span>
                                            </div>
                                            <div class="w-full overflow-y-hidden flex justify-end items-center gap-x-3">
                                                <span class='font-semibold'>Quantité :</span>
                                                <span class='text-slate-500'>{{ item.quantite + ' ' + item.unite
                                                            }}</span>
                                            </div>
                                        </div>
                                        <!-- <div class="w-full flex justify-between items-center">
                                            <div class="w-full overflow-y-hidden flex justify-start items-center gap-x-3">
                                                <span class=''>Description :</span>
                                                <span class='text-slate-500'>{{ client.description }}</span>
                                            </div>
                                            
                                        </div> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="flex justify-end items-center w-full text-white">
                                <div><button class="bg-red-400 hover:bg-red-500 p-2 lg:group-hover:block lg:hidden  rounded">Details</button>
                                </div>
                            </div> -->
                </div> <br>
            </div>
            <div v-show="mode_list" class="w-full flex justify-center items-center">
                <div class="overflow-x-auto  w-full flex justify-center items-center max-md:px-4 md:px-6 py-4">
                    <table class="min-w-full divide-y divide-gray-200 bg-white shadow-sm shadow-gray-300">
                        <thead class="">
                            <th style="display: none;">Id</th>
                            <th class="md:px-6 md:py-3 bg-gray-100 text-left max-md:text-sm leading-4 font-medium text-gray-500  tracking-wider">
                                Nom</th>
                            <th class="md:px-6 md:py-3 bg-gray-100 text-left max-md:text-sm leading-4  font-medium text-gray-500  tracking-wider">
                                Contact</th>
                            <th class="md:px-6 md:py-3 bg-gray-100 text-left max-md:text-sm leading-4 font-medium text-gray-500  tracking-wider">
                                Produit disponible</th>
                            <th class="md:px-6 md:py-3 bg-gray-100 text-left max-md:text-sm max-sm:hidden leading-4 font-medium text-gray-500  tracking-wider">
                                Prix Unitaire(AR)</th>
                            <th style="display: none;">Catégorie</th>
                            <th class="md:px-6 md:py-3 bg-gray-100 text-left max-md:text-sm leading-4 font-medium text-gray-500  tracking-wider">
                            </th>
                        </thead>
                        <tbody class="bg-white divide-y divide-gray-200">
                            <tr v-for="item in filtered_data()" :key="item.idfourni" class="hover:cursor-pointer hover:bg-gray-50 max-md:text-sm" @click="modal_details = true, idfourni = item.idfourni, nom = item.nom, contact = item.contact, email = item.email,
                        adresse = item.adresse, capaciteProd = item.capaciteProd, prodDispo = item.prodDispo, prixUnitaire = item.prixUnitaire, quantite = item.quantite, nomcat = item.nomcat, description = item.description,unite = item.unite, lieu = item.lieu, unite = item.unite">
                                <td style="display: none;">{{ item.idfourni }}</td>
                                <td class="md:px-6 sm:px-3 sm:py-2 md:py-4  max-md:text-sm whitespace-no-wrap">{{ item.nom }}</td>
                                <td class="md:px-6 sm:px-3 sm:py-2 md:py-4  whitespace-no-wrap">{{ item.contact }}</td>
                                <td style="display: none;">{{ item.email }}</td>
                                <td style="display: none;">{{ item.adresse }}</td>
                                <td class="md:px-6 sm:px-3 sm:py-2 md:py-4  whitespace-no-wrap max-md:px-3">
                                    {{ item.prodDispo }}</td>
                                <td style="display: none;">{{ item.quantite }}</td>
                                <td style="display: none;">{{ item.unite }}</td>
                                <td class="md:px-6 sm:px-3 max-sm:mx-5  sm:py-2 md:py-4  whitespace-no-wrap  max-sm:hidden">
                                    {{ item.prixUnitaire }}</td>
                                <td style="display: none;">{{ item.description }}</td>
                                <td style="display: none;">{{ item.lieu }}</td>
                                <td style="display: none;">{{ item.capaciteProd }}</td>
                                <td style="display: none;">{{ item.nomcat }}</td>
                                <td class="px-6 py-4 whitespace-no-wrap">
                                    <div @click="modal_details = true" class="fa fa-eye text-gray-500 active:bg-gray-200">
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div><br>
        </div>
    </div><br><br><br><br><br><br>
    <Footer></Footer>
</template>
<style scoped>
    *:not(.modal_ajout) {
        position: relative;
        z-index: 1;
    }
</style>
<script>
    import axios from 'axios'
    import apiUrl from '../../ApiConfig';
    import Footer from './../../components/Client/Footer.vue'
    import Header2 from './../../components/Client/Header2.vue'
    import Filtre from './../../components/Client/Filtre.vue'
    import Modal from '@/components/modal/modal.vue';
    import Menu from '@/components/Client/menu/Menu.vue'
    export default {
        // eslint-disable-next-line vue/multi-word-component-names
        name: 'Fournisseur',
        components: {
            Footer,
            Header2,
            Filtre,
            Modal,
            Menu
        },
        data() {
            return {
                inpCat: '',
                inpProduit: '',
                inpPrix1: 0,
                inpPrix2: 0,
                sections: [{
                        link: '/home',
                        title: 'Accueil'
                    },
                    {
                        link: '/customer',
                        title: 'Client'
                    },
                    {
                        link: '/supplier',
                        title: 'Fournisseur'
                    },
                    {
                        link: '/investor',
                        title: 'Investisseur'
                    },
                    {
                        link: '/comparison',
                        title: 'Comparaison'
                    },
                ],
                modal_menu: false,
                mode_list: localStorage.getItem('seera_list_mode') !== null ? JSON.parse(localStorage.getItem('seera_list_mode')) : true,
                modal_details: false,
                fournisseurs: [],
                categories: [],
                nom: '',
                contact: '',
                email: '',
                adresse: '',
                prodDispo: '',
                prixUnitaire: '',
                quantite: '',
                unite: '',
                description: '',
                lieu: '',
                capaciteProd: '',
                nomcat: '',
                province: localStorage.getItem('province'),
                region: localStorage.getItem('region')
            }
        },
        mounted() {
            this.getFournisseurs();
            this.categorieSelection();
        },
        methods: {
            receive_data_filter(props1, props2, props3, props4) {
                this.inp_prix = props1
                this.inp_sel_categorie = props2
                this.inp_sel_prix = props3
                this.inp_search = props4
            },
            data_list_function(data) {
                this.mode_list = data
            },
            modal_details_show() {
                this.modal_details = !this.modal_details
            },
            getFournisseurs() {
                const typeAbo = localStorage.getItem('typeAbo');
                // const province = localStorage.getItem('province');
                if (typeAbo === 'standard')
                    try {
                        axios.get(apiUrl + 'api/affichage_fournisseur_seera_standard', {
                                params: {
                                    province: this.province
                                }
                            })
                            .then(response => {
                                this.fournisseurs = response.data
                                // eslint-disable-next-line no-undef
                            }).catch(error => {
                                console.log(error)
                                this.fournisseurs = []
                            })
                    } catch (error) {
                        console.error(error);
                    }
                else if (typeAbo === 'basic') {
                    try {
                        axios.get(apiUrl + 'api/affichage_fournisseur_seera_basic', {
                                params: {
                                    region: this.region
                                }
                            })
                            .then(response => {
                                this.fournisseurs = response.data
                                // eslint-disable-next-line no-undef
                            }).catch(error => {
                                console.log(error)
                                this.fournisseurs = []
                            })
                    } catch (error) {
                        console.error(error);
                    }
                } else {
                    try {
                        axios.get(apiUrl + 'api/affichage_fournisseur_seera')
                            .then(response => {
                                this.fournisseurs = response.data
                                // eslint-disable-next-line no-undef
                            }).catch(error => {
                                console.log(error)
                                this.fournisseurs = []
                            })
                    } catch (error) {
                        console.error(error);
                    }
                }
            },
            rechercheFournisseur() {
                axios.get(apiUrl + 'api/recherche_fournisseur', {
                    params: {
                        inpProduit: this.inpProduit,
                        inpPrix1: this.inpPrix1,
                        inpPrix2: this.inpPrix2,
                        inpCat: this.inpCat
                    }
                }).then(response => {
                    this.fournisseurs = response.data
                }).catch(error => {
                    console.log(error)
                })
            },
            categorieSelection() {
                axios.get(apiUrl + 'api/affichage_nom_categorie').then(response => {
                    this.categories = response.data
                }).catch(error => {
                    console.log(error)
                })
            },
            receive_data_menu_mobile(data) {
                this.modal_menu = data
            },
            filtered_data() {
                return this.fournisseurs.filter(item => (this.inpCat != '' ? item.nomcat.toLowerCase().includes(this.inpCat.toLowerCase()) : item))
                    .filter(item => (this.inpProduit != '' ? item.prodDispo.toLowerCase().includes(this.inpProduit.toLowerCase()) : item))
                    .filter(item => (this.inpPrix != 0 ? (this.inpPrix1 <= item.prixUnitaire) : item))
                    .filter(item => (this.inpPrix2 != 0 ? (this.inpPrix2 >= item.prixUnitaire) : item))
            },
        }
    }
</script>
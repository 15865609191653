<!-- eslint-disable vue/multi-word-component-names -->
<!-- eslint-disable vue/require-v-for-key -->
<template>
    <div class="gray max-lg:w-screen lg:w-10/12 float-right h-screen flexbox  justify-center">
        <div class="bg-white max-sm:p-2 sm:h-fit mx-4 flex items-center rounded mb-3">
            <div class="flex p-2 justify-center items-center lg:hidden">
                <div class="fas fa-bars fa-2x text-gray-600 cursor-pointer" @click="modal_menu = !modal_menu"></div>
            </div>
            <Header :name_page="name_page" class="p-2"></Header>
        </div>
        <div class="bg-white h-fit mx-4 grid  items-center rounded p-2">
            <div class="flex max-sm:flex-col md:flex-wrap justify-end  items-center  gap-6">
                <div class="flex justify-end items-center max-sm:w-11/12 sm:w-3/12">
                    <div class="flex gap-3 ">
                        <div class="flex  items-center h-fit justify-center border rounded border-gray-400">
                            <div class="flex ">
                                <input type="text" name="" id="" v-model="inpProduit" placeholder="Recherche..." class="p-1 focus:outline-none placeholder:text-gray-300">
                            </div>
                            <div class="flex justify-center items-center">
                                <div class="fa fa-search p-1" @click="rechercheAbonnement()"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="bg-white h-fit mx-4 flex items-center justify-center rounded my-3 ">
            <div class="w-full text-xs sm:text-base p-3 max-lg:overflow-x-auto">
                <table class="w-full max-w-full ">
                    <thead class="text-left text-gray-600 bg-blue-50 font-semibold">
                        <tr>
                            <th>Utilisateur</th>
                            <th class="hidden">Idabo</th>
                            <th>Type</th>
                            <th>Montant</th>
                            <th class="hidden">Description</th>
                            <th>Date début</th>
                            <th>Date fin</th>
                            <th>Statut</th>
                            <th>Référence</th>
                            <th class="hidden">Ref image</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody class="divide-y">
                        <tr v-for="abonnement in abonnements" :key="abonnement.idabo" class="hover:bg-blue-50">
                            <td @click="iduser = abonnement.iduser,modal_user = true, detail_user()">
                                <span class="cursor-pointer rounded bg-slate-200 px-3 py-1">
                                                {{ abonnement.iduser }}
                                            </span>
                            </td>
                            <td class="hidden"> {{ abonnement.idabo }}</td>
                            <td>{{ abonnement.type }}</td>
                            <td>{{ abonnement.montant }}</td>
                            <td class="hidden">{{ abonnement.description }}</td>
                            <td>{{ abonnement.dateDebut }}</td>
                            <td>{{ abonnement.dateFin }}</td>
                            <td>{{ abonnement.statut }}</td>
                            <td>{{ abonnement.refText }}</td>
                            <td class="hidden">{{ abonnement.refImage }}</td>
                            <td class="flex justify-start items-center gap-x-4 p-2">
                                <button class="text-gray-500" @click="modal_details = true, idabo = abonnement.idabo, iduser = abonnement.iduser, type = abonnement.type, montant = abonnement.montant,
                                description = abonnement.description, dateDebut = abonnement.dateDebut, dateFin = abonnement.dateFin, statut = abonnement.statut, refText = abonnement.refText, refImage = abonnement.refImage">
                                                <span class="fa fa-eye text-blue-500"></span>
                                            </button>
                                <button class="text-gray-500" @click="modal_modif = true, idabo1 = abonnement.idabo, iduser1 = abonnement.iduser, dateDebut1 = abonnement.dateDebut, statut1 = abonnement.statut">
                                                <span class="fa fa-pen text-orange-500"></span>
                                            </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
    <Menu></Menu>
    <!-- modal menu pour mobile -->
    <Modal :isOpen="modal_user" @close="modal_user=false">
        <div class="w-full">
            <div class="w-full flex flex-col gap-y-4">
                <h1 class="text-xl font-semibold">Détail d'utilisateur</h1>
                <input type="hidden" v-model="iduser">
                <div class="flex gap-x-4">
                    <label class=" font-semibold w-3/12" for="">Nom :</label>
                    <input type="text" class="w-full border p-1 bg-white" id="i" v-model="nom" disabled>
                </div>
                <div class="flex gap-x-4">
                    <label class=" font-semibold w-3/12" for="">Contact :</label>
                    <input type="text" class="w-full border p-1 bg-white" id="i" v-model="contact" disabled>
                </div>
                <div class="flex gap-x-4">
                    <label class=" font-semibold w-3/12" for="">Email :</label>
                    <input type="text" class="w-full border p-1 bg-white" id="i" v-model="email" disabled>
                </div>
                <div class="flex gap-x-4">
                    <label class=" font-semibold w-3/12" for="">Adresse :</label>
                    <input type="text" class="w-full border p-1 bg-white" id="i" v-model="adresse" disabled>
                </div>
                <div class="flex gap-x-4">
                    <label class=" font-semibold w-3/12" for="">Statut :</label>
                    <input type="text" class="w-full border p-1 bg-white" id="i" v-model="statut" disabled>
                </div>
            </div>
        </div>
    </Modal>
    <Modal :isOpen="modal_details" @close="modal_details=false">
        <div class="flex justify-center items-center">
            <h5 class="text-2xl font-semibold ">Details d'abonnement</h5>
        </div>
        <div class="w-full">
            <form>
                <div class="w-full">
                    <div class="flex flex-col gap-y-4">
                        <div class="flex justify-between items-center gap-x-4 w-full">
                            <label class=" font-semibold w-4/12" for="">id user :</label>
                            <input type="text" class="border rounded p-1 w-full bg-white" id="i" v-model="iduser" disabled>
                        </div>
                        <div class="flex justify-between items-center gap-x-4 w-full">
                            <label class=" font-semibold w-4/12" for="">Type :</label>
                            <input type="text" class="border rounded p-1 w-full bg-white" id="i" v-model="type" disabled>
                        </div>
                        <div class="flex justify-between items-center gap-x-4 w-full">
                            <label class=" font-semibold w-4/12" for="">Montant (Ar) :</label>
                            <input type="text" class="border rounded p-1 w-full bg-white" id="i" v-model="montant" disabled>
                        </div>
                        <div class="flex justify-between items-center gap-x-4 w-full">
                            <label class=" font-semibold w-4/12" for="">Description :</label>
                            <textarea class="border rounded" name="" id="i" cols="80" rows="3" v-model="description" disabled></textarea>
                        </div>
                        <div class="flex justify-between items-center gap-x-4 w-full">
                            <label class=" font-semibold w-4/12" for="">Date début :</label>
                            <input type="text" class="border rounded p-1 w-full bg-white" id="i" v-model="dateDebut" disabled>
                        </div>
                        <div class="flex justify-between items-center gap-x-4 w-full">
                            <label class=" font-semibold w-4/12" for="">Réference texte :</label>
                            <input type="text" class="border rounded p-1 w-full bg-white" id="i" v-model="refText" disabled>
                        </div>
                        <div class="flex justify-between items-center gap-x-4 w-full">
                            <label class=" font-semibold w-4/12" for="">Réference image :</label>
                            <input type="text" class="border rounded p-1 w-full bg-white hover:cursor-pointer" id="i" v-model="refImage" @click="show_modal_image()"><br>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </Modal>
    <!-- <Modal v-show="modal_details"
                class="modal_ajout w-screen h-screen bg-black bg-opacity-60 grid justify-center items-center">
                <div class="h-fit max-lg:w-[90vw] lg:w-[60vw] bg-white rounded py-4 px-5 gap-y-6">
                    <div class="flex flex-col w-full justify-center items-start gap-y-5">
                        <div class="w-full flex justify-end items-center">
                            <div @click="modal_details = false"
                                class="fa fa-times py-1 bg-red-400 text-white rounded px-2 hover:cursor-pointer"></div>
                        </div>
                        <div class="flex justify-center items-center">
                            <h5 class="text-2xl font-semibold ">Details d'abonnement</h5>
                        </div>
                        <div class="w-full">
                            <form>
                                <div class="w-full">
                                    <div class="flex flex-col gap-y-4">
                                        <div class="flex justify-between items-center gap-x-4 w-full">
                                            <label class=" font-semibold w-4/12" for="">id user :</label>
                                            <input type="text" class="border rounded p-1 w-full bg-white" id="i"
                                                v-model="iduser" disabled>
                                        </div>
                                        <div class="flex justify-between items-center gap-x-4 w-full">
                                            <label class=" font-semibold w-4/12" for="">Type :</label>
                                            <input type="text" class="border rounded p-1 w-full bg-white" id="i" v-model="type"
                                                disabled>
                                        </div>
                                        <div class="flex justify-between items-center gap-x-4 w-full">
                                            <label class=" font-semibold w-4/12" for="">Montant (Ar) :</label>
                                            <input type="text" class="border rounded p-1 w-full bg-white" id="i"
                                                v-model="montant" disabled>
                                        </div>
                                        <div class="flex justify-between items-center gap-x-4 w-full">
                                            <label class=" font-semibold w-4/12" for="">Description :</label>
                                            <textarea class="border rounded" name="" id="i" cols="80" rows="3"
                                                v-model="description" disabled></textarea>
                                        </div>
                                        <div class="flex justify-between items-center gap-x-4 w-full">
                                            <label class=" font-semibold w-4/12" for="">Date début :</label>
                                            <input type="text" class="border rounded p-1 w-full bg-white" id="i"
                                                v-model="dateDebut" disabled>
                                        </div>
                                        <div class="flex justify-between items-center gap-x-4 w-full">
                                            <label class=" font-semibold w-4/12" for="">Date fin</label>
                                            <input type="text" class="border rounded p-1 w-full bg-white" id="i"
                                                v-model="dateFin" disabled>
                                        </div>
                                        <div class="flex justify-between items-center gap-x-4 w-full">
                                            <label class=" font-semibold w-4/12" for="">Statut :</label>
                                            <input type="text" class="border rounded p-1 w-full bg-white" id="i"
                                                v-model="statut" disabled>
                                        </div>
                                        <div class="flex justify-between items-center gap-x-4 w-full">
                                            <label class=" font-semibold w-4/12" for="">Réference texte :</label>
                                            <input type="text" class="border rounded p-1 w-full bg-white" id="i"
                                                v-model="refText" disabled>
                                        </div>
                                        <div class="flex justify-between items-center gap-x-4 w-full">
                                            <label class=" font-semibold w-4/12" for="">Réference image :</label>
                                            <input type="text" class="border rounded p-1 w-full bg-white hover:cursor-pointer"
                                                id="i" v-model="refImage" @click="show_modal_image()"
                                                ><br>
                                        </div>
                                    </div>
                                </div>
                                <div class="flex justify-between items-center gap-x-4 w-full">
                                    <label class=" font-semibold w-4/12" for="">Réference image :</label>
                                    <input type="text" class="border rounded p-1 w-full bg-white hover:cursor-pointer" id="i" v-model="refImage" data-bs-toggle="modal" data-bs-target="#detailImage" disabled><br>
                                </div>
                            </form>
                        </div>

                </div>
            </Modal> -->
    <!--modal modification-->
    <Modal :isOpen="modal_modif" @close="modal_modif=false">
        <div class="flex justify-center items-center">
            <h5 class="text-2xl font-semibold ">Modification</h5>
        </div>
        <div class="w-full">
            <form @submit.prevent="modificationAbonnement">
                <div class="w-full">
                    <div class="w-full flex flex-col gap-y-4">
                        <div class="gap-x-4 hidden">
                            <label for="">idUser</label>
                            <input type="" class="border p-1  w-full" v-model="iduser1">
                        </div>
                        <div class="hidden gap-x-4">
                            <label for="">idAbo</label>
                            <input type="" class="border p-1  w-full" v-model="idabo1">
                        </div>
                        <div class="flex ">
                            <label class="" for="">Date debut</label>
                            <input type="date" class="bg-white border p-1 w-full" id="i" v-model="dateDebut1">
                        </div>
                        <div class="flex justify-between items-center gap-x-4 w-full ">
                            <label class="" for="">Statut</label>
                            <select v-model="statut1" class="border p-1 w-full">
                                                    <option>En attente</option>
                                                    <option>A valider</option>
                                                    <option>Actif</option>
                                                </select>
                        </div>
                    </div>
                    <div class="flex max-lg:w-full max-lg:justify-between lg:justify-end items-center gap-x-4 my-4 ">
                        <button type="button" @click="modal_modif = false" class="border border-gray-500 max-lg:w-1/2 text-gray-500 px-3  h-7  rounded">Fermer</button>
                        <button type="submit" class=" h-7 bg-blue-500 px-3 max-lg:w-1/2  text-white rounded">Modifier</button>
                    </div>
                </div>
            </form>
        </div>
    </Modal>


    

    <!-- <div class="modal_ajout top-0 z-70 right-0 w-screen h-screen flex flex-col justify-center  shadow-sm items-center bg-slate-700 bg-opacity-50"
        v-show="modal_image">

        <div class="max-h-[90vh] max-w-[90vw]">
            <div class="w-full flex justify-end items-center">
                <div class="fa fa-times text-xl px-2 py-1 bg-red-400 rounded text-white" @click="modal_image = false">
                </div>
            </div>
            <div>
                <img alt="">
            </div>
        </div>
    </div> -->
    <Sidebar :modal_menu="modal_menu" @close="modal_menu=false"></Sidebar>
</template>

<script>
    import apiUrl from '../../ApiConfig';
    import axios from 'axios'
    import Menu from '@/components/Admin/Menu.vue'
    import Header from '@/components/Admin/Header.vue'
    //import Filtre from '@/components/Admin/Filtre.vue'
    import Modal from "@/components/modal/modal.vue"
    import '@/assets/styleAdmin/page.css'
    import Sidebar from '@/components/Admin/sidebar/sidebar.vue';
    export default {
        // eslint-disable-next-line vue/multi-word-component-names
        name: 'Abonnement',
        components: {
            Menu,
            //Filtre,
            Header,
            Modal,
            Sidebar
        },

        // data() {
        //     return {
        //         modal_menu: false,
        //         modal_user: false,
        //         modal_image: false,
        //         iduser: "",
        //         type: "",
        //         montant: "",
        //         description: "",
        //         dateDebut: "",
        //         dateFin: "",
        //         statut: "",
        //         refText: "",
        //         refImage: "",
        //         nom: "",
        //         contact: "",
        //         email: "",
        //         adresse: "",
        //         idabo: "",
        //         iduser1: '',
        //         idabo1: '',
        //         dateFin1: '',
        //         abonnements: false,
        //         dateDebut1: '',
        //         statut1: '',
        //         //ovaina ny nom n variable name-page
        //         name_page: this.$options.name,
        //         modal_modif: false,
        //         modal_ajout: false,
        //         modal_suppr: false,
        //         modal_details: false,
        //         //input dans filtrage
        //     }
        // },
        // mounted() {
        //     this.getAbonnements();
        // },
        // methods: {
        //     getAbonnements() {
        //         axios.get(apiUrl + 'api/affichage_abonnement').then(response => {
        //             this.abonnements = response.data
        //             // eslint-disable-next-line no-undef
        //         }).catch(error => {
        //             console.log(error)
        //             this.abonnements = [];
        //         })
        //     },
            data() {
                return {
                    modal_menu: false,
                    modal_user: false,
                    iduser: "",
                    type: "",
                    montant: "",
                    description: "",
                    dateDebut: "",
                    dateFin: "",
                    statut: "",
                    refText: "",
                    refImage: "",
                    nom: "",
                    contact: "",
                    email: "",
                    adresse: "",
                    idabo: "",
                    iduser1: '',
                    idabo1: '',
                    dateFin1: '',
                    abonnements: false,
                    dateDebut1: '',
                    statut1: '',
                    //ovaina ny nom n variable name-page
                    name_page: "Abonnement",
                    modal_modif: false,
                    modal_ajout: false,
                    modal_suppr: false,
                    modal_details: false,
                    //input dans filtrage
                }

            },
            mounted() {
                this.getAbonnements();
            },
            methods: {
                getAbonnements() {
                    axios.get(apiUrl + 'api/affichage_abonnement').then(response => {
                        this.abonnements = response.data
                        // eslint-disable-next-line no-undef
                    }).catch(error => {
                        console.log(error)
                        this.abonnements = [];
                    })
                },
                detail_user() {
                    // eslint-disable-next-line no-unused-vars
                    
                    try {
                        axios.get(apiUrl + 'api/affichage_user', {
                                params: {
                                    iduser: this.iduser
                                }
                            }).then(response => {
                                this.nom = response.data[0].name;
                                this.email = response.data[0].email;
                                this.contact = response.data[0].contact;
                                this.adresse = response.data[0].adresse;
                                this.statut = response.data[0].statut;
                            })
                            .catch(error => {
                                (
                                    console.error(error)
                                )
                            })
                    } catch (error) {
                        console.error(error)
                    }
                },
                modificationAbonnement() {
                    try {
                        const formData = new FormData();
                        formData.append('iduser', this.iduser1);
                        formData.append('idabo', this.idabo1);
                        formData.append('dateDebut', this.dateDebut1);
                        formData.append('statut', this.statut1);
                        axios.post(apiUrl + 'api/modification_abonnement', formData
                            // eslint-disable-next-line no-unused-vars
                        ).then(response => {
                            this.getAbonnements();
                            this.modal_modif = false;
                            this.iduser = '';
                            this.idabo = '', this.dateDebut = '', this.statut = '';
                        }).catch(error => {
                            (
                                console.log(error)
                            )
                        })
                    } catch (error) {
                        console.error(error)
                    }
                },
                getImageUrl(refImage) {
                    // Utilisez la fonction asset() de Laravel pour générer l'URL de l'image
                    return apiUrl + `images/${refImage}`;
                },
                show_modal_image() {
                    // image_modal=img
                    this.modal_image = true
                },
                modificationAbonnement() {
                    try {
                        const formData = new FormData();
                        formData.append('iduser', this.iduser1);
                        formData.append('idabo', this.idabo1);
                        formData.append('dateDebut', this.dateDebut1);
                        formData.append('statut', this.statut1);
                        axios.post(apiUrl + 'api/modification_abonnement', formData
                            // eslint-disable-next-line no-unused-vars
                        ).then(response => {
                            this.getAbonnements();
                            this.modal_modif = false;
                            this.iduser = '';
                            this.idabo = '', this.dateDebut = '', this.statut = '';
                        }).catch(error => {
                            (
                                console.log(error)
                            )
                        });
                    } catch (error) {
                        console.error(error)
                    }
                },
                fermerDetailUser() {
                    this.modal_user = false,
                        this.nom = '',
                        this.contact = '', this.adresse = '', this.email = '', this.statut = ''
                }

            }
        }
    // }
</script>
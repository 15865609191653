<!-- eslint-disable vue/require-v-for-key -->
<template>
    <div class="gray  w-screen lg:w-10/12 float-right h-screen flexbox  justify-center">
        <div class="bg-white max-sm:p-2 sm:h-fit mx-4 flex items-center rounded mb-3">
            <div class="flex p-2 justify-center items-center lg:hidden">
                <div class="fas fa-bars fa-2x text-gray-600 cursor-pointer" @click="modal_menu = !modal_menu"></div>
            </div>
            <Header :name_page="name_page" class="p-2"></Header>
        </div><br>
        <div class="bg-white h-fit mx-4 flex items-center justify-center rounded my-3 ">
            <div class="w-full text-xs sm:text-base max-lg:overflow-x-auto p-2">
                <table class="w-full max-w-full lg:mx-4 max-lg:mx-2">
                    <thead class="text-left bg-blue-50 ">
                        <th class="hidden">IdMessage</th>
                        <th>Nom</th>
                        <th class="hidden">Numero</th>
                        <th>Email</th>
                        <th>Objet</th>
                        <th class="hidden">Message</th>
                        <th></th>
                    </thead>
                    <tbody v-if="messages.length > 0" class="divide-y">
                        <tr v-for="message in messages" :key="message.idMessage" class="hover:bg-gray-100">
                            <td class="hidden">{{ message.idMessage }}</td>
                            <td>{{ message.nom }}</td>
                            <td class="hidden">{{ message.numero }}</td>
                            <td>{{ message.email }}</td>
                            <td>{{ message.objet }}</td>
                            <td class="hidden">{{ message.sms }}</td>
                            <td class="flex gap-2 justify-center py-2">
                                <div @click="modal_details = true, idMessage = message.idMessage, nom = message.nom, numero = message.numero, email = message.email, objet = message.objet, sms = message.sms" class="fa fa-eye text-gray-500 active:bg-gray-200 cursor-pointer"></div>
                                <div @click="modal_suppr = true, idMessage1 = message.idMessage, nom = message.nom" class="fa fa-trash text-red-300 active:bg-gray-200 cursor-pointer"></div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
    <Menu></Menu>
    <!-- modal details -->
    
    <Modal :isOpen="modal_details" @close="modal_details=false">
        
            
            <div class="flex flex-col gap-y-3">
                <h1 class="font-bold text-xl">Message</h1>
                <hr class="w-full text-slate-700">
                <div>
                    <p class="">
                        <span class="font-bold">Nom : </span>
                        <span class="text-slate-500"> {{ nom }}</span>
                    </p>
                    <p>
                        <span class="font-bold">Email : </span>
                        <span class="text-slate-500"> {{ email }}</span>
                    </p>
                </div>
                <div>
                    <h6 class="font-bold">Objet</h6>
                    <p class="border w-full border-gray-300 p-2 text-slate-500">{{ objet }}</p>
                </div>
                <div class="">
                    <h6 class="font-bold">Contenu</h6>
                    <p class="border w-full border-gray-300 p-2 text-slate-500">{{ sms }}</p>
                </div>
            </div>
       
    </Modal>
    <Modal :isOpen="modal_suppr" @close="modal_suppr=false">
        <form action="" @submit.prevent="suppressionMessage">
            <h1 class="font-bold text-slate-600">Supression de message</h1>
            <div class="">
                <input type="text" class="hidden" v-model="idMessage1">
                <p class="text-slate-500">Voulez-vous supprimer ce message ? </p>
            </div>
            <div class="w-full flex justify-between gap-x-3 items-center">
                <button class="border border-slate-500 text-slate-500 rounded w-full mt-2 p-1 cursor-pointer ">Annuler</button>
                <button type="submit" class="bg-blue-500 rounded mt-2 p-1 cursor-pointer w-full text-white flex justify-center items-center">
                                    <span class="fa fa-spinner animate-spin" v-if="load.message.suppression"></span>
                                    <span class="" v-else>Confirmer</span>
                            </button>
            </div>
        </form>
    </Modal>
    <Sidebar :modal_menu="modal_menu" @close="modal_menu=false"></Sidebar>
</template>
<style>
    .v-enter-from,
    .v-leave-to {
        transform: translateX(-1000px);
    }
    .v-enter-active,
    .v-leave-active {
        translate: transform 0.5s;
    }
</style>
<script>
    import Menu from '@/components/Admin/Menu.vue'
    import Header from '@/components/Admin/Header.vue'
    import Filtre from '@/components/Admin/Filtre.vue'
    import '@/assets/styleAdmin/page.css';
    import axios from 'axios';
    import apiUrl from '../../ApiConfig';
     import Sidebar from '@/components/Admin/sidebar/sidebar.vue'
    import Modal from "@/components/modal/modal.vue"
    export default {
        // eslint-disable-next-line vue/multi-word-component-names
        name: 'Message',
        components: {
            Menu,
            Filtre,
            Header,
            Modal,Sidebar
        },
        data() {
            return {
                messages: [],
                nom: '',
                email: '',
                numero: '',
                sms: '',
                objet: '',
                idMessage1: '',
                idMessage: '',
                modal_menu: false,
                menu_item: [{
                        title: 'Dashboard',
                        link: '/Admin/Dashboard',
                        logo: 'fa fa-house '
                    },
                    {
                        title: 'Comparaison',
                        link: '/Admin/Comparaison',
                        logo: 'fas fa-chart-line'
                    },
                    {
                        title: 'Categorie',
                        link: '/Admin/Categorie',
                        logo: 'fa fa-list-check '
                    },
                    {
                        title: 'Utilisateur',
                        link: '/Admin/Utilisateur',
                        logo: 'fa fa-users '
                    },
                    {
                        title: 'Abonnement',
                        link: '/Admin/subscription',
                        logo: 'fa fa-users '
                    },
                    {
                        title: 'Client',
                        link: '/Admin/Client',
                        logo: 'fa fa-user'
                    },
                    {
                        title: 'Fournisseur',
                        link: '/Admin/Fournisseur',
                        logo: 'fa fa-house '
                    },
                    {
                        title: 'Investisseur',
                        link: '/Admin/Investisseur',
                        logo: 'fa fa-money-check '
                    },
                    {
                        title: 'Message',
                        link: '/Admin/Message',
                        logo: 'fas fa-message '
                    },
                ],
                //ovaina ny nom n variable name-page
                name_page: this.$options.name,
                modal_modif: false,
                modal_ajout: false,
                modal_suppr: false,
                modal_details: false,
                Prix1: '',
                Prix2: '',
                Categorie: '',
                Produit: '',
                Recherche: '',
                load: {
                    message: {
                        suppression: false
                    }
                }
            }
        },
        methods: {
            getMessages() {
                try {
                    axios.get(apiUrl + 'api/affichage_message').then(response => {
                        this.messages = response.data
                        // eslint-disable-next-line no-undef
                    }).catch(error => {
                        console.log(error)
                        this.messages = []
                    })
                } catch (error) {
                    console.error(error)
                }
            },
            suppressionMessage() {
                this.load.message.suppression = true
                try {
                    axios.post(apiUrl + 'api/suppression_message', {
                        idMessage: this.idMessage1
                        // eslint-disable-next-line no-unused-vars
                    }).then(response => {
                        this.modal_suppr = false;
                        this.getMessages();
                        this.load.message.suppression = false
                    }).catch(error => {
                        (
                            console.log(error)
                        )
                    });
                } catch (error) {
                    console.error(error)
                }
            },
            data_modal_ajout(data) {
                this.modal_ajout = data
            },
            receive_data_filter(props1, props2, props3, props4, props5) {
                this.Prix1 = props1
                this.Prix2 = props2
                this.Categorie = props3
                this.Produit = props4
                this.Recherche = props5
                alert(props5)
            }
        },
        mounted() {
            this.getMessages();
        }
    }
</script>
<template>
    <div class="">
        <div class="gray max-lg:w-screen lg:w-10/12 float-right h-screen flexbox  justify-center">
            <div class="bg-white max-sm:p-2 flex sm:h-fit mx-4  items-center rounded mb-2">
                <div class="flex p-2 justify-center items-center lg:hidden">
                    <div class="fas fa-bars fa-2x text-gray-600 cursor-pointer" @click="modal_menu = !modal_menu"></div>
                </div>
                <Header :name_page="name_page" class="p-2"></Header>
            </div>
            <div class="bg-white h-fit mx-4 flex justify-end items-center rounded py-2 ">
                <div class="flex  justify-between items-center">
                    <div class="flex  items-center justify-center ">
                        <div class=" max-lg:px-2 lg:mx-4"><label for="" class="w-fit">Prix</label></div>
                        <div class="flex gap-3">
                            <div><input type="number" class="border py-1 border-gray-300 rounded w-full" v-model="inpPrix1"></div>
                            <div><input type="number" class="border py-1 border-gray-300 rounded w-full" name="" id="" v-model="inpPrix2"></div>
                        </div>
                    </div>
                    <div class="flex  items-center max-sm:justify-start justify-center ">
                        <div class="flex  lg:mx-2 max-lg:w-7/12 max-lg:px-2"><label for="">Categorie</label></div>
                        <div class="flex max-sm:w-5/12">
                            <select name="" id="" v-model="inpCat" class="border border-gray-300 rounded p-1 w-full">
                                    <option v-for="categorie in categories" :key="categorie.nomcat"
                                        :value="categorie.nomcat" class="w-full">{{ categorie.nomcat }}</option>
                                </select>
                        </div>
                    </div>
                    <div class="flex justify-end items-center ">
                        <div class="flex gap-3 ">
                            <div class="flex m-2 items-center h-fit justify-center border rounded border-gray-400">
                                <div class="flex ">
                                    <input type="text" name="" id="" v-model="inpProduit" placeholder="Rechercher..." class=" px-1 py-1 focus:outline-none placeholder:text-gray-300">
                                </div>
                                <div class="flex justify-center items-center">
                                    <div class="fa fa-search px-1 " @click="rechercheClient(), rechercheFournisseur()">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class=" h-fit mx-4 md:flex max-md:grid max-md:grid-cols-1  items-start gap-2 justify-center  my-3 ">
                <div class="max-sm:w-full h-full md:w-1/2 bg-white rounded px-2 ">
                    <div class="my-5">
                        <h5 class="text-center text-xl font-semibold">Client</h5>
                    </div>
                    <table class="max-w-full w-full px-2 max-lg:mx-2 ">
                        <thead class="text-left bg-blue-50 text-slate-900 font-semibold">
                            <th style="display: none;">Idcli</th>
                            <th>Nom</th>
                            <th style="display: none;">Contact</th>
                            <th style="display: none;">Email</th>
                            <th style="display: none;">Adresse</th>
                            <th>Produit Demandé</th>
                            <th style="display: none;">Quantité</th>
                            <th style="display: none;">Unité</th>
                            <th>PU</th>
                            <th style="display: none;">Description</th>
                            <th style="display: none;">Lieu</th>
                            <th style="display: none;">Besoin</th>
                            <th>Actions</th>
                        </thead>
                        <tbody v-if="clients.length > 0" class="divide-y">
                            <tr v-for="client in clients" :key="client.idcli" class="hover:bg-blue-50  max-lg:px-2">
                                <td style="display: none;">{{ client.idcli }}</td>
                                <td>{{ client.nom }}</td>
                                <td style="display: none;">{{ client.contact }}</td>
                                <td style="display: none;">{{ client.email }}</td>
                                <td style="display: none;">{{ client.adresse }}</td>
                                <td>{{ client.prodDemande }}</td>
                                <td style="display: none;">{{ client.quantite }}</td>
                                <td style="display: none;">{{ client.unite }}</td>
                                <td>{{ client.prixUnitaire }}</td>
                                <td style="display: none;">{{ client.description }}</td>
                                <td style="display: none;">{{ client.lieu }}</td>
                                <td style="display: none;">{{ client.besoin }}</td>
                                <td style="display: none;">{{ client.nomcat }}</td>
                                <td class="flex gap-4 justify-center py-2">
                                    <div @click="modal_details_client = true, idcli = client.idcli, nom1 = client.nom, contact1 = client.contact, email1 = client.email, adresse1 = client.adresse, prodDemande1 = client.prodDemande,
                            quantite1 = client.quantite, unite1 = client.unite, prixUnitaire1 = client.prixUnitaire, description1 = client.description, lieu1 = client.lieu, besoin1 = client.besoin, nomcat1 = client.nomcat" class="fa fa-eye text-blue-400 "></div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="max-sm:w-full h-full md:w-1/2 bg-white rounded">
                    <div class="my-5">
                        <h5 class="text-center text-xl font-semibold">Fournisseur</h5>
                    </div>
                    <table class="min-w-full lg:mx-2 max-lg:px-2">
                        <thead class="text-left bg-blue-50 text-slate-900 font-semibold">
                            <th style="display: none;">Idfourni</th>
                            <th>Nom</th>
                            <th style="display: none;">Contact</th>
                            <th style="display: none;">Email</th>
                            <th style="display: none;">Adresse</th>
                            <th>Produit Disponible</th>
                            <th style="display: none;">Quantité</th>
                            <th style="display: none;">Unité</th>
                            <th>PU</th>
                            <th style="display: none;">Description</th>
                            <th style="display: none;">Lieu</th>
                            <th style="display: none;">Capacité</th>
                            <th>Action</th>
                        </thead>
                        <tbody class="divide-y">
                            <tr v-for="fournisseur in fournisseurs" :key="fournisseur.idfourni" class="hover:bg-blue-50 max-lg:px-2 py-3">
                                <td style="display: none;">{{ fournisseur.idfourni }}</td>
                                <td>{{ fournisseur.nom }}</td>
                                <td style="display: none;">{{ fournisseur.contact }}</td>
                                <td style="display: none;">{{ fournisseur.email }}</td>
                                <td style="display: none;">{{ fournisseur.adresse }}</td>
                                <td>{{ fournisseur.prodDispo }}</td>
                                <td style="display: none;">{{ fournisseur.quantite }}</td>
                                <td style="display: none;">{{ fournisseur.unite }}</td>
                                <td>{{ fournisseur.prixUnitaire }}</td>
                                <td style="display: none;">{{ fournisseur.description }}</td>
                                <td style="display: none;">{{ fournisseur.lieu }}</td>
                                <td style="display: none;">{{ fournisseur.capaciteProd }}</td>
                                <td class="flex  justify-center items-center py-2">
                                    <div @click="modal_details_fournisseur = true, idfourni = fournisseur.idfourni, nom = fournisseur.nom, contact = fournisseur.contact, email = fournisseur.email, adresse = fournisseur.adresse, prodDispo = fournisseur.prodDispo,
                            quantite = fournisseur.quantite, unite = fournisseur.unite, prixUnitaire = fournisseur.prixUnitaire, description = fournisseur.description, lieu = fournisseur.lieu, capaciteProd = fournisseur.capaciteProd, nomcat = fournisseur.nomcat"
                                        class="fa fa-eye text-blue-400"></div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
    <Menu></Menu>
    <Sidebar :modal_menu="modal_menu" @close="modal_menu=false"></Sidebar>
    <!--modal details-->

    <Modal :isOpen="modal_details_client" @close="modal_details_client=false">
        <div class="flex justify-center">
            <div>
                <h1 class="font-bold pb-2">Détails Client</h1>
            </div>
        </div>
        <div class="">
            <div class="">
                <form class="">
                    <div class="grid max-sm:grid-cols-1 sm:grid-cols-2 gap-y-4">
                        <div class="gap-y-2">
                            <div class="flex flex-wrap gap-x-2 justify-start items-center my-2">
                                <div class="w-3/12"><label for="Nom">Nom</label></div>
                                <div class="w-8/12"><input type="text" name="Nom" class="rounded border w-full border-gray-300 py-1 px-2" disabled v-model="nom1"></div>
                            </div>
                            <div class="flex flex-wrap gap-x-2 justify-start items-center my-2">
                                <div class="w-3/12"><label for="Contact">Contact</label></div>
                                <div class="w-8/12"><input type="text" name="Contact" class="rounded border w-full border-gray-300 py-1 px-2" disabled v-model="contact1"></div>
                            </div>
                            <div class="flex flex-wrap gap-x-2 justify-start items-center my-2">
                                <div class="w-3/12"><label for="Email">Email</label></div>
                                <div class="w-8/12"><input type="email" name="Email" class="rounded border w-full border-gray-300 py-1 px-2" disabled v-model="email1"></div>
                            </div>
                            <div class="flex flex-wrap gap-x-2 justify-start items-center my-2">
                                <div class="w-3/12"><label for="Adresse">Adresse</label></div>
                                <div class="w-8/12"><input type="text" name="Adresse" class="rounded border w-full border-gray-300 py-1 px-2" disabled v-model="adresse1"></div>
                            </div>
                        </div>
                        <div class="gap-y-2">
                            <div class="flex flex-wrap gap-x-2 justify-start items-center my-2">
                                <div class="w-3/12"><label for="Produit demandé">Produit demandé</label></div>
                                <div class="w-8/12"><input type="text" name="Produit demandé" class="rounded w-full border border-gray-300 py-1 px-2 " disabled v-model="prodDemande1"></div>
                            </div>
                            <div class="flex flex-wrap gap-x-2 justify-start items-center my-2">
                                <div class="w-3/12"><label for="quantite">Quantite</label></div>
                                <div class="w-8/12"><input type="number" name="quantite" class="rounded border w-full border-gray-300 py-1 px-2" disabled v-model="quantite1"></div>
                            </div>
                            <div class="flex flex-wrap gap-x-2 justify-start items-center my-2">
                                <div class="w-3/12"><label for="unite">Unite</label></div>
                                <div class="w-8/12"><input type="text" name="unite" class="rounded border w-full border-gray-300 px-2 py-1" disabled v-model="unite1"></div>
                            </div>
                            <div class="flex flex-wrap gap-x-2 justify-start items-center my-2">
                                <div class="w-3/12"><label for="PrixUnitaire">Prix unitaire</label></div>
                                <div class="w-8/12"><input type="number" name="PrixUnitaire" class="rounded border w-full border-gray-300 px-2 py-1 " disabled v-model="prixUnitaire1"></div>
                            </div>
                            <div class="flex flex-wrap gap-x-2 justify-start items-center my-2">
                                <div class="w-3/12"><label for="description">Description</label></div>
                                <div class="w-8/12"><input type="text" name="quantite" class="rounded border w-full border-gray-300 px-2 py-1" disabled v-model="description1"></div>
                            </div>
                            <div class="flex flex-wrap gap-x-2 justify-start items-center my-2">
                                <div class="w-3/12"><label for="lieu">Lieu</label></div>
                                <div class="w-8/12"><input type="text" name="lieu" class="rounded border w-full border-gray-300 px-2 py-1" disabled v-model="lieu1"></div>
                            </div>
                            <div class="flex flex-wrap gap-x-2 justify-start items-center my-2">
                                <div class="w-3/12"><label for="Besoin">Besoin</label></div>
                                <div class="w-8/12"><input type="text" name="Besoin" class="rounded border w-full border-gray-300 px-2 py-1" disabled v-model="besoin1"></div>
                            </div>
                            <div class="flex flex-wrap gap-x-2 my-2">
                                <div class="w-3/12"><label for="Besoin">Catégorie</label></div>
                                <div class="w-8/12">
                                    <input type="text" name="Besoin" class="rounded border  w-full border-gray-300 px-2 " disabled v-model="nomcat1">
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </Modal>
    <Modal :isOpen="modal_details_fournisseur" @close="modal_details_fournisseur=false">
        <div class="flex justify-center">
            <div>
                <h1 class="font-bold pb-2">Détails fournisseur</h1>
            </div>
        </div>
        <div class="flex justify-center gap-5">
            <div>
                <form>
                    <div class="md:grid md:grid-cols-2 gap-4 justify-start ">
                        <div>
                            <div class="   p-2 hidden">
                                <div class=""><label for="idfourni">idfourni</label></div>
                                <div><input v-model="idfourni1" type="text" name="idfourni" class="rounded border w-full border-gray-300 p-1"></div>
                            </div>
                            <div class="flex justify-between p-2">
                                <div><label for="nom">Nom</label></div>
                                <div><input v-model="nom" type="text" name="nom" class="rounded border w-full border-gray-300 p-1" disabled></div>
                            </div>
                            <div class=" flex justify-between p-2">
                                <div><label for="Contact">Contact</label></div>
                                <div><input v-model="contact" type="text" name="Contact" disabled class="rounded border w-full border-gray-300 p-1"></div>
                            </div>
                            <div class="flex justify-between p-2">
                                <div><label for="email">Email</label></div>
                                <div><input v-model="email" type="text" name="email" disabled class="rounded border w-full border-gray-300 p-1"></div>
                            </div>
                            <div class=" flex justify-between p-2">
                                <div><label for="adresse">Adresse</label></div>
                                <div><input v-model="adresse" type="text" disabled name="adresse" class="rounded border w-full border-gray-300 p-1"></div>
                            </div>
                            <div class="flex justify-between p-2">
                                <div><label for="lieu">Lieu</label></div>
                                <div><input v-model="lieu" type="text" name="lieu" disabled class="rounded border w-full border-gray-300 p-1"></div>
                            </div>
                        </div>
                        <div>
                            <div class=" flex justify-between p-2">
                                <div><label for="prodDispo">Produit Disponible</label></div>
                                <div><input v-model="prodDispo" disabled type="text" name="prodDispo" class="rounded border w-full border-gray-300 p-1"></div>
                            </div>
                            <div class="flex justify-between p-2">
                                <div><label for="quantite">Quantite</label></div>
                                <div><input v-model="quantite" disabled type="text" name="quantite" class="rounded border w-full border-gray-300 p-1"></div>
                            </div>
                            <div class="flex justify-between p-2">
                                <div><label for="unite">Unite</label></div>
                                <div><input v-model="unite" type="text" disabled name="unite" class="rounded border w-full border-gray-300 p-1"></div>
                            </div>
                            <div class=" flex justify-between p-2">
                                <div><label for="prixUnitaire">prixUnitaire</label></div>
                                <div><input v-model="prixUnitaire" type="text" disabled name="prixUnitaire" class="rounded w-full border border-gray-300 p-1"></div>
                            </div>
                            <div class="flex justify-between p-2">
                                <div><label for="description">Description</label></div>
                                <div><input v-model="description" type="text" disabled name="description" class="rounded border w-full border-gray-300 p-1"></div>
                            </div>
                            <div class="flex justify-between p-2">
                                <div><label for="capaciteProd">Capacite</label></div>
                                <div><input v-model="capaciteProd" type="text" disabled name="capaciteProd" class="rounded w-full border border-gray-300 p-1"></div>
                            </div>
                            <div class="flex justify-between p-2">
                                <div><label for="Besoin">Catégorie</label></div>
                                <div class=" ">
                                    <input type="text" name="Besoin" class="rounded border  w-full border-gray-300 px-2 " disabled v-model="nomcat">
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </Modal>
</template>
<style >
    .v-enter-active,
    .v-leave-active {
        transition: transform 0.6s;
    }
    .v-enter-from,
    .v-leave-to {
        transform: translateX(-500px)
    }
</style>
<script>
    import apiUrl from '../../ApiConfig';
    import axios from 'axios'
    import Menu from '@/components/Admin/Menu.vue'
    import Header from '@/components/Admin/Header.vue'
    //import Filtre from '@/components/Admin/Filtre.vue'
    import '@/assets/styleAdmin/page.css'
    import Modal from "@/components/modal/modal.vue"
     import Sidebar from '@/components/Admin/sidebar/sidebar.vue'
    export default {
        name: 'Comparaison',
        components: {
            Menu,
            Header,
            Modal,Sidebar
        },
        data() {
            return {
                //ovaina ny nom n variable name-page
                menu_item: [{
                        title: 'Dashboard',
                        link: '/Admin/Dashboard',
                        logo: 'fa fa-house '
                    },
                    {
                        title: 'Comparaison',
                        link: '/Admin/Comparaison',
                        logo: 'fas fa-chart-line'
                    },
                    {
                        title: 'Categorie',
                        link: '/Admin/Categorie',
                        logo: 'fa fa-list-check '
                    },
                    {
                        title: 'Utilisateur',
                        link: '/Admin/Utilisateur',
                        logo: 'fa fa-users '
                    },
                    {
                        title: 'Abonnement',
                        link: '/Admin/subscription',
                        logo: 'fa fa-users '
                    },
                    {
                        title: 'Client',
                        link: '/Admin/Client',
                        logo: 'fa fa-user'
                    },
                    {
                        title: 'Fournisseur',
                        link: '/Admin/Fournisseur',
                        logo: 'fa fa-house '
                    },
                    {
                        title: 'Investisseur',
                        link: '/Admin/Investisseur',
                        logo: 'fa fa-money-check '
                    },
                    {
                        title: 'Message',
                        link: '/Admin/Message',
                        logo: 'fas fa-message '
                    },
                ],
                modal_menu: false,
                name_page: this.$options.name,
                modal_modif: false,
                modal_ajout: false,
                modal_suppr: false,
                modal_details_client: false,
                modal_details_fournisseur: false,
                inpPrix1: '',
                inpPrix2: '',
                inpCat: '',
                inpProduit: '',
                Recherche: '',
                idfourni: '',
                nom: '',
                contact: '',
                email: '',
                adresse: '',
                prodDispo: '',
                quantite: '',
                unite: '',
                prixUnitaire: '',
                description: '',
                lieu: '',
                capaciteProd: '',
                nomcat: '',
                //input modal modif et details
                idfourni1: '',
                fournisseurs: [],
                categories: [],
                idcli: '',
                nom1: '',
                contact1: '',
                email1: '',
                adresse1: '',
                prodDemande1: '',
                quantite1: '',
                unite1: '',
                prixUnitaire1: '',
                description1: '',
                lieu1: '',
                besoin1: '',
                nomcat1: '',
                //input modal modif et details
                idcli1: '',
                clients: [],
            }
        },
        mounted() {
            this.getClients();
            this.categorieSelection();
            this.getFournisseurs();
        },
        methods: {
            data_modal_ajout(data) {
                this.modal_ajout = data
            },
            categorieSelection() {
                try {
                    axios.get(apiUrl + 'api/affichage_nom_categorie').then(response => {
                        this.categories = response.data
                    }).catch(error => {
                        console.log(error)
                    })
                } catch (error) {
                    console.error(error)
                }
            },
            getClients() {
                try {
                    axios.get(apiUrl + 'api/affichage_client_seera').then(response => {
                        this.clients = response.data
                        // eslint-disable-next-line no-undef
                    }).catch(error => {
                        console.log(error)
                        this.clients = []
                    })
                } catch (error) {
                    console.error(error)
                }
            },
            getFournisseurs() {
                try {
                    axios.get(apiUrl + 'api/affichage_fournisseur_seera').then(response => {
                        this.fournisseurs = response.data
                        // eslint-disable-next-line no-undef
                    }).catch(error => {
                        console.log(error)
                        this.fournisseurs = []
                    })
                } catch (error) {
                    console.error(error)
                }
            },
            rechercheClient() {
                try {
                    axios.get(apiUrl + 'api/recherche_client_seera', {
                        params: {
                            inpProduit: this.inpProduit,
                            inpPrix1: this.inpPrix1,
                            inpPrix2: this.inpPrix2,
                            inpCat: this.inpCat
                        }
                    }).then(response => {
                        this.clients = response.data
                    }).catch(error => {
                        console.log(error)
                    })
                } catch (error) {
                    console.error(error)
                }
            },
            rechercheClient1() {
                try {
                    axios.get(apiUrl + 'api/recherche_client_seera', {
                        params: {
                            inpProduit: '',
                            inpPrix1: '',
                            inpPrix2: '',
                            inpCat: ''
                        }
                    }).then(response => {
                        this.clients = response.data
                    }).catch(error => {
                        console.log(error)
                    })
                } catch (error) {
                    console.error(error)
                }
            },
            rechercheFournisseur() {
                try {
                    axios.get(apiUrl + 'api/recherche_fournisseur', {
                        params: {
                            inpProduit: this.inpProduit,
                            inpPrix1: this.inpPrix1,
                            inpPrix2: this.inpPrix2,
                            inpCat: this.inpCat
                        }
                    }).then(response => {
                        this.fournisseurs = response.data
                    }).catch(error => {
                        console.log(error)
                    })
                } catch (error) {
                    console.error(error)
                }
            },
            rechercheFournisseur1() {
                try {
                    axios.get(apiUrl + 'api/recherche_fournisseur', {
                        params: {
                            inpProduit: '',
                            inpPrix1: '',
                            inpPrix2: '',
                            inpCat: ''
                        }
                    }).then(response => {
                        this.fournisseurs = response.data
                    }).catch(error => {
                        console.log(error)
                    })
                } catch (error) {
                    console.error(error)
                }
            },
            init() {
                this.inpProduit = '', this.inpPrix1 = '', this.inpPrix2 = '', this.inpCat = ''
            },
            receive_data_filter(props1, props2, props3, props4, props5) {
                this.Prix1 = props1
                this.Prix2 = props2
                this.Categorie = props3
                this.Produit = props4
                this.Recherche = props5
            }
        }
    }
</script>

<template>
    <div class="flex max-sm:grid text-sm sm:text-base sm:grid-cols-4 justify-around items-center">
        <div class="flex justify-start items-center">
            <div class="flex gap-x-2  bg-blue-500 text-white h-8 px-3 rounded justify-center items-center cursor-pointer" @click="modal_ajout_show()">
                <span  class="fa fa-plus"></span>
                <span>
                    Ajouter
                </span>
            </div>
        </div>
                       

    </div>
</template>
<script>
    export default{
        // eslint-disable-next-line vue/multi-word-component-names
        name:'Filtre',
        emits:[
            'modal_ajout_show',
            'data_filter',
            'data_reset'
        ],
        props:{
            categorie:{
                type: Array, 
                required: true 
            }
        },
        data(){
            return{
                windowWidth:window.innerWidth,
                modal_ajout:false,
                //donne de formulaire de recherche 
                
                inpPrix1:'',
                inpPrix2:'',
                inpProduit:'',
                inpCat:'',
                categories:this.$props.categorie

            }
        },methods:{
            modal_ajout_show(){
                this.$emit('modal_ajout_show',!this.modal_ajout)
            },
            send_filter(){
                this.$emit('data_filter',this.inpPrix1,this.inpPrix2,this.inpCat,this.inpProduit)
            },
            init_filter(){
                this.inpCat=''
                this.inpPrix1=''
                this.inpPrix2=''
                this.inpProduit=''
                this.$emit('data_reset')
            }
        }
    }
</script>
<template>
    <Transition>
        <div v-if="isOpen" class=" modal_ajout inset-0 z-20   flex justify-center items-center  ">
            <div class="w-screen h-screen modal_absolute z-30 inset-0 bg-slate-700/80" @click="close()"></div>
            <div class="p-3 modal_absolute max-md:w-full md:w-8/12   lg:w-6/12 m-auto flex flex-col  gap-y-1 z-40 bg-white max-h-screen overflow-y-auto">
                <div class="w-full flex justify-end items-center">
                    <button @click="close()" class="fa font-semibold fa-times "></button>
                </div>
                <div class="">
                    <slot></slot>
                </div>
            </div>
        </div>
    </Transition>
</template>
<style scoped>
    .v-enter-from,
    .v-leave-to {
        transform: translateY(-20px);
        opacity: 0;
    }
    .v-enter-active,
    .v-leave-active {
        transition: all 0.1s;
    }
    .modal_ajout {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 4;
    }
</style>
<script>
    export default {
        props: {
            isOpen: {
                type: Boolean,
                required: true,
            }
        },
        methods: {
            close() {
                this.$emit('close');
            }
        }
    }
</script>
<style lang="">

</style>
<template>


    <div class="text-center hidden">
        <!-- <p class="">Bienvenue </p> -->
        <p class="hidden">{{ iduser }}</p>
        <!--<p class="hidden">{{ typeAbonnement }}</p>

        <p class="hidden">{{ userEmail }}</p>
        <p class="hidden">{{ userContact }}</p>-->
  </div>

  <div>

    <Header2 @data_menu_mobile2="receive_data_menu_mobile"></Header2>
    <div class="w-screen transition-all scroll-smooth flex flex-col justify-center items-center">
      <Service />

      <br><br>
      <Partenaire />
      <Propos />


      <Pricing />
      <Contact />
    </div>
    <Footer></Footer>
  </div>
  <Menu :modal_menu="modal_menu" @close="modal_menu=false"></Menu>
</template>

<script>


import Footer from './../../components/Client/Footer.vue'
import Header2 from './../../components/Client/Header2.vue'
import Pricing from './../../components/Client/Pricing.vue'
import Contact from './../../components/Client/Contact2.vue'
import Service from './../../components/Client/Service.vue'
import Partenaire from './../../components/Client/Partenaire.vue'
import Propos from './../../components/Client/About.vue'
import Menu from '@/components/Client/menu/Menu.vue'
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Accueil',
  components: {
    Footer, Header2, Pricing, Contact, Service, Partenaire, Propos,Menu
  },
  data() {
    return {

      iduser: '',

      modal_menu: false,
      sections: [
        { link: '/home', title: 'Accueil' },
        { link: '/customer', title: 'Client' },
        { link: '/supplier', title: 'Fournisseur' },
        { link: '/investor', title: 'Investisseur' },
        { link: '/comparison', title: 'Comparaison' },
      ],
    }
  },

  mounted() {
    this.iduser = localStorage.getItem('iduser');

  },

  methods: {
    receive_data_menu_mobile(data) {
      this.modal_menu = data
    }
  }

}

</script>
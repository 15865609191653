<template>
    <div class="gray max-lg:w-screen z-0 lg:w-10/12 float-right h-screen flexbox  justify-center">
        <div class="bg-white max-sm:p-1 sm:h-fit mx-4 flex items-center rounded mb-3">
            <div class="flex p-2 justify-center items-center lg:hidden">
                <div class="fas fa-bars fa-2x text-gray-600 cursor-pointer" @click="modal_menu = !modal_menu"></div>
            </div>
            <Header :name_page="name_page" class="p-1"></Header>
        </div>
        <div class="bg-white h-fit mx-4  items-center  rounded  flex">
            <div class="flex  justify-between items-center gap-6 max-lg:py-2 px-1 w-full">
                <div class=" flex max-sm:justify-start justify-center items-center">
                    <Filtre @modal_ajout_show="data_modal_ajout"></Filtre>
                </div>
                <div class="flex items-center justify-center max-sm:w-11/12 sm:w-4/12">
                    <div class="w-2/12"><label for="">Prix</label></div>
                    <div class="flex w-10/12">
                        <div class="w-5/12"><input type="number" class="border rounded p-1 w-full" v-model="inpPrix1">
                        </div>
                        <div class="w-2/12 flex justify-center  items-center">
                            <div><span class="">et</span></div>
                        </div>
                        <div class="w-5/12"><input type="number" class="border rounded p-1 w-full" name="" id="" v-model="inpPrix2"></div>
                    </div>
                </div>
                <div class="flex items-center max-sm:justify-start justify-center max-sm:w-11/12 sm:w-3/12">
                    <div class="flex  max-sm:w-5/12 mx-2"><label for="">Categorie</label></div>
                    <div class="flex max-sm:w-7/12">
                        <select name="" id="" v-model="inpCat" class="border border-gray-300 rounded w-full p-1">
        
                                                <option v-for="categorie in categories" :key="categorie.nomcat" class="w-full"
        
                                                    :value="categorie.nomcat">{{ categorie.nomcat }}</option>
        
                                            </select>
                    </div>
                </div>
                <div class="flex justify-end items-center max-sm:w-11/12 sm:w-3/12">
                    <div class="flex gap-3 ">
                        <div class="flex md:m-2 items-center h-fit justify-center border rounded border-gray-400">
                            <div class="flex ">
                                <input type="text" name="" id="" v-model="inpProduit" placeholder="Rechercher..." class="p-1 focus:outline-none w-full placeholder:text-gray-300">
                            </div>
                            <div class="flex justify-center items-center">
                                <div class="fa fa-search p-1" @click="rechercheFournisseur()"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="bg-white h-fit mx-4 flex items-center justify-center rounded my-3 ">
            <div class="w-full ">
                <table class="min-w-full lg:mx-4 max-lg:mx-2">
                    <thead class="text-left font-semibold bg-blue-50">
                        <th style="display: none;">Idfourni</th>
                        <th>Nom</th>
                        <th>Contact</th>
                        <th style="display: none;">Email</th>
                        <th style="display: none;">Adresse</th>
                        <th>Produit disponible</th>
                        <th class="max-sm:hidden">Quantité</th>
                        <th class="max-sm:hidden">Unité</th>
                        <th class="max-sm:hidden">PU</th>
                        <th style="display: none;">Description</th>
                        <th style="display: none;">Lieu</th>
                        <th style="display: none;">Capacite</th>
                        <th>Actions</th>
                    </thead>
                    <tbody v-if="fournisseurs.length > 0" class='text-left divide-y'>
                        <tr v-for="fournisseur in fournisseurs" :key="fournisseur.idfourni" class="hover:bg-blue-50">
                            <td style="display: none;">{{ fournisseur.idfourni }}</td>
                            <td>{{ fournisseur.nom }}</td>
                            <td>{{ fournisseur.contact }}</td>
                            <td style="display: none;">{{ fournisseur.email }}</td>
                            <td style="display: none;">{{ fournisseur.adresse }}</td>
                            <td>{{ fournisseur.prodDispo }}</td>
                            <td class="max-sm:hidden">{{ fournisseur.quantite }}</td>
                            <td class="max-sm:hidden">{{ fournisseur.unite }}</td>
                            <td class="max-sm:hidden">{{ fournisseur.prixUnitaire }}</td>
                            <td style="display: none;">{{ fournisseur.description }}</td>
                            <td style="display: none;">{{ fournisseur.lieu }}</td>
                            <td style="display: none;">{{ fournisseur.capaciteProd }}</td>
                            <td class="flex gap-x-2 justify-center items-center max-lg:gap-x-2 py-2">
                                <div @click="modal_details = true, idfourni = fournisseur.idfourni, nom = fournisseur.nom, contact = fournisseur.contact, email = fournisseur.email, adresse = fournisseur.adresse, prodDispo = fournisseur.prodDispo, province = fournisseur.province, region = fournisseur.region,
        
                                        quantite = fournisseur.quantite, unite = fournisseur.unite, prixUnitaire = fournisseur.prixUnitaire, description = fournisseur.description, lieu = fournisseur.lieu, capaciteProd = fournisseur.capaciteProd, nomcat = fournisseur.nomcat, verification = fournisseur.verification"
                                    class="fa fa-eye text-blue-500 active:bg-blue-400"></div>
                                <div @click="modal_modif = true, idfourni = fournisseur.idfourni, nom = fournisseur.nom, contact = fournisseur.contact, email = fournisseur.email, adresse = fournisseur.adresse, prodDispo = fournisseur.prodDispo, province = fournisseur.province, region = fournisseur.region,
        
                                        quantite = fournisseur.quantite, unite = fournisseur.unite, prixUnitaire = fournisseur.prixUnitaire, description = fournisseur.description, lieu = fournisseur.lieu, capaciteProd = fournisseur.capaciteProd, nomcat = fournisseur.nomcat, verification = fournisseur.verification"
                                    class="fa fa-pencil-alt text-green-400  active:bg-gray-200"></div>
                                <div @click="modal_suppr = true, idfourni1 = fournisseur.idfourni" class="fa fa-trash text-red-300 active:bg-gray-200"></div>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <!-- <Pagination></Pagination> -->
            </div>
        </div>
    </div>
    <Menu></Menu>
    <!-- modal menu pour mobile -->
    <Sidebar :modal_menu="modal_menu" @close="modal_menu=false"></Sidebar>
    <!--modal ajout-->
    <Modal :isOpen="modal_ajout" @close="modal_ajout=false">
        <div class="flex justify-center items-center">
            <div>
                <h5 class="font-semibold">Ajout fournisseur</h5>
            </div>
        </div>
        <div class="">
            <form action="http://127.0.0.1:8000/api/ajout_fournisseur_seera" method="POST" @submit.prevent="ajoutFournisseur">
                <div class="md:grid md:grid-cols-2 gap-2">
                    <div>
                        <div class="flex justify-between  p-1">
                            <div><label for="nom">Nom</label></div>
                            <div><input v-model="fournisseur.nom" type="text" name="nom" class="rounded border w-full border-gray-300 p-1"></div>
                        </div>
                        <div class="flex justify-between  p-1">
                            <div><label for="Contact">Contact</label></div>
                            <div><input v-model="fournisseur.contact" type="text" name="Contact" class="rounded border w-full border-gray-300 p-1"></div>
                        </div>
                        <div class="flex justify-between  p-1">
                            <div><label for="email">Email</label></div>
                            <div><input v-model="fournisseur.email" type="text" name="email" class="rounded border w-full border-gray-300 p-1"></div>
                        </div>
                        <div class="flex justify-between  p-1">
                            <div><label for="adresse">Adresse</label></div>
                            <div><input v-model="fournisseur.adresse" type="text" name="adresse" class="rounded border w-full border-gray-300 p-1"></div>
                        </div>
                        <div class="flex justify-between gap-5 p-1">
                            <div><label for="Besoin">Vérification</label></div>
                            <div class="w-8/12">
                                <select v-model="fournisseur.verification" class="border border-gray-300 p-1 w-full rounded ">
        
                                                                <option value="vrai">Vrai</option>
        
                                                                <option value="faux">Faux</option>
        
                                                            </select>
                            </div>
                        </div>
                        <div class="flex justify-between gap-5 p-1">
                            <div><label for="Besoin">Catégorie</label></div>
                            <div class="w-8/12">
                                <select v-model="fournisseur.nomcat" class="rounded border border-gray-300 w-full p-1">
        
                                                                <option v-for="categorie in categories" :key="categorie.nomcat"
        
                                                                    :value="categorie.nomcat">{{ categorie.nomcat }}</option>
        
                                                            </select>
                            </div>
                        </div>
                        <div class="flex justify-between gap-5 p-1">
                            <div><label for="Besoin">Province</label></div>
                            <div class="w-8/12 ">
                                <select v-model="fournisseur.province" class="border border-gray-300 px-1 py-1 w-full rounded ">
        
                                                                <option>Antananarivo</option>
        
                                                                <option>Fianarantsoa</option>
        
                                                                <option>Mahajanga</option>
        
                                                                <option>Toliara</option>
        
                                                                <option>Toamasina</option>
        
                                                                <option>Antsiranana</option>
        
                                                            </select>
                            </div>
                        </div>
                        <div class="flex justify-between gap-5 p-1">
                            <div><label for="Besoin">Région</label></div>
                            <div class="w-8/12 ">
                                <select v-model="fournisseur.region" class="border border-gray-300 px-1 py-1 w-full rounded ">
        
                                                                <option>Menabe</option>
        
                                                                <option>Atsimo Atsinanana</option>
        
                                                                <option>Vatovavy</option>
        
                                                                <option>Fitovinany</option>
        
                                                                <option>Analamanga</option>
        
                                                                <option>Vakinakaratra</option>
        
                                                            </select>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div class="flex justify-between  p-1">
                            <div><label for="prodDispo">Disponible</label></div>
                            <div><input v-model="fournisseur.prodDispo" type="text" name="prodDispo" class="rounded border w-full border-gray-300 p-1"></div>
                        </div>
                        <div class="flex justify-between  p-1">
                            <div><label for="quantite">Quantite</label></div>
                            <div><input v-model="fournisseur.quantite" type="number" name="quantite" class="rounded border w-full border-gray-300 p-1"></div>
                        </div>
                        <div class="flex justify-between  p-1">
                            <div><label for="unite">unite</label></div>
                            <div><input v-model="fournisseur.unite" type="text" name="unite" class="rounded border w-full border-gray-300 p-1"></div>
                        </div>
                        <div class="flex justify-between  p-1">
                            <div><label for="prixUnitaire">PU</label></div>
                            <div><input v-model="fournisseur.prixUnitaire" type="text" name="prixUnitaire" class="rounded w-full border border-gray-300 p-1"></div>
                        </div>
                        <div class="flex justify-between  p-1">
                            <div><label for="description">description</label></div>
                            <div><input v-model="fournisseur.description" type="text" name="description" class="rounded border w-full border-gray-300 p-1"></div>
                        </div>
                        <div class="flex justify-between  p-1">
                            <div><label for="lieu">Lieu</label></div>
                            <div><input v-model="fournisseur.lieu" type="text" name="lieu" class="rounded border w-full border-gray-300 p-1"></div>
                        </div>
                        <div class="flex justify-between  p-1">
                            <div><label for="capaciteProd">Capacité</label></div>
                            <div><input v-model="fournisseur.capaciteProd" type="text" name="capaciteProd" class="rounded w-full border border-gray-300 p-1"></div>
                        </div>
                    </div>
                </div>
                <div class="flex max-lg:justify-between lg:justify-end w-full items-center gap-5 p-1">
                    <div class="max-lg:w-1/2"><button @click="modal_ajout = false" class="border border-gray-600 text-gray-600 rounded px-3 py-1 w-full">Annuler</button>
                    </div>
                    <div class="max-lg:w-1/2"><button class="bg-blue-400 hover:bg-bleu-500 w-full px-3 py-1 rounded text-white" type="submit">Enregitrer</button></div>
                </div>
            </form>
        </div>
    </Modal>
    <!--modal de suppresion-->
    <Modal :isOpen="modal_suppr" @close="modal_suppr=false">
        <div>
            <h5 class="font-semibold">Suppression fournisseur</h5>
            <input type="hidden" v-model="idfourni1">
        </div>
        <div class="flex justify-between w-full gap-5">
            <button @click="modal_suppr = false" class="rounded px-3 py-1 w-full border-slate-600 border text-slate-600" type="button">Annuler
        
                                </button>
            <button class="rounded px-3 p-1 text-white bg-blue-300 w-full" @click="suppressionFournisseur">Supprimer</button>
        </div>
    </Modal>
    <!--modal modification-->
    <Modal :isOpen="modal_modif" @close="modal_modif=false">
        <div class="flex justify-center items-center">
            <div>
                <h5 class="fonnt-semibold">Modification fournisseur</h5>
            </div>
        </div>
        <div class="">
            <form action="http://127.0.0.1:8000/api/modification_fournisseur_seera" method="POST" @submit.prevent="modificationFournisseur">
                <div class="md:grid md:grid-cols-2 gap-2">
                    <div>
                        <div class=" grid-cols-2 justify-between gap-5 p-1 hidden ">
                            <div><label for="idfourni">idfourni</label></div>
                            <div><input v-model="idfourni" type="text" name="idfourni" class="rounded border w-full border-gray-300 p-1"></div>
                        </div>
                        <div class="flex justify-start items-center gap-5 p-1 ">
                            <div class="w-3/12"><label for="nom">Nom</label></div>
                            <div><input v-model="nom" type="text" name="nom" class="rounded border w-full border-gray-300 p-1"></div>
                        </div>
                        <div class="flex justify-between items-centergap-5 p-1">
                            <div class="w-3/12"><label for="Contact">Contact</label></div>
                            <div class="w-9/12"><input v-model="contact" type="text" name="Contact" class="rounded w-full border border-gray-300 p-1"></div>
                        </div>
                        <div class="flex justify-start items-center gap-5 p-1">
                            <div class="w-3/12"><label for="email">Email</label></div>
                            <div><input v-model="email" type="text" name="email" class="rounded border w-full border-gray-300 p-1"></div>
                        </div>
                        <div class="flex justify-start items-center gap-5 p-1">
                            <div class="w-3/12"><label for="adresse">Adresse</label></div>
                            <div><input v-model="adresse" type="text" name="adresse" class="rounded border w-full border-gray-300 p-1"></div>
                        </div>
                        <div class="flex justify-start items-center gap-5 p-1">
                            <div class="w-3/12"><label for="lieu">lieu</label></div>
                            <div><input v-model="lieu" type="text" name="lieu" class="rounded border border-gray-300 w-full p-1"></div>
                        </div>
                        <div class="flex justify-start items-center gap-5 p-1">
                            <div class=""><label for="Besoin">Vérification</label></div>
                            <div class="w-8/12">
                                <select v-model="verification" class="border w-full border-gray-300 pp-1 rounded ">
        
                                                                <option value="vrai">Vrai</option>
        
                                                                <option value="faux">Faux</option>
        
                                                            </select>
                            </div>
                        </div>
                        <div class="flex justify-between gap-5 p-1">
                            <div><label for="Besoin">Province</label></div>
                            <div class="w-8/12 ">
                                <select v-model="province" class="border border-gray-300 px-1 py-1 w-full rounded ">
        
                                                                <option value="Antananarivo">Antananarivo</option>
        
                                                                <option value="Fianarantsoa">Fianarantsoa</option>
        
                                                                <option value="Mahajanga">Mahajanga</option>
        
                                                                <option value="Toliara">Toliara</option>
        
                                                                <option value="Toamasina">Toamasina</option>
        
                                                                <option value="Antsiranana">Antsiranana</option>
        
                                                            </select>
                            </div>
                        </div>
                        <div class="flex justify-between gap-5 p-1">
                            <div><label for="Besoin">Région</label></div>
                            <div class="w-8/12 ">
                                <select v-model="region" class="border border-gray-300 px-1 py-1 w-full rounded ">
        
                                                                <option value="Menabe">Menabe</option>
        
                                                                <option value="Atsimo Atsinanana">Atsimo Atsinanana</option>
        
                                                                <option value="Vatovavy">Vatovavy</option>
        
                                                                <option value="Fitovinany">Fitovinany</option>
        
                                                                <option value="Analamanga">Analamanga</option>
        
                                                                <option value="Vakinakaratra">Vakinakaratra</option>
        
                                                            </select>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div class="flex justify-start items-center gap-5 p-1">
                            <div class="w-3/12"><label for="prodDispo">Disponible</label></div>
                            <div><input v-model="prodDispo" type="text" name="prodDispo" class="rounded border w-full border-gray-300 p-1"></div>
                        </div>
                        <div class="flex justify-start items-center gap-5 p-1">
                            <div class="w-3/12"><label for="quantite">Quantite</label></div>
                            <div><input v-model="quantite" type="number" name="quantite" class="rounded border w-full border-gray-300 p-1"></div>
                        </div>
                        <div class="flex justify-start items-center gap-5 p-1">
                            <div class="w-3/12"><label for="unite">Unite</label></div>
                            <div><input v-model="unite" type="text" name="unite" class="rounded border w-full border-gray-300 p-1"></div>
                        </div>
                        <div class="flex justify-start items-center gap-5 p-1">
                            <div class="w-3/12"><label for="prixUnitaire">PU</label></div>
                            <div><input v-model="prixUnitaire" type="text" name="prixUnitaire" class="rounded border w-full border-gray-300 p-1"></div>
                        </div>
                        <div class="flex justify-start items-center gap-5 p-1">
                            <div class="w-3/12"><label for="description">description</label></div>
                            <div><input v-model="description" type="text" name="description" class="rounded border w-full border-gray-300 p-1"></div>
                        </div>
                        <div class="flex justify-start items-center gap-5 p-1">
                            <div class="w-3/12"><label for="capaciteProd">Capacité</label></div>
                            <div><input v-model="capaciteProd" type="text" name="capaciteProd" class="rounded border w-full border-gray-300 p-1"></div>
                        </div>
                        <div class="flex justify-between w-full items-center gap-5 p-1">
                            <div class="w-3/12"><label for="Besoin">Catégorie</label></div>
                            <div class="w-9/12">
                                <select v-model="nomcat" class="border border-gray-300 p-1 rounded w-full">
        
                                                                <option v-for="categorie in categories" :key="categorie.nomcat"
        
                                                                    :value="categorie.nomcat" required>{{ categorie.nomcat }}</option>
        
                                                            </select>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="flex max-lg:justify-between lg:justify-end w-full items-center gap-5 p-1">
                    <div class="max-lg:w-1/2"><button type="button" @click="modal_modif = false" class="border border-gray-600 text-gray-600 py-1 px-3 rounded w-full">Annuler</button>
                    </div>
                    <div class="max-lg:w-1/2"><button class="bg-blue-400 hover:bg-blue-500 px-3 py-1 rounded text-white w-full" type="submit">Modifier</button></div>
                </div>
            </form>
        </div>
    </Modal>
    <!--modal details-->
    <Modal :isOpen="modal_details" @close="modal_details=false">
        <div class="flex justify-center">
            <div>
                <h5 class="font-semibold">Détails fournisseur</h5>
            </div>
        </div>
        <div class="">
            <div>
                <form>
                    <div class="md:grid md:grid-cols-2 gap-2 justify-start ">
                        <div class="justify-between gap-5 p-1 hidden">
                            <div class="w-3/12"><label for="idfourni">idfourni</label></div>
                            <div><input v-model="idfourni1" type="text" name="idfourni" class="rounded border w-full border-gray-300 p-1" disabled></div>
                        </div>
                        <div class="flex justify-start items-center gap-5 py-1 ">
                            <div class="w-3/12"><label for="nom">Nom</label></div>
                            <div><input disabled v-model="nom" type="text" name="nom" class="rounded border w-full border-gray-300 p-1"></div>
                        </div>
                        <div class="flex justify-start items-center  gap-5 py-1">
                            <div class="w-3/12"><label for="Contact">Contact</label></div>
                            <div><input disabled v-model="contact" type="text" name="Contact" class="rounded border w-full border-gray-300 p-1"></div>
                        </div>
                        <div class="flex justify-start items-center gap-5 py-1">
                            <div class="w-3/12"><label for="email">email</label></div>
                            <div><input disabled v-model="email" type="text" name="email" class="rounded border w-full border-gray-300 p-1"></div>
                        </div>
                        <div class="flex justify-start  items-center gap-5 py-1">
                            <div class="w-3/12"><label for="adresse">adresse</label></div>
                            <div><input disabled v-model="adresse" type="text" name="adresse" class="rounded border w-full border-gray-300 p-1"></div>
                        </div>
                        <div class="flex justify-start items-center  gap-5 py-1">
                            <div class="w-3/12"><label for="prodDispo">prodDispo</label></div>
                            <div><input disabled v-model="prodDispo" type="text" name="prodDispo" class="rounded border w-full border-gray-300 p-1"></div>
                        </div>
                        <div class="flex justify-start items-center  gap-5 py-1">
                            <div class="w-3/12"><label for="quantite">quantite</label></div>
                            <div><input disabled v-model="quantite" type="text" name="quantite" class="rounded border w-full border-gray-300 p-1"></div>
                        </div>
                        <div class="flex justify-start items-center  gap-5 py-1">
                            <div class="w-3/12"><label for="unite">unite</label></div>
                            <div><input disabled v-model="unite" type="text" name="unite" class="rounded border w-full border-gray-300 p-1"></div>
                        </div>
                        <div class="flex justify-start items-center  gap-5 py-1">
                            <div class="w-3/12"><label for="prixUnitaire">prixUnitaire</label></div>
                            <div><input disabled v-model="prixUnitaire" type="text" name="prixUnitaire" class="rounded w-full border border-gray-300 p-1"></div>
                        </div>
                        <div class="flex justify-start items-center  gap-5 py-1">
                            <div class="w-3/12"> <label for="description">description</label></div>
                            <div><input disabled v-model="description" type="text" name="description" class="rounded w-full border border-gray-300 p-1"></div>
                        </div>
                        <div class="flex justify-start items-center  gap-5 py-1">
                            <div class="w-3/12"><label for="lieu">lieu</label></div>
                            <div><input disabled v-model="lieu" type="text" name="lieu" class="rounded border w-full border-gray-300 p-1"></div>
                        </div>
                        <div class="flex justify-start items-center  gap-5 py-1">
                            <div class="w-3/12"><label for="capaciteProd">capaciteProd</label></div>
                            <div><input disabled v-model="capaciteProd" type="text" name="capaciteProd" class="rounded w-full border border-gray-300 p-1"></div>
                        </div>
                        <div class="flex justify-start items-center  gap-5 py-1">
                            <div><label for="Besoin">Catégorie</label></div>
                            <div>
                                <input type="text" name="Besoin" class="rounded border  w-full border-gray-300 px-2 " disabled v-model="nomcat">
                            </div>
                        </div>
                        <div class="flex justify-start items-center  gap-5 py-1">
                            <div><label for="Besoin">Province</label></div>
                            <div>
                                <input type="text" name="Besoin" class="rounded border  w-full border-gray-300 px-2 " disabled v-model="province">
                            </div>
                        </div>
                        <div class="flex justify-start items-center  gap-5 py-1">
                            <div><label for="Besoin">Région</label></div>
                            <div>
                                <input type="text" name="Besoin" class="rounded border  w-full border-gray-300 px-2 " disabled v-model="region">
                            </div>
                        </div>
                        <div class="flex justify-start items-center  gap-5 p-1">
                            <div class="w-3/12"><label for="description">Vérification</label></div>
                            <div><input type="text" name="quantite" class="rounded border border-gray-300 px-2" disabled v-model="verification"></div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </Modal>
</template>
<style>
    .v-enter-active,
    .v-leave-active {
        transition: transform 0.6s;
    }
    .v-enter-from,
    .v-leave-to {
        transform: translateX(-500px)
    }
</style>
<script>
    import apiUrl from '../../ApiConfig';
    import axios from 'axios'
    import Menu from '@/components/Admin/Menu.vue'
    import Header from '@/components/Admin/Header.vue'
    import Filtre from '@/components/Admin/Filtre.vue'
    import Pagination from '@/components/Admin/Pagination.vue'
    import '@/assets/styleAdmin/page.css'
    import Modal from "@/components/modal/modal.vue"
    import Sidebar from '@/components/Admin/sidebar/sidebar.vue'
    export default {
        name: 'Fournisseur',
        components: {
            Menu,
            Filtre,
            Header,
            Pagination,
            Modal,
            Sidebar
        },
        data() {
            return {
                modal_menu: false,
                menu_item: [{
                        title: 'Dashboard',
                        link: '/Admin/Dashboard',
                        logo: 'fa fa-house '
                    },
                    {
                        title: 'Comparaison',
                        link: '/Admin/Comparaison',
                        logo: 'fas fa-chart-line'
                    },
                    {
                        title: 'Categorie',
                        link: '/Admin/Categorie',
                        logo: 'fa fa-list-check '
                    },
                    {
                        title: 'Utilisateur',
                        link: '/Admin/Utilisateur',
                        logo: 'fa fa-users '
                    },
                    {
                        title: 'Abonnement',
                        link: '/Admin/subscription',
                        logo: 'fa fa-users '
                    },
                    {
                        title: 'Client',
                        link: '/Admin/Client',
                        logo: 'fa fa-user'
                    },
                    {
                        title: 'Fournisseur',
                        link: '/Admin/Fournisseur',
                        logo: 'fa fa-house '
                    },
                    {
                        title: 'Investisseur',
                        link: '/Admin/Investisseur',
                        logo: 'fa fa-money-check '
                    },
                    {
                        title: 'Message',
                        link: '/Admin/Message',
                        logo: 'fas fa-message '
                    },
                ],
                //ovaina ny nom n variable name-page
                name_page: this.$options.name,
                modal_modif: false,
                modal_ajout: false,
                modal_suppr: false,
                modal_details: false,
                Prix1: '',
                Prix2: '',
                categories: [],
                inpCat: '',
                inpPrix1: '',
                inpPrix2: '',
                inpProduit: '',
                Produit: '',
                Recherche: '',
                //input modal ajout
                idfourni: '',
                nom: '',
                contact: '',
                email: '',
                adresse: '',
                prodDispo: '',
                quantite: '',
                unite: '',
                prixUnitaire: '',
                description: '',
                lieu: '',
                capaciteProd: '',
                nomcat: '',
                verification: '',
                province: '',
                region: '',
                //input modal modif et details
                idfourni1: '',
                fournisseurs: [],
                fournisseur: {
                    idfourni: '',
                    nom: '',
                    contact: '',
                    email: '',
                    adresse: '',
                    prodDispo: '',
                    quantite: '',
                    unite: '',
                    prixUnitaire: '',
                    description: '',
                    lieu: '',
                    capaciteProd: '',
                    nomcat: '',
                    verification: '',
                    province: '',
                    region: '',
                }
            }
        },
        mounted() {
            this.getFournisseurs();
            this.categorieSelection();
        },
        methods: {
            data_modal_ajout(data) {
                this.modal_ajout = data
            },
            receive_data_filter(props1, props2, props3, props4) {
                this.Prix1 = props1
                this.Prix2 = props2
                this.Categorie = props3
                this.Produit = props4
                this.rechercheClient()
            },
            getFournisseurs() {
                try {
                    axios.get(apiUrl + 'api/affichage_fournisseur_seera').then(response => {
                        this.fournisseurs = response.data
                        // eslint-disable-next-line no-undef
                    }).catch(error => {
                        console.log(error)
                        this.fournisseurs = []
                    })
                } catch (error) {
                    console.error(error)
                }
            },
            ajoutFournisseur() {
                // eslint-disable-next-line no-unused-vars
                try {
                    axios.post(apiUrl + 'api/ajout_fournisseur_seera', this.fournisseur).then(response => {
                            if (confirm('Fournisseur ajouté avec succès')) {
                                this.getFournisseurs();
                                this.modal_ajout = false;
                                this.fournisseur.nom = "";
                                this.fournisseur.contact = "";
                                this.fournisseur.email = "";
                                this.fournisseur.adresse = "";
                                this.fournisseur.prodDispo = "";
                                this.fournisseur.quantite = "";
                                this.fournisseur.unite = "";
                                this.fournisseur.prixUnitaire = "";
                                this.fournisseur.description = "";
                                this.fournisseur.lieu = "";
                                this.fournisseur.capaciteProd = "";
                                this.fournisseur.nomcat = "";
                                this.fournisseur.verification = "";
                                this.fournisseur.province = "",
                                    this.fournisseur.region = ""
                            }
                        })
                        .catch(error => {
                            (
                                console.log(error)
                            )
                        });
                } catch (error) {
                    console.error(error)
                }
            },
            modificationFournisseur() {
                try {
                    axios.post(apiUrl + 'api/modification_fournisseur_seera', {
                        idfourni: this.idfourni,
                        nom: this.nom,
                        contact: this.contact,
                        email: this.email,
                        adresse: this.adresse,
                        // eslint-disable-next-line no-unused-vars
                        prodDispo: this.prodDispo,
                        quantite: this.quantite,
                        unite: this.unite,
                        prixUnitaire: this.prixUnitaire,
                        description: this.description,
                        lieu: this.lieu,
                        capaciteProd: this.capaciteProd,
                        nomcat: this.nomcat,
                        verification: this.verification,
                        province: this.province,
                        region: this.region
                    }).then(response => {
                        if (confirm('Fournisseur modifié avec succès')) {
                            this.modal_modif = false;
                            this.getFournisseurs();
                        }
                    }).catch(error => {
                        (
                            console.log(error)
                        )
                    });
                } catch (error) {
                    console.error(error)
                }
            },
            suppressionFournisseur() {
                try {
                    axios.post(apiUrl + 'api/suppression_fournisseur_seera', {
                        idfourni: this.idfourni1
                        // eslint-disable-next-line no-unused-vars
                    }).then(response => {
                        if (confirm('Fournisseur supprimé avec succès')) {
                            this.modal_suppr = false;
                            this.getFournisseurs();
                        }
                    }).catch(error => {
                        (
                            console.log(error)
                        )
                    });
                } catch (error) {
                    console.error(error)
                }
            },
            categorieSelection() {
                try {
                    axios.get(apiUrl + 'api/affichage_nom_categorie').then(response => {
                        this.categories = response.data
                    }).catch(error => {
                        console.log(error)
                    })
                } catch (error) {
                    console.error(error)
                }
            },
            rechercheFournisseur() {
                try {
                    axios.get(apiUrl + 'api/recherche_fournisseur', {
                        params: {
                            inpProduit: this.inpProduit,
                            inpPrix1: this.inpPrix1,
                            inpPrix2: this.inpPrix2,
                            inpCat: this.inpCat
                        }
                    }).then(response => {
                        this.fournisseurs = response.data
                    }).catch(error => {
                        console.log(error)
                    })
                } catch (error) {
                    console.error(error)
                }
            },
            init() {
                this.inpProduit = '', this.inpPrix1 = '', this.inpPrix2 = '', this.inpCat = ''
            },
        }
    }
</script>
<template lang="">
    <div class="flex flex-col gap-y-6 w-screen max-w-screen justify-start items-center">
    
        <Header2 @data_menu_mobile2="receive_data_menu_mobile"></Header2>
    
        <div class="flex max-w-11/12 w-11/12 justify-start items-center gap-x-3 text-slate-500">
    
            <button @click="go(1)" :class="page===1?'font-bold text-slate-700':'font-semibold text-slate-400'">Information</button>
    
            <button @click="go(2)" :class="page===2?'font-bold text-slate-700':'font-semibold text-slate-400'"></button>
    
            <button @click="go(3)" :class="page===3?'font-bold text-slate-700':'font-semibold text-slate-400'"></button>
    
            <!-- <button @click="page=4" :class="page===4?'font-bold text-slate-700':'font-semibold text-slate-400'">Facture</button> -->
    
        </div>
    
        <div class="max-w-11/12 w-11/12">
    
            <div v-if="page===1" class="grid max-lg:grid-cols-1 w-full lg:grid-cols-2 gap-5">
    
                <Personnel/>
    
                <Abonnement/>
    
            </div>
    
            <div v-if="page===2">
    
               
    
            </div>
    
            <div v-if="page===3" class="w-full flex flex-col gap-y-3">
    
                
    
            </div>
    
            
    
        </div>
        
    </div>
    <Menu :modal_menu="modal_menu" @close="modal_menu=false"></Menu>
    <!-- <Footer></Footer> -->
   
</template>
<script>
import Footer from './../../../components/Client/Footer.vue'
import Header2 from './../../../components/Client/Header2.vue'
import Abonnement from './abonnement/Abonnement.vue'
import Personnel from "./personnel/Personnel.vue"

import Menu from '@/components/Client/menu/Menu.vue'
export default {
    name: "Profile",
    components: {
        Footer,
        Header2,
        Personnel,
        Abonnement,
        
        
        Menu

    },
    data() {
        return {
            modal_menu: false,
             
            page: 1,
        }
    },
    mounted() {
        if(localStorage.getItem('profile_page') == 1 || localStorage.getItem('profile_page') ==2 || localStorage.getItem('profile_page') ==3) {
            this.page=JSON.parse(localStorage.getItem('profile_page'))
        }
    },
    methods: {
        receive_data_menu_mobile(data) {
            this.modal_menu = data
        },
        go(page){
            this.page=page
            localStorage.setItem('profile_page',page)
        }
    }
}
</script>
<style lang="">

</style>
<template>
  <input v-model="$route.params.iduser" class="text-center hidden">
  <div class=" mb-10  text-center w-screen  md:mb-12"> 
      <h1 class="text-center font-semibold text-2xl">Choix d'abonnement</h1>
      <p class="` text-gray-500 text-sm text-center">
        Découvrez nos offres exclusives pour accéder à des informations précieuses 
        et essentielles à votre succès.
      </p>
    </div>
  <div id="Pricing" class="w-screen  justify-center py-4 flex flex-wrap gap-x-12 bg-slate-50">
    <div class="w-11/12 max-md:grid lg:grid md:flex gap-y-5 md:flex-wrap max-lg:justify-center lg:grid-cols-3 max-md:grid-cols-1   lg:justify-between gap-x-5">
      <div class="flex flex-col max-md:w-full max-lg:w-5/12 lg:w- justify-between items-start  bg-white shadow-sm shadow-gray-300 rounded p-5">
        <div class="flex flex-col  gap-y-3 ">
          <div class="flex gap-x-3 ">
            <div class="icon">
            </div>
            <div>
              <h1 class="font-bold text-5xl">
                Basique
              </h1>
            </div>
          </div>
          <div>
            <p>
              Profitez d'un accès de base à notre plateforme. Idéal pour les bésoins simples.
            </p>
          </div>
          <div>
            <h3>
              <span class="text-3xl font-bold">
                15 000 Ar
              </span>
              <span class="text-xl font-semibold">
                /mois
              </span>
            </h3>
            <div class="flex flex-col gap-y-2 py-3">
              <div class="flex justify-start gap-x-4 items-center">
                <svg class="w-5 h-5 text-green-600" viewBox="0 0 24 24" stroke-linecap="round" stroke-width="2">
                  <polyline fill="none" stroke="currentColor" points="6,12 10,16 18,8"></polyline>
                  <circle cx="12" cy="12" fill="none" r="11" stroke="currentColor"></circle>
                </svg>
                <span>
                  30 clients
                </span>
              </div>
              <div class="flex justify-start gap-x-4 items-center">
                <svg class="w-5 h-5 text-green-600" viewBox="0 0 24 24" stroke-linecap="round" stroke-width="2">
                  <polyline fill="none" stroke="currentColor" points="6,12 10,16 18,8"></polyline>
                  <circle cx="12" cy="12" fill="none" r="11" stroke="currentColor"></circle>
                </svg>
                <span>
                  30 fournisseurs
                </span>
              </div>
            </div>
          </div>
        </div>
        <button 
        @click="choixAbonnement('basic','10 000 Ar'),$router.push('/paiement/' + $route.params.iduser)"
         class="w-full border rounded h-9 hover:text-white hover:bg-slate-900  border-slate-900 font-semibold">
            Choisir
          </button>
      </div>
      <div class="flex flex-col max-md:w-full max-lg:w-5/12 justify-between items-start  gap-y-3  bg-slate-900 text-white shadow-sm shadow-gray-300 rounded p-5">
        <div class="flex flex-col  gap-y-3">
          <div class="flex gap-x-3">
            <div class="icon">
            </div>
            <div>
              <h1 class="font-bold text-5xl">
                Standard
              </h1>
            </div>
          </div>
          <div>
            <p class="text-gray-300">
              Améliorez votre engagement avec notre abonnement standard et Bénéficiez d'un accès elargit à notre plateforme.
            </p>
          </div>
          <div>
            <h3>
              <span class="text-3xl font-bold">
                50 000 Ar
              </span>
              <span class="text-xl font-semibold">
                /mois
              </span>
            </h3>
            <div class="flex flex-col gap-y-2 py-3">
              <div class="flex justify-start gap-x-4 items-center">
                <svg class="w-5 h-5 text-green-600" viewBox="0 0 24 24" stroke-linecap="round" stroke-width="2">
                  <polyline fill="none" stroke="currentColor" points="6,12 10,16 18,8"></polyline>
                  <circle cx="12" cy="12" fill="none" r="11" stroke="currentColor"></circle>
                </svg>
                <span class="text-gray-300">
                  100 clients
                </span>
              </div>
              <div class="flex justify-start gap-x-4 items-center">
                <svg class="w-5 h-5 text-green-600" viewBox="0 0 24 24" stroke-linecap="round" stroke-width="2">
                  <polyline fill="none" stroke="currentColor" points="6,12 10,16 18,8"></polyline>
                  <circle cx="12" cy="12" fill="none" r="11" stroke="currentColor"></circle>
                </svg>
                <span class="text-gray-300">
                  100 fournisseurs
                </span>
              </div>
              <div class="flex justify-start gap-x-4 items-center">
                <svg class="w-5 h-5 text-green-600" viewBox="0 0 24 24" stroke-linecap="round" stroke-width="2">
                  <polyline fill="none" stroke="currentColor" points="6,12 10,16 18,8"></polyline>
                  <circle cx="12" cy="12" fill="none" r="11" stroke="currentColor"></circle>
                </svg>
                <span class="text-gray-300">
                  30 investisseurs
                </span>
              </div>
            </div>
          </div>
        </div>
        <button 
        @click="choixAbonnement('standard','40 000 Ar'), $router.push('/paiement/' + $route.params.iduser)"
        class="w-full  rounded h-9 bg-green-600 hover:bg-green-700 font-semibold">
            Choisir
          </button>
      </div>
      <div class="flex max-md:w-full max-lg:w-5/12 flex-col items-start  gap-y-3  bg-white shadow-sm shadow-gray-300 rounded p-5">
        <div class="flex gap-x-3">
          <div class="icon">
          </div>
          <div>
            <h1 class="font-bold text-5xl">
              Premium
            </h1>
          </div>
        </div>
        <div>
          <p>
            Profitez d'un accès de base à notre plateforme. Idéal pour les bésoins simples.
          </p>
        </div>
        <div>
          <h3>
            <span class="text-3xl font-bold">
                100 000 Ar
              </span>
            <span class="text-xl font-semibold">
                /mois
              </span>
          </h3>
          <div class="flex flex-col gap-y-2 py-3">
            <div class="flex justify-start gap-x-4 items-center">
              <svg class="w-5 h-5 text-green-600" viewBox="0 0 24 24" stroke-linecap="round" stroke-width="2">
                  <polyline fill="none" stroke="currentColor" points="6,12 10,16 18,8"></polyline>
                  <circle cx="12" cy="12" fill="none" r="11" stroke="currentColor"></circle>
                </svg>
              <span>
                  +200 clients
                </span>
            </div>
            <div class="flex justify-start gap-x-4 items-center">
              <svg class="w-5 h-5 text-green-600" viewBox="0 0 24 24" stroke-linecap="round" stroke-width="2">
                  <polyline fill="none" stroke="currentColor" points="6,12 10,16 18,8"></polyline>
                  <circle cx="12" cy="12" fill="none" r="11" stroke="currentColor"></circle>
                </svg>
              <span>
                  +200 fournisseurs
                </span>
            </div>
            <div class="flex justify-start gap-x-4 items-center">
              <svg class="w-5 h-5 text-green-600" viewBox="0 0 24 24" stroke-linecap="round" stroke-width="2">
                  <polyline fill="none" stroke="currentColor" points="6,12 10,16 18,8"></polyline>
                  <circle cx="12" cy="12" fill="none" r="11" stroke="currentColor"></circle>
                </svg>
              <span>
                  +100 investisseurs
                </span>
            </div>
            <div class="flex justify-start gap-x-4 items-center">
              <svg class="w-5 h-5 text-green-600" viewBox="0 0 24 24" stroke-linecap="round" stroke-width="2">
                  <polyline fill="none" stroke="currentColor" points="6,12 10,16 18,8"></polyline>
                  <circle cx="12" cy="12" fill="none" r="11" stroke="currentColor"></circle>
                </svg>
              <span>
                   Bonus surprise
                </span>
            </div>
          </div>
        </div>
        <button 
        @click="choixAbonnement('premium','100 000 Ar'), $router.push('/paiement/' + $route.params.iduser)"
        class="w-full border rounded h-9 hover:text-white hover:bg-slate-900  border-slate-900 font-semibold">
            Choisir
          </button>
      </div>
    </div>
  </div>
   
   <br><br>
</template>
<script>
export default {
  data() {
    return {
      abonnement: null,
      prix: null,
    }
  },
	methods:{
    choixAbonnement(data,prix){
      this.abonnement = data;
      this.prix = prix;
      
      localStorage.setItem('myAbonnement',data)
      localStorage.setItem('myPrix',prix)
      //this.$router.push('/paiement')
    }
  }
}
</script>
<style lang="">
	
</style>
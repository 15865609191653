<template>
    <!--containt all-->
    <div class=" w-screen h-screen  bg-gray-300 inline-grid grid-cols-2 items-center justify-center">
        <div class="bg-white w-2/4 h-2/4 mx-20 rounded justify-center items-center">
            <div>
                <img src="./../../assets/serra2.png" class="w-2/4 h-2/4" alt="">
            </div>
        </div>
        <div class="bg-white rounded h-3/4 w-3/4 m-auto grid items-center justify-center">
            <div class="">
                <h1>Connection</h1>
                <div>
                    <form>
                        <div class="grid gap-y-2 w-full h-full">
                            <div><input type="text" class="rounded p-1 border border-gray-300 w-4/5 sm:w-full h-full" placeholder="Email"></div>
                            <div><input type="password" class="rounded p-1 border border-gray-300 w-4/5 sm:w-full h-full" placeholder="mot de passe"></div>
                            <div class="grid-flow-row items-center justify-center">
                                <div class="mx-auto"><button>Se connecter</button></div>
                                
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name:'Login',
    data(){
        return{
            
        }
    }
}
</script>
<!-- eslint-disable vue/require-v-for-key -->

<template>
  <div class="gray max-lg:w-screen  lg:w-10/12 float-right h-screen flexbox  justify-center">
    <div class="bg-white max-sm:p-2 sm:h-fit mx-4 flex items-center rounded">
      <div class="flex p-2 justify-center items-center lg:hidden">
        <div class="fas fa-bars fa-2x text-gray-600 cursor-pointer" @click="modal_menu = !modal_menu"></div>
      </div>
      <Header :name_page="name_page" class="p-2"></Header>
    </div><br>
    <div class=" h-fit mx-4 flex flex-col  justify-start rounded  lg:my-3 gap-9">
      <div class=" h-fit flex  w-full  justify-start items-start rounded  gap-5">
        <div class=" w-full flex justify-between items-center p-3 bg-white rounded h-fit">
          <div class=" ">
            <h6 class="font-semibold">Utilisateur :</h6>
          </div>
          <div class="text-center text-2xl  p-4 text-red-400">{{ nombreUtilisateurs }}</div>
        </div>
        <div class="w-full flex justify-between items-center p-3  bg-white rounded">
          <div class="">
            <h6 class="font-semibold">Client :</h6>
          </div>
          <div class="text-center text-2xl p-4 text-blue-400">{{ nombreClients }}</div>
        </div>
        <div class="w-full flex justify-between items-center p-3 bg-white rounded">
          <div class="">
            <h6 class="font-semibold">Fournisseur :</h6>
          </div>
          <div class="text-center text-2xl  p-4 text-yellow-400">{{ nombreFournisseurs }}</div>
        </div>
        <div class=" w-full flex justify-between items-center p-3 bg-white rounded">
          <div class="">
            <h6 class="font-semibold">Investisseur :</h6>
          </div>
          <div class="text-center text-2xl  p-4 text-green-400">{{ nombreInvestisseurs }}</div>
        </div>
      </div>
      <div class="p-3 max-md:min-w-full   bg-white rounded">
        <apexchart height="400px" type="bar" :options="chartOptions" :series="series" />
      </div>
    </div>
  </div>
  <Menu class="w-2/12 bg-white shadow-sm shadow-gray-300 "></Menu>
  <!-- modal menu pour mobile -->
  <Sidebar :modal_menu="modal_menu" @close="modal_menu=false"></Sidebar>
</template>
<style scoped>
  .v-enter-active,
  .v-leave-active {
    transition: transform 0.6s;
  }
  .v-enter-from,
  .v-leave-to {
    transform: translateX(-500px)
  }
</style>
<script>
  import apiUrl from '../../ApiConfig';
  import axios from 'axios'
  import Menu from '@/components/Admin/Menu.vue'
  import Header from '@/components/Admin/Header.vue'
  import Graph from '@/components/Admin/Graph.vue'
  import Filtre from '@/components/Admin/Filtre.vue'
  import Sidebar from '@/components/Admin/sidebar/sidebar.vue'
  import '@/assets/styleAdmin/page.css'
  export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: 'Tableau de board',
    components: {
      Menu,
      // eslint-disable-next-line vue/no-unused-components
      Filtre,
      Header,
      // eslint-disable-next-line vue/no-unused-components
      Graph,
      Sidebar,
    },
    data() {
      return {
        modal_menu: false,
        // chartConfig,
        //ovaina ny nom n variable name-page
        name_page: this.$options.name,
        modal_modif: false,
        modal_ajout: false,
        modal_suppr: false,
        modal_details: false,
        Prix1: '',
        Prix2: '',
        Categorie: '',
        Produit: '',
        Recherche: '',
        nombreUtilisateurs: 0,
        nombreClients: 0,
        nombreFournisseurs: 0,
        nombreInvestisseurs: 0,
        series: [{
          name: 'Total',
          data: [0, 0, 0, 0]
        }],
        chartOptions: {
          chart: {
            height: 350,
            type: 'bar'
          },
          plotOptions: {
            bar: {
              horizontal: false,
            },
          },
          xaxis: {
            categories: ["Utilisateur", "Client", "Fournisseur", "Investisseur"],
          },
        }
      }
    },
    computed: {
    },
    mounted() {
      this.getNombreFournisseur();
      this.getNombreInvestisseur();
      this.getNombreClient();
      this.getNombreUtilisateur();
    },
    methods: {
      getNombreUtilisateur() {
        try {
          axios.get(apiUrl + 'api/nombre_utilisateur_seera').then(response => {
            this.nombreUtilisateurs = response.data.nombreUtilisateurs;
            // eslint-disable-next-line no-undef
            this.series[0].data = [this.nombreUtilisateurs, this.nombreClients, this.nombreFournisseurs, this.nombreInvestisseurs]
          }).catch(error => {
            console.log(error)
          })
        } catch (error) {
          console.error(error)
        }
      },
      getNombreClient() {
        try {
          axios.get(apiUrl + 'api/nombre_client_seera').then(response => {
            this.nombreClients = response.data.nombreClients;
            // eslint-disable-next-line no-undef
          }).catch(error => {
            console.log(error)
          })
        } catch (error) {
          console.error(error)
        }
      },
      getNombreFournisseur() {
        try {
          axios.get(apiUrl + 'api/nombre_fournisseur_seera').then(response => {
            this.nombreFournisseurs = response.data.nombreFournisseurs;
            // eslint-disable-next-line no-undef
          }).catch(error => {
            console.log(error)
          })
        } catch (error) {
          console.error(error)
        }
      },
      getNombreInvestisseur() {
        axios.get(apiUrl + 'api/nombre_investisseur_seera').then(response => {
          this.nombreInvestisseurs = response.data.nombreInvestisseurs;
          // eslint-disable-next-line no-undef
        }).catch(error => {
          console.log(error)
        })
      },
      data_modal_ajout(data) {
        this.modal_ajout = data
      },
      receive_data_filter(props1, props2, props3, props4, props5) {
        this.Prix1 = props1
        this.Prix2 = props2
        this.Categorie = props3
        this.Produit = props4
        this.Recherche = props5
      }
    }
  }
</script>
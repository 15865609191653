<template lang="">
    <div v-if="iduser==''||iduser==undefined">
        <Header :modal_menu="modal_menu" @toggle="modal_menu=!modal_menu"></Header>
        <br/>
        <br/>
        
        
    </div>
    
    <Header2 @data_menu_mobile2="receive_data_menu_mobile" v-else>
    </Header2>
    <br/>
    <br/>
    <div class="w-screen flex justify-center items-start ">
        <div class="w-11/12 grid max-lg:grid-cols-1 lg:grid-cols-2  gap-2">
            <div class="flex flex-col gap-y-3">
                <div class="flex flex-row justify-between items-center gap-2 text-slate-700 font-semibold">
                    <h4 class="w-full bg-blue-50 p-2 font-bold">Termes et conditions</h4>
                    <!-- <span class="text-blue-400 fa fa-pen"></span> -->
                </div>
                <div class="flex flex-col gap-y-1">
                    <div class="flex flex-row justify-start items-center gap-x-2 text-slate-600 font-semibold">
                        <h4>Introduction</h4>
                        <span></span>
                    </div>
                    <div class="text-slate-500 ">
                        <p>
                            Bienvenue sur notre site web. En accédant à notre site, vous acceptez nos termes et conditions.
                        </p>
                        <p>
                            Ces termes et conditions peuvent être modifier à tout moment, veuillez les consulter régulièrement.
                        </p>
                    </div>
                </div>
                <div class="flex flex-col gap-y-1">
                    <div class="flex flex-row justify-start items-center gap-2 text-slate-600 font-semibold">
                        <h4>Utilisation du site</h4>
                        <span></span>
                    </div>
                    <div class="text-slate-500 ">
                        <p>
                            Vous devez utiliser ce site conformément aux lois en vigueur. Il est interdit de publier des contenus offansants et illegaux.
                        </p>
                    </div>
                </div>
            </div>
            <div class="flex flex-col gap-y-3">
                <div class="flex flex-row justify-between items-center gap-2 text-slate-700 font-semibold">
                    <h4 class="w-full bg-blue-50 p-2 font-bold">Politique de confidentialité</h4>
                    <!-- <span class="text-blue-400 fa fa-pen"></span> -->
                </div>
                <div class="flex flex-col gap-y-1">
                    <div class="flex flex-row justify-start items-center gap-x-2 text-slate-600 font-semibold">
                        <h4>Introduction</h4>
                        <span></span>
                    </div>
                    <div class="text-slate-500 ">
                        <p>
                            Nous nous engageons à protéger votre vie privée. Cette politique explique comment nous traitons vos informations personnelles. En utilisant notre site, vous consentez à notre politique de confidentialité.
                        </p>
                    </div>
                </div>
                <div class="flex flex-col gap-y-1">
                    <div class="flex flex-row justify-start items-center gap-x-2 text-slate-600 font-semibold">
                        <h4>Donnée collectées</h4>
                        <span></span>
                    </div>
                    <div class="text-slate-500 ">
                        <p>
                            Nous collectons des informations personnelles que vous fournissez volontairement telles que votre nom,adresse,e-mail,etc.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <Footers></Footers>
    <Menu_landing :modal_menu="modal_menu" @close="modal_menu=false" v-if="iduser==''||iduser==undefined"></Menu_landing>
    <Menu :modal_menu="modal_menu" @close="modal_menu=false" v-else></Menu>
</template>
<script>
    import Footers from '@/components/Client/Footer.vue'
    import Header2 from '@/components/Client/Header2.vue'
    import Menu from '@/components/Client/menu/Menu.vue'
    import Footer from '@/components/Client/Footer.vue'
    import Menu_landing from '@/components/Client/menu/Menu_lainding.vue'
    import Header from '@/components/Client/Header_all.vue';
    export default {
        name: "politique",
        components: {
            Footer,
            Header2,
            Footers,
            Menu,
            Menu_landing,
            Header
        },
        data() {
            return {
                modal_menu: false,
                iduser: undefined,
            }
        },
        mounted() {
            this.iduser = localStorage.getItem('iduser')
        },
        methods: {
            receive_data_menu_mobile(data) {
                this.modal_menu = data
            },
        }
    }
</script>
<style lang="">

</style>
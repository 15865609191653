import { createRouter, createWebHistory } from 'vue-router'
import Accueil from '../views/Client/Accueil.vue'
import Connection from '../views/Client/Connection.vue'
import Inscription from '../views/Client/Inscription.vue'
import Accueil2 from '../views/Client/Accueil2.vue'
import Fournisseur from './../views/Client/Fournisseur.vue'
import Comparaison from './../views/Client/Comparaison.vue'
import Client from './../views/Client/Client.vue'
import Investisseur from './../views/Client/Investisseur.vue'
import Abonnement from './../views/Client/Abonnement.vue'
import AbonnementAdmin from './../views/Admin/Abonnement.vue'
import Dashboard from './../views/Admin/Dashboard.vue'
import ComparaisonAdmin from './../views/Admin/Comparaison.vue'
import Utilisateur from './../views/Admin/Utilisateur.vue'
import ClientAdmin from './../views/Admin/Client.vue'
import FournisseurAdmin from './../views/Admin/Fournisseur.vue'
import MessageAdmin from './../views/Admin/Message.vue'
import InvestisseurAdmin from './../views/Admin/Investisseur.vue'
import Login from './../views/Admin/Login.vue'
import Categorie from './../views/Admin/Categorie.vue'
import Paiement from './../views/Client/Paiement.vue'
import ChoixAbonnement from '@/views/Client/abonnement/choixAbonnement.vue'
import Profile from '@/views/Client/Profile/Profile.vue'
import Partenaire from "@/views/Admin/partenaire/Partenaire.vue"
import FAQ from '@/views/Client/FAQ/FAQ.vue'
import Politique from '@/views/Client/Politique&Condition/Politique&Condition.vue'
const routes = [
  {
    path: '/',
    name: 'accueil',
    component:Accueil
  },{
    path: '/profile',
    name: 'profile',
    component:Profile
  },
  {
    path: '/comparison',
    name: 'Comparaison',
    component:Comparaison
  },
  {
    path: '/paiement/:iduser',
    name: 'Paiement',
    component:Paiement
  },
  {
    path: '/home',
    name: 'Accueil',
    component:Accueil2
  },
  {
    path: '/subscription/:iduser',
    name: 'Abonnement',
    component:Abonnement
  },
  {
    path: '/list_subscription',
    name: 'list_subscription',
    component:ChoixAbonnement
  }, 
  {
    path: '/Admin/subscription',
    name: 'AbonnementAdmin',
    component:AbonnementAdmin
  },
  {
    path: '/customer',
    name: 'Client',
    component:Client
  },
  ,
  {
    path: '/FAQ',
    name: 'FAQ',
    component:FAQ
  },
  ,
  {
    path: '/policy',
    name: 'politique',
    component:Politique
  },
  {
    path: '/investor',
    name: 'Investisseur',
    component:Investisseur
  },
  {
    path: '/signin',
    name: 'Connection',
    component:Connection
  },
  {
    path: '/signup',
    name: 'Inscription',
    component:Inscription
  },{
    path: '/supplier',
    name: 'Fournisseur',
    component:Fournisseur
  },
  {
    path: '/Admin/Dashboard',
    name: 'DashboardAdmin',
    component:Dashboard
  },
  {
    path: '/Admin/Categorie',
    name: 'Categorie',
    component:Categorie
  },
  {
    path: '/Admin/Login',
    name: 'Login',
    component:Login
  },
  {
    path: '/Admin/Comparaison',
    name: 'ComparaisonAdmin',
    component:ComparaisonAdmin
  }
  ,
  {
    path: '/Admin/Message',
    name: 'MessageAdmin',
    component:MessageAdmin
  },
  {
    path: '/Admin/Utilisateur',
    name: 'Utilisateur',
    component:Utilisateur
  },
  {
    path: '/Admin/Client',
    name: 'ClientClient',
    component:ClientAdmin
  },
  {
    path: '/Admin/Fournisseur',
    name: 'FournisseurAdmin',
    component:FournisseurAdmin
  },
  
  {
    path: '/Admin/Partenaire',
    name: 'admin_partenaire',
    component:Partenaire
  },
  {
    path: '/Admin/Investisseur',
    name: 'InvestisseurAdmin',
    component:InvestisseurAdmin
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router

<template lang="">
  <div ref="myDiv" id="Contact" class="flex flex-col justify-center items-center w-screen max-w-screen">
    <div class="flex flex-col items-center justify-center">
      <div class="font-bold text-2xl text-center">
        <h1>Contact</h1>
      </div><br>
      <div class="w-11/12">
        <p class="text-slate-500  text-center ">
          N'hésitez pas à nous contacter pour toute question, demande d'information ou assistance. Nous sommes là pour vous aide.
        </p>
      </div>
    </div>
    <div class=" my-5   w-[95vw]  h-fit md:flex lg:flex sm:grid sm:grid-cols-1 gap-x-5 gap-y-5  p-4 justify-between text-start">
      <div class="md:w-4/12 lg:w-4/12 sm:w-full flex flex-col gap-y-5 gap-x-2">
        <div class="flex gap-x-4">
          <div class="flex justify-start items-center sm:mr-0 lg:mr-0 md:mr-3 w-fit">
            <div class=" flex justify-start items-center">
              <span class="fas fa-location-dot p-3 bg-red-400 rounded text-white"></span>
            </div>
          </div>
          <div class="text-left">
            <div>
              <h1 class="font-bold">Adresse:</h1>
            </div>
            <div>
              <p class="text-gray-600   md:">Lot VK 17 A Fenomanana Mahazoarivo Antananarivo</p>
            </div>
          </div>
        </div>
        <div class="flex gap-x-4">
          <div class="flex justify-start items-center">
            <div class="fas fa-envelope p-3 bg-red-400 rounded text-white"></div>
          </div>
          <div class="text-left">
            <div>
              <h1 class="font-bold">Email:</h1>
            </div>
            <div>
              <p class="text-gray-600  md:">info@haisoa.com</p>
            </div>
          </div>
        </div>
        <div class="flex gap-x-4">
          <div class="flex justify-start items-center">
            <div class="fas fa-tablet p-3 bg-red-400 rounded text-white"></div>
          </div>
          <div class="text-left">
            <div>
              <h1 class="font-bold">Téléphone:</h1>
            </div>
            <div>
              <p class="text-gray-600  md:">+261389875472 </p>
            </div>
          </div>
        </div>
      </div>
      <br class="md:hidden">
      <div class="h-fit lg:w-8/12 md:w-8/12 sm:w-full">
        <form @submit.prevent="envoyerMessage">
          <div class="flex flex-col gap-5">
            <div class="flex gap-3">
              <div class="w-6/12"><input type="text" class="border  border-gray-300 w-full p-1 rounded" v-model="nom" placeholder="Votre nom" required></div>
              <div class="w-6/12"><input type="text" class="border  border-gray-300 w-full p-1 rounded" v-model="numero" placeholder="Votre numéro" required></div>
            </div>
            <div>
              <input type="email" class="border border-gray-300  w-full p-1 rounded" v-model="email" placeholder="Votre email" required>
            </div>
            <div>
              <input type="text" class="border border-gray-300  w-full p-1 rounded" v-model="objet" placeholder="Objet" required>
            </div>
            <div class="h-fit">
              <textarea class="border border-gray-300 w-full rounded  p-1" rows="5" v-model="message" placeholder="Votre message" required></textarea>
            </div>
          </div>
          <div class="flex justify-end items-center">
            <div class="max-md:w-full">
              <button type="submit" class="py-1 max-md:w-full px-4 my-4 h-8 text-white bg-red-400 hover:bg-red-500 rounded flex justify-center items-center">
                          <span class="fa fa-spinner animate-spin" v-if="load.message"></span> 
                          <span class="" v-else>Envoyer le message</span>  
                        </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
  <!-- feedback success -->
  <Feedback :isOpen="feedback.success.isOpen" @close="feedback.success.isOpen=false">
    <div class="flex justify-start items-center gap-x-4">
      <span class="fa fa-check text-xl font-bold text-white bg-green-600 rounded-full p-3"></span>
      <span class="text-slate-600">{{feedback.success.text}}</span>
    </div>
  </Feedback>
  <!-- feedback failure -->
  <Feedback :isOpen="feedback.fails.isOpen" @close="feedback.fails.isOpen=false">
    <div class="flex justify-start items-center gap-x-4">
      <span class="fa fa-times text-xl font-bold text-white bg-red-600 rounded-full px-3 py-2"></span>
      <span class="text-slate-600">{{feedback.fails.text}}</span>
    </div>
  </Feedback>
</template>
<script>
  import axios from 'axios';
  import apiUrl from '../../ApiConfig';
  import Feedback from '../modal/Feedback.vue';
  export default {
    components: {
      Feedback
    },
    data() {
      return {
        nom: '',
        numero: '',
        email: '',
        objet: '',
        message: '',
        modalValidation: false,
        load: {
          message: false
        },
        feedback: {
          success: {
            isOpen: false,
            text: ""
          },
          fails: {
            isOpen: false,
            text: ""
          }
        }
      }
    },
    methods: {
      envoyerMessage() {
        this.load.message = true
        try {
          const formData = new FormData();
          formData.append('nom', this.nom);
          formData.append('email', this.email);
          formData.append('numero', this.numero);
          formData.append('objet', this.objet);
          formData.append('message', this.message);
          // eslint-disable-next-line no-unused-vars
          axios.post(apiUrl + 'api/envoyer_message', formData)
            .then(response => {
              this.nom = '', this.email = '', this.numero = '', this.message = '';
              this.objet = '';
              console.log('Message envoyer avec succès');
              this.load.message = false
              this.feedback.success.isOpen = true
              this.feedback.success.text = "Votre message a été bien envoyé !"
              
              setTimeout(() => {
                this.feedback.success.isOpen = false
              }, 2500)
            })
            .catch(error => {
              console.log(error)
              this.feedback.fails.isOpen = true
              this.feedback.fails.text = "L'envoy du message a échoué !"
              setTimeout(() => {
                this.feedback.fails.isOpen = false
              }, 2500)
            })
        } catch (error) {
          console.error(error)
          this.feedback.fails.isOpen = true
          this.feedback.fails.text = "L'envoy du message a échoué !"
          setTimeout(() => {
            this.feedback.fails.isOpen = false
          }, 2500)
        }
      },
    },
  }
</script>
<style lang="">

</style>
<template>
    <div class="w-screen  mt-10 flex justify-center py-10 relative -z-10  bg-blue-950 text-white ">
        <div class="w-11/12 flex flex-col items-center justify-center">
            <div class="w-full flex flex-wrap justify-around items-start gap-x-3 gap-y-6">
                <div class=" flex flex-col justify-start items-start gap-y-3 ">
                    <h4 class="font-bold text-white">Contact</h4>
                    <div class="w-full flex justify-start items-start gap-x-3 text-slate-400 hover:text-slate-200">
                        <div class="fa fa-location-dot pt-1"></div>
                        <div>Lot VK 17 A Fenomanana
                            <br /> Mahazoarivo Antananarivo
                        </div>
                    </div>
                    <div class=" flex justify-start items-start gap-x-3 text-slate-400  hover:text-slate-200">
                        <div class="fa fa-phone"></div>
                        <div> +261 38 98 754 72</div>
                    </div>
                    <div class=" flex justify-start items-start gap-x-3 text-slate-400  hover:text-slate-200">
                        <div class="fa fa-envelope"></div>
                        <div>info@haisoa.com</div>
                    </div>
                    <div class=" flex justify-start items-start gap-x-3 text-slate-400 hover:text-slate-200">
                        <div><span>Nous suivre : </span></div>
                        <a href="" class="fab fa-twitter-square text-xl"></a>
                        <a href="" class="fab fa-facebook text-xl"></a>
                        <a href="" class="fab fa-linkedin text-xl"></a>
                    </div>
                </div>
                <div class=" flex flex-col justify-start items-start gap-y-3">
                    <h4 class="font-bold text-white">Informations</h4>
                    <button @click="go('/profile','/signin',1)" class="text-slate-400 hover:text-slate-200">Profil</button>
                    <button @click="go('/profile','/signin',1)" class="text-slate-400 hover:text-slate-200">Abonnement</button>
                    <button @click="$router.push('/FAQ')" class="text-slate-400 hover:text-slate-200">Centre d'aide</button>
                    <button @click="$router.push('/policy')" class="text-slate-400 hover:text-slate-200">Politique et condition</button>
                </div>
                <div class=" flex flex-col justify-start items-start gap-y-3">
                    <h4 class="font-bold text-white">Pages</h4>
                    <button @click="go('/home','/')" class="text-slate-400 hover:text-slate-200">Accueil</button>
                    <button @click="go('/customer','/signin')" class="text-slate-400 hover:text-slate-200">Client</button>
                    <button @click="go('/supplier','/signin')" class="text-slate-400 hover:text-slate-200">Fournisseur</button>
                    <button @click="go('/investor','/signin')" class="text-slate-400 hover:text-slate-200">Investisseur</button>
                    <button @click="go('/comparison','/signin')" class="text-slate-400 hover:text-slate-200">Comparaison</button>
                </div>
            </div>
            
            <div class="w-11/12 flex justify-center text-slate-400 my-5">
                <p class="text-center">
                    <router-link to="/Admin/Dashboard">
                        <span>ⓒ</span>
                    </router-link> Copyright
                    <a href="http://www.haisoa.com" target="_blank" class="text-red-400 font-semibold">HAISOA</a> 2023. Tous droits réservés.
                </p>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        // eslint-disable-next-line vue/multi-word-component-names
        name: 'Footer',
        data() {
            return {
                sections: [{
                        id: 'Accueil',
                        title: 'Accueil',
                        link: '/Client/Accueil2'
                    },
                    {
                        id: 'Service',
                        title: 'Service',
                        link: '/Client/Accueil2/#Service'
                    },
                    {
                        id: 'About',
                        title: 'Pricing',
                        link: '/Client/Accueil2/#Pricing'
                    },
                    {
                        id: 'Pricing',
                        title: 'About',
                        link: '/Client/Accueil2/#About'
                    },
                    {
                        id: 'Contact',
                        title: 'Contact',
                        link: '/Client/Accueil2/#Contact'
                    },
                    
                ],
                iduser:undefined
            }
        },
       
        methods:{
            go(route,otherwiseRoute,page=undefined){
                if(localStorage.getItem('iduser')){
                    if(page!==undefined){
                        localStorage.setItem('profile_page',page)
                        
                    }
                    this.$router.push(route);
                } else {
                    this.$router.push(otherwiseRoute)
                }
            }
        }
    }
</script>
<style>

</style>
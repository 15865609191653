<template>
    <div class="w-screen h-screen flex justify-evenly items-center">
        <div class="min-lg:w-8/12 max-lg:w-screen h-screen bg-blue-950 max-lg:hidden">
            <img src="./../../assets/pexels-mark-stebnicki-2749165.jpg" alt="" class="h-full w-full opacity-50">
        </div>
        <div class="min-lg:grid max-lg:flex max-lg:flex-col  max-lg:w-screen  min-lg:w-4/12 h-screen justify-center items-center text-center bg-blue-950 text-white">
            <div class="grid gap-4 mt-10">
                <div class="overflow-hidden">
                    <h1 class="text-3xl font-bold p-4">Inscription</h1><br>
                    <p class="mx-9 text-slate-300">
                        Inscrivez-vous pour des infos exclusives et opportunités uniques.
                        <br />Rejoignez-nous maintenant !
                    </p>
                </div>
                <form @submit.prevent="inscription">
                    <div class="flex flex-col justify-center items-center gap-y-1">
                        <div class="bg-transparent border-b border-white w-fit flex gap-5 justify-center items-center">
                            <div class="fa fa-user text-white"></div>
                            <div>
                                <input type="text" placeholder="Nom" v-model="name" class="py-2 px-9 w-full focus:outline-none focus:bg-transparent focus:border-0 focus:border-white bg-transparent border-0 border-white" required>
                            </div>
                        </div>
                        <div class="bg-transparent border-b border-white w-fit flex gap-5 justify-center items-center">
                            <div class="fa fa-envelope text-white"></div>
                            <div>
                                <input type="email" placeholder="E-mail" v-model="email" class="py-2 px-9 w-full focus:outline-none focus:bg-transparent focus:border-0 focus:border-white bg-transparent border-0 border-white" required>
                            </div>
                        </div>
                        <div class="bg-transparent border-b border-white  w-fit flex gap-5 justify-center items-center">
                            <div class="fa fa-phone text-white"></div>
                            <div>
                                <input type="text" placeholder="Contact" v-model="contact" class="py-2 px-9 w-full focus:outline-none focus:bg-transparent focus:border-b focus:border-white bg-transparent border-0 border-white" required>
                            </div>
                        </div>
                        <div class="bg-transparent border-b border-white  w-fit flex gap-5 justify-center items-center">
                            <div class="fa fa-location-dot text-white"></div>
                            <div>
                                <input type="text" placeholder="Adresse" v-model="adresse" class="py-2 px-9 w-full focus:outline-none focus:bg-transparent focus:border-b focus:border-white bg-transparent border-0 border-white" required>
                            </div>
                        </div>
                        <div class="bg-transparent border-b   w-fit flex gap-5 justify-center items-center" :class="no_matching">
                            <div class="fa fa-lock text-white"></div>
                            <div>
                                <input v-model="password" type="password" placeholder="Mot de passe" class="py-2 px-9 w-full focus:outline-none focus:bg-transparent focus:border-b focus:border-white bg-transparent border-0 border-white" required>
                                <input type="text" v-model="password" v-show="false" placeholder="Mot de passe" class="py-2 px-9 w-full focus:outline-none focus:bg-transparent focus:border-b focus:border-white bg-transparent border-0 border-white" required>
                            </div>
                        </div>
                        <div class="bg-transparent border-b   w-fit flex gap-5 justify-center items-center" :class="no_matching">
                            <div class="fa fa-lock text-white"></div>
                            <div>
                                <input v-model="confirm_password" v-show="false" type="text" placeholder=" Confirmer  le Mot de passe" class="focus:outline-none py-2 px-9 w-full focus:bg-transparent focus:border-b focus:border-white bg-transparent border-0 border-white" required>
                                <input v-model="confirm_password" type="password" placeholder="Confirmer le mot de passe" class="focus:outline-none py-2 px-9 w-full focus:bg-transparent focus:border-b focus:border-white bg-transparent border-0 border-white" required>
                            </div>
                        </div>
                        <div>
                            <p class="text-red-500">{{ message }}</p>
                        </div>
                        <div class="flex gap-4 max-md:justify-between max-md:w-10/12 md:justify-center w-full items-center  my-4 ">
                            <div class=" max-md:w-full max-lg:w-7/12 lg:w-8/12">
                                <button type="submit" class="py-1 w-full text-white px-5 rounded bg-red-400 hover:bg-red-500 active:bg-red-500 flex justify-center items-center">
                                            <span class="fa fa-spinner animate-spin" v-if="load.signup"></span>
                                            <span class="" v-else>S'inscrire</span>
                                        </button>
                            </div>
                            
                        </div>
                        <div class="flex gap-4 max-md:justify-between max-md:w-10/12 md:justify-center w-full items-center  my-4 ">
                            <div class=" max-md:w-full max-lg:w-7/12 lg:w-8/12">
                                <p >
                                    <span class="text-slate-400">Vous-avez déja un compte ?</span> <RouterLink to="/signin" class="text-orange-500">Se connecter</RouterLink>
                                </p>
                            </div>
                            
                        </div>
                       
                    </div>
                </form>
            </div>
        </div>
    </div>
    <div class="img_user">
        <img src="./../../assets/serra2.png" class="max-sm:w-20 sm:w-[10vw]">
    </div>
    <Feedback :isOpen="modalError" @close="modalError=false">
        <div class="flex justify-start items-center gap-x-5 pb-4">
            <span class="fa fa-times bg-red-400 text-white px-2 py-1 rounded-full text-xl"></span>
            <p class="text-slate-500">Inscription échoué</p>
        </div>
    </Feedback>
    <Feedback :isOpen="modalValidation" @close="modalValidation=false">
        <div class="flex justify-start items-center gap-x-5 pb-4">
            <span class="fa fa-check bg-green-400 text-white p-2 rounded-full text-xl"></span>
            <p class="text-slate-500">Inscription reussi</p>
        </div>
        <div class="">
            <button @click="$router.push('/signin')" class="h-7 font-bold text-white bg-slate-700 rounded-sm w-full px-3">Se connecter</button>
        </div>
    </Feedback>
</template>
<script>
    import axios from 'axios';
    import apiUrl from '../../ApiConfig';
    import Feedback from "@/components/modal/Feedback.vue";
    export default {
        // eslint-disable-next-line vue/multi-word-component-names
        name: 'Connection',
        components: {
            Feedback
        },
        data() {
            return {
                name: '',
                email: '',
                contact: '',
                adresse: '',
                password: '',
                confirm_password: '',
                no_matching: 'undefined',
                message: '',
                modalValidation: false,
                modalError: false,
                load: {
                    signup: false
                },
            }
        },
        methods: {
            inscription() {
                if (this.password != this.confirm_password) {
                    this.message = "Veuillez confirmer votre mot de passe";
                    this.confirm_password = ""
                } else {
                    this.load.signin = true
                    try {
                        const formData = new FormData();
                        formData.append('name', this.name);
                        formData.append('email', this.email);
                        formData.append('contact', this.contact);
                        formData.append('adresse', this.adresse);
                        formData.append('password', this.password);
                        axios.post(apiUrl + 'api/inscription', formData)
                            .then(({
                                data
                            }) => {
                                console.log(data);
                                this.load.signin = false
                                this.modalValidation = true;
                            })
                            .catch(error => {
                                this.modalError = true
                            })
                    } catch (error) {
                        console.error(error)
                    }
                }
            },
            verification() {
                if (this.confirm_pwd != '') {
                    if (this.confirm_pwd != this.pwd || this.confirm_pwd == '') {
                        this.no_matching = 'border-red-400'
                    } else {
                        this.no_matching = 'border-green-400'
                    }
                }
            },
            goBack() {
                this.$router.go(-1)
            }
        }
    }
</script>
<template lang="">
    <Menu :modal_menu="modal_menu" @close="modal_menu=false">
    </Menu>
    <Header2Vue @data_menu_mobile2="receive_data_menu_mobile"></Header2Vue><br>
    <div class="flex flex-col justify-start text-sm items-center gap-5 w-screen">
        <div class="flex  max-lg:flex-col max-w-11/12 w-11/12  max-lg:items-end max-lg:justify-center lg:justify-center lg:items-center max-md:gap-y-3 md:gap-5">
            <div class="flex max-md:flex-col max-md:w-full max-md:gap-y-3 gap-x-4">
                <div class="flex w-full max-w-full">
                    <div class="rounded-l py-1 border border-slate-400  w-full">
                        <input type="number" class="md:w-full  h-full  focus:outline-none    placeholder:text-gray-300" placeholder="Prix entre..." v-model="inpPrix1">
                    </div>
                    <div class="rounded-r py-1 border border-slate-400 w-full ">
                        <input type="number" class="md:w-full  h-full focus:outline-none   placeholder:text-gray-300" placeholder="Et..." v-model="inpPrix2">
                    </div>
                </div>
                <div class="rounded border p-1 border-slate-400 w-full">
                    <input type="text" class="h-full w-full  focus:outline-none   placeholder:text-gray-300" placeholder="Localisation..." v-model="inpAdresse">
                </div>
                <div class="rounded border p-1  border-slate-400 w-full">
                    <select name="" id="" v-model="inpCat" class="h-full w-full     focus:bg-green-50 focus:bg-opacity-50 focus:outline-none placeholder:text-gray-300 " placeholder="localisation">
                                    <option value="">Tous</option>
                                    <option v-for="categorie in categories" :key="categorie.nomcat" :value="categorie.nomcat">{{ categorie.nomcat }}</option>
                                </select>
                </div>
                <div class="rounded border p-1 border-slate-400 w-full flex justify-between items-center">
                    <input type="text" class=" h-full w-full  focus:outline-none    placeholder:text-gray-300" placeholder="Produit..." v-model="inpProduit" />
                    <span class="fa fa-search text-xl"></span>
                </div>
            </div>
        </div>
        <div class="w-screen flex flex-col justify-start items-center bg-slate-50 py-4">
            <div class="w-11/12 flex justify-start items-center md:hidden">
                <div class="flex justify-start items-center gap-x-3 bg-slate-700 p-1 w-full text-white  rounded-sm">
                    <span @click="page=1;compared_data.fournisseur=undefined" class=" w-full text-center  font-bold p-4 rounded-sm" :class="page==1?'bg-white text-slate-700':''">Client</span>
                    <span @click="page=2;compared_data.client=undefined" class=" p-4 rounded-sm w-full text-center font-bold" :class="page==2?'bg-white text-slate-700':''">Fournisseur</span>
                </div>
            </div>
            <div class="w-11/12 grid md:grid-cols-2 gap-3 ">
                <div class="w-full flex  flex-col gap-4" :class="page===1?'':'max-lg:hidden'">
                    <div class="flex max-md:hidden justify-between items-center w-full">
                        <h4 class="">Client</h4>
                        <span @click="compared_data.client=undefined;index_client_clicked=undefined" v-show="compared_data.client!=undefined" class="fa fa-times  text-lg max-md:hidden"></span>
                    </div>
                    <div v-if="filtered_data(clients).length > 0" class="w-full flex flex-col gap-3">
                        <Card  v-for="(client,index) in filtered_data(clients)"  :key="index" @click="getClickedClient(client);index_client_clicked=index;index_fournisseur_clicked=undefined;isOpen.comparaison.client=true" :class="index_client_clicked===index?'shadow shadow-red-600':''">
                            <div class="flex w-full gap-x-3 ">
                                
                                <div class="max-md:w-[60px] md:w-[80px] lg:w-[100px] h-full flex justify-center items-center">
                                    <img src="./../../assets/user.png" class="w-10/12" alt="">

                                </div>
                                
                                <div class="w-full flex flex-col gap-y-2">
                                    <div class="flex justify-between items-center w-full">
                                        <div class="w-full text-lg font-bold overflow-y-hidden">{{ client.nom }}</div>
                                        <span  class="fa fa-eye text-slate-500" @click="isOpen.details_client = true, nom = client.nom, contact = client.contact, email = client.email,adresse = client.adresse, besoin = client.besoin, prodDemande = client.prodDemande, prixUnitaire = client.prixUnitaire, quantite = client.quantite, nomcat = client.nomcat,unite = client.unite, lieu = client.lieu, description = client.description"></span>
                                    </div>
                                    
                                    <div class="flex flex-col  h-fit">
                                        <div class="w-full flex justify-between items-center">
                                            <div class="w-full overflow-y-hidden flex justify-start items-center gap-x-3">
                                                <span class='font-semibold'>Produit :</span>
                                                <span class='text-slate-500'>{{ client.prodDemande }}</span>
                                            </div>
                                            <span class="mr-2 text-red-500 font-bold flex  justify-end items-center gap-x-3">
                                                            <span :class="verify_compared_fournisseur(client.prixUnitaire)"></span>
                                            <span>{{ client.prixUnitaire }}Ar</span>
                                            </span>
                                        </div>
                                        <div class="w-full flex justify-between items-center">
                                            <div class="w-full overflow-y-hidden flex justify-start items-center gap-x-3">
                                                <span class='font-semibold'>Categorie:</span>
                                                <span class='text-slate-500'>{{ client.nomcat }}</span>
                                            </div>
                                            <div class="w-full overflow-y-hidden flex justify-end items-center gap-x-3">
                                                <span class='font-semibold'>Quantité :</span>
                                                <span class='text-slate-500'>{{ client.quantite + ' ' + client.unite
                                                                                                                                    }}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Card>
                        
                    </div>

                        <div v-else>
                            Pas des données qui correspond à votre recherche. Veuillez réssayer une autre.
                        </div>
                </div>
                <div class="w-full flex  flex-col gap-4" :class="page===2?'':'max-md:hidden'">
                    <div class="flex max-md:hidden justify-between items-center w-full">
                        <h4 class="">Fournisseur</h4>
                        <span @click="compared_data.fournisseur=undefined;index_fournisseur_clicked=undefined" v-show="compared_data.fournisseur!=undefined" class="fa fa-times  text-lg max-md:hidden"></span>
                    </div>
                    <div class="w-full flex flex-col gap-3" v-if="filtered_data(fournisseurs).length>0">
                        <Card v-for="(item,index) in filtered_data(fournisseurs)" :key="index" @click="getClickedFournisseur(item);index_fournisseur_clicked=index;index_fournisseur_clicked=index;index_client_clicked=undefined;isOpen.comparaison.fournisseur=true" :class="index_fournisseur_clicked===index?'shadow shadow-red-600':''">
                            <div class="flex w-full gap-x-3">
                                <div class="max-md:w-[60px] md:w-[80px] lg:w-[100px] h-full flex justify-center items-center">
                                    <img src="./../../assets/user.png" class="w-10/12" alt="">
                                </div>
                                <div class="w-full flex flex-col gap-y-2">
                                    <div class="flex justify-between items-center w-full">
                                        <span class="w-full text-lg font-bold overflow-y-hidden">{{ item.nom }}</span>
                                        <span class="fa fa-eye text-slate-500" @click="isOpen.details_fournisseur = true, nom = item.nom, contact = item.contact, email = item.email, adresse = item.adresse, prodDispo = item.prodDispo, quantite = item.quantite, unite = item.unite, prixUnitaire = item.prixUnitaire, description = item.description, lieu = item.lieu, capaciteProd = item.capaciteProd, nomcat = item.nomcat"></span>
                                    </div>
                                    <div class="flex flex-col  h-fit">
                                        <div class="w-full flex justify-between items-start">
                                            <div class="w-full overflow-y-hidden flex justify-start items-center gap-x-3">
                                                <span class='font-semibold'>Produit :</span>
                                                <span class='text-slate-500'>{{ item.prodDispo }}</span>
                                            </div>
                                            <span class="mr-2 text-red-500 font-bold flex  justify-end items-center gap-x-3">
                                                            <span :class="verify_compared_client(item.prixUnitaire)"></span>
                                            <span>{{ item.prixUnitaire }}Ar</span>
                                            </span>
                                        </div>
                                        <div class="w-full flex justify-between items-start">
                                            <div class="w-full overflow-y-hidden flex justify-start items-center gap-x-3">
                                                <span class='font-semibold'>Categorie:</span>
                                                <span class='text-slate-500'>{{ item.nomcat }}</span>
                                            </div>
                                            <div class="w-full overflow-y-hidden flex justify-end items-center gap-x-3">
                                                <span class='font-semibold'>Quantité :</span>
                                                <span class='text-slate-500'>{{ item.quantite + ' ' + item.unite
                                                                                                                                }}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Card>
                    </div>
                    <div v-else>
                        Pas des données qui correspond à votre recherche. Veuillez réssayer une autre.
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- <Modal :isOpen="isOpen.guide" @close="close_guide()">
         <Modal :isOpen="true" @close="close_guide()"> 
        <h4 class="font-bold text-lg ">Guide de comparaison</h4>
        <p class="text-slate-500">
            lorem impsum lorem impsum lorem impsum lorem impsum lorem impsum lorem impsum lorem impsum lorem impsum.
            lorem impsum lorem impsum lorem impsum lorem impsum .
        </p>
        <div class="flex max-md:flex-col justify-center items-start w-full max-w-full">
            <img scr="" alt="photo"/>
            <img scr="" alt="photo"/>
        </div>
    </Modal> -->
    <Modal :isOpen="isOpen.comparaison.client" @close="isOpen.comparaison.client=false" class="md:hidden">
        <div class="w-full flex flex-col gap-3">
            <h4 class="font-bold text-slate-700">Client</h4>
                <div v-if="compared_data.client!==undefined" class="flex active:shadow-sm active:shadow-red-500 border-l-4 border-red-500   group flex-col justify-between items-center rounded bg-red-200 shadow-red-300 shadow-sm p-2">
                    <div class="flex w-full gap-x-3">
                        <div class="max-md:w-[60px] md:w-[80px] lg:w-[100px] h-full flex justify-center items-center">
                            <img src="./../../assets/user.png" class="w-10/12" alt="">
                        </div>
                        <div class="w-full flex flex-col gap-y-2">
                            <div class="w-full text-lg font-bold overflow-y-hidden">{{ compared_data.client.nom }}</div>
                            <div class="flex flex-col  h-fit">
                                <div class="w-full flex justify-between items-start">
                                    <div class="w-full overflow-y-hidden flex justify-start items-center gap-x-3">
                                        <span class='font-semibold'>Produit :</span>
                                        <span class='text-slate-500'>{{ compared_data.client.prodDispo }}</span>
                                    </div>
                                    <span class="mr-2 text-red-500 font-bold flex  justify-end items-center gap-x-3">
                                                            
                                        {{ compared_data.client.prixUnitaire }}
                                        </span>
                                </div>
                                <div class="w-full flex justify-between items-start">
                                    <div class="w-full overflow-y-hidden flex justify-start items-center gap-x-3">
                                        <span class='font-semibold'>Categorie:</span>
                                        <span class='text-slate-500'>{{ compared_data.client.nomcat }}</span>
                                    </div>
                                    <div class="w-full overflow-y-hidden flex justify-end items-center gap-x-3">
                                        <span class='font-semibold'>Quantité :</span>
                                        <span class='text-slate-500'>{{ compared_data.client.quantite + ' ' + compared_data.client.unite
                                                                                                                                }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <h4 class="font-bold text-slate-700">Fournisseur</h4>
            <Card v-for="(item,index) in filtered_data(fournisseurs)" :key="index">
                <div class="flex w-full gap-x-3">
                    <div class="max-md:w-[60px] md:w-[80px] lg:w-[100px] h-full flex justify-center items-center">
                        <img src="./../../assets/user.png" class="w-10/12" alt="">
                    </div>
                    <div class="w-full flex flex-col gap-y-2">
                        <div class="w-full text-lg font-bold overflow-y-hidden">{{ item.nom }}</div>
                        <div class="flex flex-col  h-fit">
                            <div class="w-full flex justify-between items-start">
                                <div class="w-full overflow-y-hidden flex justify-start items-center gap-x-3">
                                    <span class='font-semibold'>Produit :</span>
                                    <span class='text-slate-500'>{{ item.prodDispo }}</span>
                                </div>
                                <span class="mr-2 text-red-500 font-bold flex  justify-end items-center gap-x-3">
                                                            <span :class="verify_compared_client(item.prixUnitaire)"></span>
                                <span>{{ item.prixUnitaire }}Ar</span>
                                </span>
                            </div>
                            <div class="w-full flex justify-between items-start">
                                <div class="w-full overflow-y-hidden flex justify-start items-center gap-x-3">
                                    <span class='font-semibold'>Categorie:</span>
                                    <span class='text-slate-500'>{{ item.nomcat }}</span>
                                </div>
                                <div class="w-full overflow-y-hidden flex justify-end items-center gap-x-3">
                                    <span class='font-semibold'>Quantité :</span>
                                    <span class='text-slate-500'>{{ item.quantite + ' ' + item.unite
                                                                                                                                }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Card>
        </div>
    </Modal>
    <Modal :isOpen="isOpen.comparaison.fournisseur" @close="isOpen.comparaison.fournisseur=false" class="md:hidden">
        <div class="w-full flex flex-col gap-3">
            <h4 class="font-bold text-slate-700 ">Fournisseur</h4>
            <div v-if="compared_data.fournisseur!==undefined" class="flex active:shadow-sm active:shadow-red-500 border-l-4 border-red-500   group flex-col justify-between items-center rounded bg-red-200 shadow-red-300 shadow-sm p-2">
                <div class="flex w-full gap-x-3">
                    <div class="max-md:w-[60px] md:w-[80px] lg:w-[100px] h-full flex justify-center items-center">
                        <img src="./../../assets/user.png" class="w-10/12" alt="">
                    </div>
                    <div class="w-full flex flex-col gap-y-2">
                        <div class="w-full text-lg font-bold overflow-y-hidden">{{ compared_data.fournisseur.nom }}</div>
                        <div class="flex flex-col  h-fit">
                            <div class="w-full flex justify-between items-start">
                                <div class="w-full overflow-y-hidden flex justify-start items-center gap-x-3">
                                    <span class='font-semibold'>Produit :</span>
                                    <span class='text-slate-500'>{{ compared_data.fournisseur.prodDispo }}</span>
                                </div>
                                <span class="mr-2 text-red-500 font-bold flex  justify-end items-center gap-x-3">
                                                            
                                        {{ compared_data.fournisseur.prixUnitaire }}Ar
                                        </span>
                            </div>
                            <div class="w-full flex justify-between items-start">
                                <div class="w-full overflow-y-hidden flex justify-start items-center gap-x-3">
                                    <span class='font-semibold'>Categorie:</span>
                                    <span class='text-slate-500'>{{ compared_data.fournisseur.nomcat }}</span>
                                </div>
                                <div class="w-full overflow-y-hidden flex justify-end items-center gap-x-3">
                                    <span class='font-semibold'>Quantité :</span>
                                    <span class='text-slate-500'>{{ compared_data.fournisseur.quantite + ' ' + compared_data.fournisseur.unite
                                                                                                                                }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <h4 class="font-bold text-slate-700">Client</h4>
            <Card v-for="(client,index) in filtered_data(clients)" :key="index" >
                <div class="flex w-full gap-x-3 ">
                    <div class="max-md:w-[60px] md:w-[80px] lg:w-[100px] h-full flex justify-center items-center">
                        <img src="./../../assets/user.png" class="w-10/12" alt="">
                    </div>
                    <div class="w-full flex flex-col gap-y-2">
                        <div class="w-full text-lg font-bold overflow-y-hidden">{{ client.nom }}</div>
                        <div class="flex flex-col  h-fit">
                            <div class="w-full flex justify-between items-center">
                                <div class="w-full overflow-y-hidden flex justify-start items-center gap-x-3">
                                    <span class='font-semibold'>Produit :</span>
                                    <span class='text-slate-500'>{{ client.prodDemande }}</span>
                                </div>
                                <span class="mr-2 text-red-500 font-bold flex  justify-end items-center gap-x-3">
                                                            <span :class="verify_compared_fournisseur(client.prixUnitaire)"></span>
                                <span>{{ client.prixUnitaire }}Ar</span>
                                </span>
                            </div>
                            <div class="w-full flex justify-between items-center">
                                <div class="w-full overflow-y-hidden flex justify-start items-center gap-x-3">
                                    <span class='font-semibold'>Categorie:</span>
                                    <span class='text-slate-500'>{{ client.nomcat }}</span>
                                </div>
                                <div class="w-full overflow-y-hidden flex justify-end items-center gap-x-3">
                                    <span class='font-semibold'>Quantité :</span>
                                    <span class='text-slate-500'>{{ client.quantite + ' ' + client.unite
                                                                                                                                    }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Card>
        </div>
    </Modal>
    <Modal :isOpen="isOpen.details_client" @close="isOpen.details_client=false">
        <h3 class="font-bold text-xl w-full text-left">{{ nom }}</h3>
        <div class="text-sm w-full grid   md:grid-cols-2 items-start gap-3">
            <div class="flex flex-col items-start justify-start gap-y-3">
                <div class="items-center text-left text-sm py-1 underline font-semibold">Fiche du client</div>
                <div class="flex flex-col w-full border-b">
                    <div class=" font-semibold">Contact</div>
                    <div class="text-slate-500">{{ contact }}</div>
                </div>
                <div class="flex flex-col w-full border-b">
                    <div class="font-semibold">E-mail</div>
                    <div class="text-slate-500">{{ email }}</div>
                </div>
                <div class="flex flex-col w-full border-b">
                    <div class="font-semibold">Adresse</div>
                    <div class="text-slate-500">{{ adresse }}</div>
                </div>
                <div class="flex flex-col w-full border-b">
                    <div class="font-semibold">Lieu</div>
                    <div class="text-slate-500">{{ lieu }}</div>
                </div>
                <div class="flex flex-col w-full border-b">
                    <div class="font-semibold">Besoin</div>
                    <div class="text-slate-500">{{ besoin }}</div>
                </div>
            </div>
            <div class="flex flex-col items-start justify-start gap-y-3">
                <div class="items-center text-left text-sm py-1 font-semibold underline">Fiche du produit
                </div>
                <div class="flex flex-col w-full border-b">
                    <div class="font-semibold">Produit demandé</div>
                    <div class="text-slate-500">{{ prodDemande }}</div>
                </div>
                <div class="flex flex-col w-full border-b">
                    <div class="font-semibold">Quantité</div>
                    <div class="text-slate-500">{{ quantite }} {{ unite }}</div>
                </div>
                <div class="flex flex-col w-full border-b">
                    <div class="font-semibold">Prix unitaire</div>
                    <div class="text-slate-500">{{ prixUnitaire }} Ar</div>
                </div>
                <div class="flex flex-col w-full border-b">
                    <div class="font-semibold">Description</div>
                    <div class="text-slate-500">{{ description }}</div>
                </div>
                <div class="flex flex-col w-full border-b">
                    <div class="font-semibold">Catégorie</div>
                    <div class="text-slate-500">{{ nomcat }}</div>
                </div>
            </div>
        </div>
    </Modal>
    <Modal :isOpen="isOpen.details_fournisseur" @close="isOpen.details_fournisseur=false">
        <h3 class="font-bold text-xl w-full text-left">{{ nom }}</h3>
        <div class="text-sm w-full grid   md:grid-cols-2 items-start gap-3">
            <div class="flex flex-col items-start justify-start gap-y-3">
                <div class="items-center text-left text-sm py-1 underline font-semibold">Fiche du fournisseur</div>
                <div class="flex flex-col w-full border-b">
                    <div class=" font-semibold">Contact</div>
                    <div class="text-slate-500">{{ contact }}</div>
                </div>
                <div class="flex flex-col w-full border-b">
                    <div class="font-semibold">E-mail</div>
                    <div class="text-slate-500">{{ email }}</div>
                </div>
                <div class="flex flex-col w-full border-b">
                    <div class="font-semibold">Adresse</div>
                    <div class="text-slate-500">{{ adresse }}</div>
                </div>
                <div class="flex flex-col w-full border-b">
                    <div class="font-semibold">Lieu</div>
                    <div class="text-slate-500">{{ lieu }}</div>
                </div>
                <div class="flex flex-col w-full border-b">
                    <div class="font-semibold">Capacité</div>
                    <div class="text-slate-500">{{ capaciteProd }}</div>
                </div>
            </div>
            <div class="flex flex-col items-start justify-start gap-y-3">
                <div class="items-center text-left text-sm py-1 font-semibold underline">Fiche du produit
                </div>
                <div class="flex flex-col w-full border-b">
                    <div class="font-semibold">Produit disponible</div>
                    <div class="text-slate-500">{{ prodDispo }}</div>
                </div>
                <div class="flex flex-col w-full border-b">
                    <div class="font-semibold">Quantité</div>
                    <div class="text-slate-500">{{ quantite }} {{ unite }}</div>
                </div>
                <div class="flex flex-col w-full border-b">
                    <div class="font-semibold">Prox unitaire</div>
                    <div class="text-slate-500">{{ prixUnitaire }} Ar</div>
                </div>
                <div class="flex flex-col w-full border-b">
                    <div class="font-semibold">Description</div>
                    <div class="text-slate-500">{{ description }}</div>
                </div>
                <div class="flex flex-col w-full border-b">
                    <div class="font-semibold">Catégorie</div>
                    <div class="text-slate-500">{{ nomcat }}</div>
                </div>
            </div>
        </div>
    </Modal>
    <Footer></Footer>
</template>
<script>
    import axios from 'axios';
    import apiUrl from '../../ApiConfig';
    import Footer from '@/components/Client/Footer.vue';
    import Header2Vue from '@/components/Client/Header2.vue';
    import '@/assets/comparaison.css';
    import Modal from '@/components/modal/modal.vue';
    import $ from 'jquery';
    import Menu from '@/components/Client/menu/Menu.vue'
    import Card from '@/components/card/Card.vue'
    // import Footer from './../../components/Client/Footer.vue'
    export default {
        // eslint-disable-next-line vue/multi-word-component-names
        name: 'Comparaison',
        components: {
            Footer,
            Header2Vue,
            Modal,
            Menu,
            Card
        },
        data() {
            return {
                modal_menu: false,
                clients: [],
                fournisseurs: [],
                inpProduit: '',
                inpPrix1: 0,
                inpPrix2: 0,
                inpAdresse: '',
                inpCat: '',
                prod: '',
                categories: [],
                compared_data: {
                    client: undefined,
                    fournisseur: undefined
                },
                index_client_clicked: undefined,
                index_fournisseur_clicked: undefined,
                isOpen: {
                    guide: false,
                    comparaison: {
                        client: false,
                        fournisseur: false
                    },
                    details_fournisseur:false,
                    details_client:false
                },
                page: 1,
                province: localStorage.getItem('province'),
                region: localStorage.getItem('region')
                
            }
        },
        mounted() {
            this.getClients(),
            this.getFournisseurs(),
            this.categorieSelection()
            if (typeof JSON.parse(localStorage.getItem("guide_comparaison")) == 'boolean') {
                this.isOpen.guide = JSON.parse(localStorage.getItem("guide_comparaison"))
            } else {
                this.isOpen.guide = true
            }
            console.log(typeof JSON.parse(localStorage.getItem("guide_comparaison")))
        },
        methods: {
            receive_data_menu_mobile(data) {
                this.modal_menu = data
            },
            filtered_data(data) {
                return data.filter(item => (this.inpCat != '' ? item.nomcat.toLowerCase().includes(this.inpCat.toLowerCase()) : item))
                    .filter(item => (
                        this.inpProduit != '' ?
                        (
                            this.inpProduit.toLowerCase().includes(
                                this.filtered_item_product(item).toLowerCase())
                        ) :
                        item))
                    .filter(item => (this.inpAdresse != '' ? item.adresse.toLowerCase().includes(this.inpAdresse.toLowerCase()) : item))
                    .filter(item => (this.inpPrix1 != 0 ? (this.inpPrix1 <= item.prixUnitaire) : item))
                    .filter(item => (this.inpPrix2 != 0 ? (this.inpPrix2 >= item.prixUnitaire) : item))
            },
            filtered_item_product(item) {
                if (
                    item.prodDemande
                ) {
                    return item.prodDemande
                } else {
                    return item.prodDispo
                }
            },
            categorieSelection() {
                try {
                    axios.get(apiUrl + 'api/affichage_nom_categorie').then(response => {
                        this.categories = response.data
                    }).catch(error => {
                        console.log(error)
                    })
                } catch (error) {
                    console.error(error);
                }
            },
            getClients() {
                const typeAbo = localStorage.getItem('typeAbo');
                // const province = localStorage.getItem('province');
                if (typeAbo === 'standard')
                    try {
                        axios.get(apiUrl + 'api/affichage_client_seera_standard', {
                                params: {
                                    province: this.province
                                }
                            })
                            .then(response => {
                                this.clients = response.data
                                console.log(response.data)
                                // eslint-disable-next-line no-undef
                            }).catch(error => {
                                console.log(error)
                                this.clients = []
                            })
                    } catch (error) {
                        console.error(error);
                    }
                else if (typeAbo === 'basic') {
                    try {
                        axios.get(apiUrl + 'api/affichage_client_seera_basic', {
                                params: {
                                    region: this.region
                                }
                            })
                            .then(response => {
                                this.clients = response.data
                                // eslint-disable-next-line no-undef
                            }).catch(error => {
                                console.log(error)
                                this.clients = []
                            })
                    } catch (error) {
                        console.error(error);
                    }
                } else {
                    try {
                        axios.get(apiUrl + 'api/affichage_client_seera')
                            .then(response => {
                                this.clients = response.data
                                // eslint-disable-next-line no-undef
                            }).catch(error => {
                                console.log(error)
                                this.clients = []
                            })
                    } catch (error) {
                        console.error(error);
                    }
                }
            },
            getFournisseurs() {
                const typeAbo = localStorage.getItem('typeAbo');
                // const province = localStorage.getItem('province');
                if (typeAbo === 'standard')
                    try {
                        axios.get(apiUrl + 'api/affichage_fournisseur_seera_standard', {
                                params: {
                                    province: this.province
                                }
                            })
                            .then(response => {
                                this.fournisseurs = response.data
                                //console.log(response.data)
                                // eslint-disable-next-line no-undef
                            }).catch(error => {
                                console.log(error)
                                this.fournisseurs = []
                            })
                    } catch (error) {
                        console.error(error);
                    }
                else if (typeAbo === 'basic') {
                    try {
                        axios.get(apiUrl + 'api/affichage_fournisseur_seera_basic', {
                                params: {
                                    region: this.region
                                }
                            })
                            .then(response => {
                                this.fournisseurs = response.data
                                // eslint-disable-next-line no-undef
                            }).catch(error => {
                                console.log(error)
                                this.fournisseurs = []
                            })
                    } catch (error) {
                        console.error(error);
                    }
                } else {
                    try {
                        axios.get(apiUrl + 'api/affichage_fournisseur_seera')
                            .then(response => {
                                this.fournisseurs = response.data
                                // eslint-disable-next-line no-undef
                            }).catch(error => {
                                console.log(error)
                                this.fournisseurs = []
                            })
                    } catch (error) {
                        console.error(error);
                    }
                }
            },
            getClickedClient(item) {
                this.compared_data.client = item
                this.compared_data.fournisseur = undefined
                this.isOpen.comparaison.client = false
            },
            getClickedFournisseur(item) {
                this.compared_data.fournisseur = item
                this.compared_data.client = undefined
                this.isOpen.comparaison.fournisseur = false
                
            },
            verify_compared_client(item) {
                if (this.compared_data.client != undefined) {
                    if (this.compared_data.client.prixUnitaire < item) {
                        return 'bg-green-100 text-green-700 fas fa-angles-up p-1'
                    } else if (this.compared_data.client.prixUnitaire == item) {
                        return 'bg-blue-100 text-blue-700 fas fa-equals p-1'
                    } else {
                        return 'bg-red-100 text-red-700 fas fa-angles-down p-1'
                    }
                }
            },
            verify_compared_fournisseur(item) {
                if (this.compared_data.fournisseur != undefined) {
                    if (this.compared_data.fournisseur.prixUnitaire < item) {
                        return 'bg-green-100 text-green-700 fas fa-angles-up p-1'
                    } else if (this.compared_data.fournisseur.prixUnitaire == item) {
                        return 'bg-blue-100 text-blue-700 fas fa-equals p-1'
                    } else {
                        return 'bg-red-100 text-red-700 fas fa-angles-down p-1'
                    }
                }
            },
            close_guide() {
                this.isOpen.guide = false
                localStorage.setItem('guide_comparaison', false)
            }
        }
    }
</script>
<style lang="">

</style>
import { createApp } from 'vue'
import App from './App.vue'
import '@/assets/tailwind.css'
import router from './router'
import '@/assets/fontawesome/css/all.min.css'
import '@/assets/styleAdmin/modal.css'
import VueApexCharts from "vue3-apexcharts";
const app = createApp(App)
app.use(router)
app.use(VueApexCharts);
app.mount('#app')

import '@/assets/overflow.css'
import '@/assets/animation.css'

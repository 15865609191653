<template lang="">
    <div class="w-full flex flex-col ">
        <button @click="isOpen=!isOpen" class="w-full bg-blue-50 p-2 flex justify-between items-center">
                <span class="text-slate-700 font-bold">{{title}}</span>
                <span class="fas fa-angle-right transition duration-300" :class="isOpen?'rotate-90':'rotate-0'"></span>
            </button>
        <div class="w-full  flex flex-col p-2 gap-y-2  border border-blue-50" v-if="isOpen">
            <slot></slot>
        </div>
    </div>
</template>
<script>
    export default {
        props: {
            title: {
                type: String,
                required: true,
            },
        },
        data() {
            return {
                isOpen: false
            }
        },
        methods: {
        }
    }
</script>
<style lang="">

</style>
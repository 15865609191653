<template lang="">
    <div class="modal_ajout w-screen h-screen inset-0 lg:hidden" v-show="modal_menu" @click="close()"></div>
    <Transition >
        <div class="modal_ajout text-sm top-0 right-0 flex justify-end items-start   " v-show="modal_menu">

            <div class="flex flex-col justify-start items-center max-md:w-screen md:w-[450px] h-screen shadow-sm shadow-slate-600/80 bg-white">
                <div class="flex justify-end items-center  w-full  py-2 px-5">
                    <div class=" fas fa-times text-slate-600 text-xl  p-1  rounded" @click="close()">
                    </div>
                </div>
                <div class="w-11/12 py-4 flex flex-col  divide-y">
                    <router-link :to="section.link" @click="close()" v-for="(section, index) in sections" :key="index" class="p-1 py-3 text-slate-600 hover:text-red-500 hover:bg-red-100 px-2  w-full flex justify-start items-center gap-x-3">
                        <span :class="section.icon"></span>
                        <span>{{ section.title }}</span>
                    </router-link>
                    <router-link to="/" class=" px-3 h-8 py-1 w-full text-center font-semibold  rounded bg-red-400   hover:bg-red-500  text-white ">
                        Se déconnecter
                    </router-link>
                </div>
            </div>
           
        </div>
    </Transition>
</template>
<style scoped>
    .v-enter-from,
    .v-leave-to {
        transform: translateX(400px);
        opacity: 0;
    }
    .v-enter-active,
    .v-leave-active {
        transition: all 0.3s;

    }
    
</style>
<script>
    export default {
        props: {
            modal_menu: Boolean,
            required: true
        },
        emits:[
            'close'
        ],
        data() {
            return {
                sections: [{
                        link: '/home',
                        title: 'Accueil',
                        icon: 'fa fa-home'
                    },
                    {
                        link: '/customer',
                        title: 'Client',
                        icon: 'fa fa-users'
                    },
                    {
                        link: '/supplier',
                        title: 'Fournisseur',
                        icon: 'fas fa-hand-holding-droplet'
                    },
                    {
                        link: '/investor',
                        title: 'Investisseur',
                        icon: 'fas fa-hand-holding-dollar'
                    },
                    {
                        link: '/comparison',
                        title: 'Comparaison',
                        icon: 'fas fa-scale-balanced'
                    },
                ],
            }
        },
        methods: {
            close(){
                this.$emit('close')
            }
        }
    }
</script>
<style lang="">

</style>
<template>
    <Header :modal_menu="modal_menu" @toggle="modal_menu=!modal_menu"></Header>
    <br/>
    <br/>
    <br/>
    <input v-model="$route.params.iduser" class="text-center hidden">
    <div class="w-screen flex flex-col items-center max-lg:px-4">
        <br>
        <input v-model="$route.params.iduser" class="hidden">
        <input v-model="abonnement" class="hidden">
        <input v-model="prix" class="hidden">
        <div class="w-full ">
            <div class="w-full  lg:mx-20">
                <h4 class="text-left font-semibold border-b text-2xl">Mode de paiement</h4>
            </div>
            <div class="w-full text-left flex flex-col justify-start items-start">
                <div class="lg:mx-20 ">
                    
                    <p class="text-gray-600">Chers clients, pour effectuer votre paiement, veuillez choisir le mode de paiement qui vous convient le mieux parmi les options disponibles.</p>
                </div>
                <div class="w-full max-md:my-4 lg:mx-20 flex justify-center items-center">
                    <div class="max-md:flex max-md:flex-col md:grid md:grid-cols-3 max-md:items-start max-md:justify-start md:justify-center md:items-center gap-x-4 max-md:gap-y-3 max-md:my-3 w-full">
                        <div class="flex flex-col">
                            <div class="">
                                <img src="../../assets/telma-cust-logo.jpg" class="w-[50px]" alt="">
                                
                                <span class="text-gray-600">Randrianasolo Jean Eugene</span>
                            </div>
                            
                                <p class="text-left text-gray-600">
                                    034 43 103 34
                                </p>
                            
                        </div>
                        <div class="flex flex-col">
                            <div class="">
                                <img src="./../../assets/Orange-Logo.png" class="w-[50px]" alt="">
                                
                                <span class="text-gray-600">Randrianasolo Jean Eugene</span>
                            </div>
                            
                                <p class="text-left text-gray-600">
                                    032 43 103 34
                                </p>
                            
                        </div>
                        <div class="flex flex-col">
                            <div class="">
                                <img src="./../../assets/airtel-logo.jpg" class="w-[50px]" alt="">
                               <span class="text-gray-600">Randrianasolo Jean Eugene</span>
                            </div>
                            
                                <p class="text-left text-gray-600">
                                    033 43 103 34
                                </p>
                            
                        </div>
                    </div>
                </div>
                <div class="w-full flex flex-col justify-center items-start   py-4">
                    <form @submit.prevent="ajoutAbonnement">
                        <input v-model="$route.params.iduser" class="hidden">
                        <div class="w-screen lg:px-20 max-lg:flex max-lg:flex-col max-lg:items-center max-lg:justify-center">
                            <div class="w-full flex flex-col   gap-y-4">
                                <div class="flex flex-col items-start w-full justify-center" v-if="abonnement==='basic'">
                                    <h5 class="font-semibold ">
                                        Choix de region <span class="text-red-500">*</span>
                                    </h5>
                                    <select name="" id="" class="border h-8  w-full" v-model="region">
                                            <option value="Boeny">Boeny</option>
                                            <option value="Atsimo Atsinanana">Atsimo Atsinanana</option>
                                            <option value="Alaotra Mangoro">Alaotra Mangoro</option>
                                            <option value="Diana">Diana</option>
                                            <option value="Vatovavy">Vatovavy</option>
                                            <option value="Fitovinany">Fitovinany</option>
                                            <option value="Melaky">Melaky</option>
                                            <option value="Analamanga">Analamanga</option>
                                            <option value="Vakinakaratra">Vakinakaratra</option>
                                            <option value="Atsinanana">Atsinanana</option>
                                            <option value="Boeny">Boeny</option>
                                            <option value="Boeny">Boeny</option>
                                            <option value="Boeny">Boeny</option>
                                            <option value="Boeny">Boeny</option>
                                            <option value="Boeny">Boeny</option>
                                            <option value="Boeny">Boeny</option>
                                            <option value="Boeny">Boeny</option>
                                            <option value="Boeny">Boeny</option>
                                            <option value="Boeny">Boeny</option>
                                            <option value="Boeny">Boeny</option>
                                            <option value="Boeny">Boeny</option>
                                            <option value="Boeny">Boeny</option>
                                            <option value="Boeny">Boeny</option>
                                        </select>
                                </div>
                                <div class="flex flex-col items-start w-full justify-center" v-if="abonnement==='standard'">
                                    <h5 class="font-semibold ">
                                        Choix de province <span class="text-red-500">*</span>
                                    </h5>
                                    <select name="" id="" class="border h-8  w-full" v-model="province" >
                                        <option value="Antananarivo">Antananarivo</option>
                                        <option value="Fianarantsoa">Fianarantsoa</option>
                                        <option value="Toliara">Toliara</option>
                                        <option value="Mahajanga">Mahajanga</option>
                                        <option value="Toamasina">Toamasina</option>
                                        <option value="Antsiranana">Antsiranana</option>
                                    </select>
                                </div>
                                <div>
                                    <div class="w-full">
                                        <h5 class="font-semibold ">
                                            Formulaire de paiement
                                        </h5>
                                    </div>
                                </div>
                                <div>
                                    <div><label for="Objet" class="text-gray-600">Objet</label></div>
                                    <div class="border"><input type="text" class="w-full py-1 focus:outline-none" value="Abonnement" disabled></div>
                                </div>
                                <div class="flex justify-start items-center gap-x-4 w-full">
                                    <div class="w-1/2">
                                        <div><label for="" class="text-gray-600">Abonnement choisi</label></div>
                                        <div class="border"><input type="text" class="w-full py-1 focus:outline-none" v-model="abonnement" disabled></div>
                                    </div>
                                    <div class="w-1/2">
                                        <div><label for="" class="text-gray-600">Prix</label></div>
                                        <div class="border"><input type="text" class="w-full py-1 focus:outline-none" v-model="prix" disabled></div>
                                    </div>
                                </div>
                                <div>
                                    <div><label for="" class="text-gray-600">Description</label></div>
                                    <div class="border">
                                        <textarea name="" id="" rows="5" class="w-full  focus:outline-none" v-model="description">
                                                        </textarea>
                                    </div>
                                </div>
                                <div class="flex justify-start items-center">
                                    <div class="w-fit bg-slate-700 p-1 flex">
                                        <div class="py-1 px-4 cursor-pointer bg-white inp1" @click="showing(1)">
                                            Reference</div>
                                        <div class="py-1 px-4 text-white  cursor-pointer inp2 bg-slate-600" @click="showing(2)">Piece joint</div>
                                    </div>
                                </div>
                                <div v-show="choice == false">
                                    <div><label for="" class="text-gray-600">Reference <span
                                                    class="text-red-600">*</span></label></div>
                                    <div class="border"><input type="text" class="w-full py-1 focus:outline-none" v-model="refText"></div>
                                </div>
                                <div>
                                    <div v-show="choice == true" class="mb-3 p-1">
                                        <div><label for="" class="text-gray-600">Piece joint <span
                                                        class="text-red-600">*</span></label></div>
                                        <div class="border"><input type="file" class="w-full py-1  focus:outline-none" @change="handleFileChange"></div>
                                    </div>
                                    <div v-show="verificationRef">
                                        <p class="text-red-500">Veuillez remplir l'un de formulaire de réference soit par texte ou soit par image ou les deux.</p>
                                    </div>
                                    <div class="flex justify-center  w-11/12 items-center gap-x-4">
                                        <div class="flex justify-end w-full items-center gap-x-4">
                                            <div class="max-md:w-5/12"><button class="px-3 max-md:w-full py-1 border-2 border-gray-600  text-gray-600" @click.prevent="$router.go(-1)">Annuler</button></div>
                                            <div class="max-md:w-5/12"><button type="submit" class="px-3 max-md:w-full py-1 bg-blue-400 border-2 border-blue-400 hover:border-blue-500 hover:bg-blue-500 text-white">Envoyer</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
    <div class="modal_ajout w-screen h-screen bg-black bg-opacity-50 flex justify-center items-center" v-show="ref">
        <div class="bg-white p-4">
            <div class="w-full flex justify-start items-center">
                <div>
                    <p>Vous avez remplir l'un de formulaire de réference.</p>
                </div>
            </div>
            <div class="w-full flex items-center justify-end">
                <div class="">
                    <button class="px-4 py-1 bg-blue-400 hover:bg-blue-500 text-white" @click="ref = false">OK</button>
                </div>
            </div>
        </div>
    </div>
    <div class="modal_ajout w-screen h-screen bg-black bg-opacity-50 flex justify-center items-center " v-show="modal_verification">
        <div class="bg-white flex flex-col justify-center items-center shadow-sm shadow-gray-300 p-4">
            <div class="w-full flex justify-end items-center">
                <div class="fa fa-times cursor-pointer" @click="$router.push('/')"></div>
            </div>
            <div class="fas fa-check rounded-full border-2 fa-2x text-green-400 border-green-400 p-2">
            </div>
            <div class="w-9/12">
                <p class="text-gray-500">Votre abonnement est envoyé, veuillez attendre pour le valider. Vous reçevrez de l'email si l'abonnement est effectué ou non. Merci !
                </p>
            </div>
            <div>
                <div @click="generer_pdf(), $router.push('/')" class="text-white px-4 py-1 bg-blue-400 hover:bg-blue-500 flex gap-x-4 justify-center items-center  cursor-pointer">
                    <div class="fas fa-download"></div>
                    <div>Télécharger la facture</div>
                </div>
            </div>
        </div>
    </div>
    <Footer></Footer>   
    <Menu_landing :modal_menu="modal_menu" @close="modal_menu=false"></Menu_landing>
</template>
<script>
    import axios from 'axios'
    import apiUrl from '../../ApiConfig';
    import jsPDF from 'jspdf';
    // eslint-disable-next-line no-unused-vars
    import autoTable from 'jspdf-autotable';
    import $ from 'jquery'
    import Footer from '@/components/Client/Footer.vue'
    import Menu_landing from '@/components/Client/menu/Menu_lainding.vue'
    import Header from '@/components/Client/Header_all.vue';
    export default {
        name:'paiement',
        components: {
            Menu_landing,
                Header,
                Footer
        },
        data() {
            return {
                abonnement: '',
                prix: '',
                description: '',
                refText: '',
                refImage: '',
                choice: false,
                numero: null,
                operateur: null,
                verificationRef: false,
                ref: false,
                modalValidation: false,
                modal_num: false,
                modal_verification: false,
                nomUser: '',
                adresseUser: '',
                descriptionAbo: '',
                region:'',
                regions:'',
                province:'',
                province:'',
                modal_menu:false
            }
        },
        mounted() {
            this.getAbo();
            this.getUserName();
            this.getUserAdresse();
            console.log(this.abonnement)
        },
        methods: {
            generer_pdf: function() {
                const pdf = new jsPDF('p', 'mm', [210, 297]);
                // const img = require('@/assets/logoAgri.jpg')
                let yPos = 20; // Position verticale de départ
                const margin = 10; // Marge supérieure
                yPos += 10; // Augmentez la position verticale pour la prochaine ligne de texte
                const pageHeight = pdf.internal.pageSize.height;
                if (yPos + 10 > pageHeight) {
                    pdf.addPage();
                    yPos = margin;
                }
                // pdf.addImage(img,'PNG',10,0,15,25)
                /*const date=new Date()
                // eslint-disable-next-line no-unused-vars
                const y=10
                */
                const date = new Date()
                const y = 10
                
                
                pdf.setFont('Arial', 'bold')
                pdf.setFontSize(20)
                pdf.setTextColor('blue')
                pdf.text('SEERA', 195, 20, 'right')
                pdf.setFont('Arial', 'normal')
                pdf.setFontSize(12)
                pdf.setTextColor('#1B1B1B')
                pdf.text('Lot VK 17 à Fenomanana Mahazoarivo Antananarivo', 195, 28, 'right')
                pdf.text('Madagascar', 195, 38, 'right')
                pdf.setFont('Arial', 'bold')
                pdf.setFontSize(12)
                pdf.setTextColor('black')
                pdf.text('Facture d\'abonnement', 10, 48, 'left')
                pdf.setFont('Arial', 'normal')
                pdf.setFontSize(12)
                pdf.setTextColor('#1B1B1B')
                pdf.text('Date de facturation :', 10, 58, 'left')
                pdf.text(date.toLocaleDateString(), 60, 58), 'left';
                pdf.text('Date d\'echeance :', 10, 70, 'left')
                const echeanceDate = new Date(date)
                echeanceDate.setDate(date.getDate() + 31)
                pdf.text(echeanceDate.toLocaleDateString(), 60, 68, 'left');
                pdf.setFont('Arial', 'bold')
                pdf.setFontSize(12)
                pdf.setTextColor('black')
                pdf.text('Facture à', 10, 83, 'left')
                pdf.setFont('Arial', 'normal')
                pdf.setFontSize(12)
                pdf.setTextColor('#1B1B1B')
                pdf.text("Nom: ", 10, 93, 'left')
                pdf.text("Adresse:", 10, 103, 'left')
                pdf.text("Description:", 10, 123)
                pdf.text(this.nomUser, 10, 93, 'left')
                pdf.text(this.adresseUser, 10, 103, 'left')
                pdf.text(this.description, 10, 123)
                pdf.setFontSize(12)
                const startY = 133
                pdf.autoTable({
                    head: [
                        ['Description', 'Total']
                    ],
                    body: [
                        ['Abonnement SEERA ', this.prix],
                        [{
                            content: 'Sous-total',
                            styles: {
                                halign: 'right'
                            }
                        }, this.prix],
                        [{
                            content: 'Total',
                            styles: {
                                halign: 'right'
                            }
                        }, this.prix],
                        ['',
                        {
                            content: 'NON PAYEE',
                            weight:"bold"
                        }
                        ]
                    ],
                    startY: startY,
                });
                pdf.output('dataurlnewwindow')
            },
            showing(data) {
                const inp1 = $('.inp1')
                const inp2 = $('.inp2')
                if (data == 1) {
                    this.choice = false
                    if (inp1.hasClass('bg-slate-600')) {
                        inp1.removeClass('bg-slate-600')
                    }
                    if (inp1.hasClass('text-white')) {
                        inp1.removeClass('text-white')
                    }
                    inp2.addClass('text-white')
                    inp1.addClass('bg-white')
                    if (inp2.hasClass('bg-white')) {
                        inp2.removeClass('bg-white')
                    }
                    inp2.addClass('bg-slate-600')
                } else {
                    this.choice = true
                    if (inp2.hasClass('bg-slate-600')) {
                        inp2.removeClass('bg-slate-600')
                    }
                    if (inp2.hasClass('text-white')) {
                        inp2.removeClass('text-white')
                    }
                    inp1.addClass('text-white')
                    inp2.addClass('bg-white')
                    if (inp1.hasClass('bg-white')) {
                        inp1.removeClass('bg-white')
                    }
                    inp1.addClass('bg-slate-600')
                }
            },
            
            handleFileChange(event) {
                this.refImage = event.target.files[0];
            },

            ajoutAbonnement() {
                if (this.refText === '' && this.refImage === '') {
                    this.verificationRef = true;
                } else {
                    const formData = new FormData();
                    formData.append('iduser', this.$route.params.iduser);
                    formData.append('type', this.abonnement);
                    formData.append('montant', this.prix);
                    formData.append('description', this.description);
                    formData.append('refText', this.refText);
                    formData.append('refImage', this.refImage);
                    formData.append('province', this.province);
                    formData.append('region', this.region);
                    // eslint-disable-next-line no-unused-vars
                    axios.post(apiUrl + 'api/ajout_abonnement', formData).then(response => {
                            this.modal_verification = true;
                        })
                        .catch(
                            error => {
                                (
                                    console.log(error)
                                )
                            }
                        )
                }
            },
            redirect() {
                console.log(this.operateur)
                if (this.operateur !== null) {
                    this.$router.push('/Client/Verification')
                }
            },
            show() {
                if (this.operateur === "Telma") {
                    this.numero = "0344310334"
                }
                if (this.operateur === "Orange") {
                    this.numero = "0324310334"
                }
                if (this.operateur === "Airtel") {
                    this.numero = "0334310334"
                }
            },
            getAbo() {
                this.abonnement = localStorage.getItem("myAbonnement");
                this.prix = localStorage.getItem("myPrix");
            },
            getUserName() {
                axios.get(apiUrl + 'api/user_name', {
                    params: {
                        iduser: this.$route.params.iduser
                    }
                }).then(response => {
                    this.nomUser = response.data[0].name;
                }).catch(error => {
                    console.log(error)
                })
            },
            getUserAdresse() {
                axios.get(apiUrl + 'api/user_adresse', {
                    params: {
                        iduser: this.$route.params.iduser
                    }
                }).then(response => {
                    this.adresseUser = response.data[0].adresse;
                }).catch(error => {
                    console.log(error)
                })
            },
            
        }
    }
</script>
<template lang="">
    <div ref="modal_menu" class="bg-opacity-0   modal_menu w-screen bg-white  max-md:px-5 md:px-9 py-2">
        <div class="flex items-center justify-between">
            <div>
                <router-link to="/">
                    <img src="./../../assets/serra2.png" class="max-sm:w-20 sm:w-[10vw]">
                </router-link>
            </div>
            <!---->
            <div class=" flex justify-end items-center gap-x-7 text-slate-700">
                <div class="max-lg:hidden  flex scroll-smooth transition-all duration-100 justify-center  items-center gap-5 ">
                    <a v-for="(section, index) in sections" :key="index" class="hover:text-red-500 " :href="`/#${section.id}`">{{
                        section.title }}</a>
                </div>
                <router-link to="/signin" class="py-1 max-lg:hidden px-4 rounded bg-red-400 text-white hover:bg-red-500   ">
                    Se connecter
                </router-link>
                <div class="lg:hidden">
                    <button ref="btn_menu" class=" fas fa-bars text-xl   p-2" @click="toggle()"></button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'Header_all',
        props: {
            modal_menu: {
                type: Boolean,
                required: true
            }
        },
        emits:['toggle'],
        data() {
            return {
                sections: [{
                        id: 'Accueil',
                        title: 'Accueil',
                        
                    },
                    {
                        id: 'Service',
                        title: 'Service'
                    },
                    {
                        id: 'About',
                        title: 'A propos'
                    },
                    {
                        id: 'Pricing',
                        title: 'Nos offres'
                    },
                    {
                        id: 'Contact',
                        title: 'Contact'
                    },
                ],
                position: {
                    x: 0,
                    y: 0
                }
            }
        },
        methods: {
            toggle() {
                this.$emit('toggle')
            }
        }
}
</script>
<style lang="">
    
</style>
<template lang="">
    <div class="flex active:shadow-sm active:shadow-red-500 border-l-4 border-red-500   group flex-col justify-between items-center rounded bg-white shadow-gray-300 shadow-sm p-2" >
        <slot></slot>
    </div>
</template>
<script>
    export default {
        props: {
            // hasClicked: {
            //     type: Boolean,
            //     required: false,
                
            // },
        },
        data(){
            return{
                // hasClicked:false
            }
        },
       
        methods: {
            // clicked() {
            //     this.$emit('clicked');
               
            // }
        }
    }
</script>
<style lang="">

</style>
<script>
    import axios from 'axios'
    import apiUrl from '../../ApiConfig';
    import Footer from './../../components/Client/Footer.vue'
    import Header2 from './../../components/Client/Header2.vue'
    import Filtre from './../../components/Client/Filtre.vue'
    import Modal from '@/components/modal/modal.vue';
    import Menu from '@/components/Client/menu/Menu.vue'
    export default {
        // eslint-disable-next-line vue/multi-word-component-names
        name: 'Investisseur',
        components: {
            Footer,
            Header2,
            Filtre,
            Modal,
            Menu
        },
        data() {
            return {
                // image_modal:'',
                modal_image: false,
                inpMont1: 0,
                inpMont2: 0,
                inpNom: '',
                sections: [{
                        link: '/home',
                        title: 'Accueil'
                    },
                    {
                        link: '/customer',
                        title: 'Client'
                    },
                    {
                        link: '/supplier',
                        title: 'Fournisseur'
                    },
                    {
                        link: '/investor',
                        title: 'Investisseur'
                    },
                    {
                        link: '/comparison',
                        title: 'Comparaison'
                    },
                ],
                modal_menu: false,
                investisseurs: [],
                nom: '',
                contact: '',
                email: '',
                adresse: '',
                montant: '',
                quantite: '',
                unite: '',
                conditionUtil: '',
                duree: '',
                taux: '',
                observation: '',
                image: '',
                mode_list: localStorage.getItem('seera_list_mode') !== null ? JSON.parse(localStorage.getItem('seera_list_mode')) : true,
                modal_details: false,
                province: localStorage.getItem('province'),
                region: localStorage.getItem('region')
            }
        },
        mounted() {
            this.getInvestisseurs();
        },
        methods: {
            rechercheInvestisseur() {
                try {
                    axios.get(apiUrl + 'api/recherche_investisseur_seera', {
                        params: {
                            inpNom: this.inpNom,
                            inpMont1: this.inpMont1,
                            inpMont2: this.inpMont2
                        }
                    }).then(response => {
                        this.investisseurs = response.data
                    }).catch(error => {
                        console.log(error)
                    })
                } catch (error) {
                    console.error(error)
                }
            },
            getInvestisseurs() {
                const typeAbo = localStorage.getItem('typeAbo');
                // const province = localStorage.getItem('province');
                if (typeAbo === 'standard')
                    try {
                        axios.get(apiUrl + 'api/affichage_investisseur_seera_standard', {
                                params: {
                                    province: this.province
                                }
                            })
                            .then(response => {
                                this.investisseurs = response.data
                                // eslint-disable-next-line no-undef
                            }).catch(error => {
                                console.log(error)
                                this.investisseurs = []
                            })
                    } catch (error) {
                        console.error(error);
                    }
                else if (typeAbo === 'basic') {
                    try {
                        axios.get(apiUrl + 'api/affichage_investisseur_seera_basic', {
                                params: {
                                    region: this.region
                                }
                            })
                            .then(response => {
                                this.investisseurs = response.data
                                // eslint-disable-next-line no-undef
                            }).catch(error => {
                                console.log(error)
                                this.investisseurs = []
                            })
                    } catch (error) {
                        console.error(error);
                    }
                } else {
                    try {
                        axios.get(apiUrl + 'api/affichage_fournisseur_seera')
                            .then(response => {
                                this.investisseurs = response.data
                                // eslint-disable-next-line no-undef
                            }).catch(error => {
                                console.log(error)
                                this.investisseurs = []
                            })
                    } catch (error) {
                        console.error(error);
                    }
                }
            },
            receive_data_menu_mobile(data) {
                this.modal_menu = data
            },
            data_list_function(data) {
                this.mode_list = data
            },
            modal_details_show() {
                this.modal_details = !this.modal_details
            },
            show_modal_image() {
                // image_modal=img
                this.modal_image = true
            },
            filtered_data() {
                return this.investisseurs.filter(item => (this.inpNom != '' ? item.nom.toLowerCase().includes(this.inpNom.toLowerCase()) : item))
                    .filter(item => (this.inpMont1 != 0 ? (this.inpMont1 <= item.montant) : item))
                    .filter(item => (this.inpMont2 != 0 ? (this.inpMont2 >= item.montant) : item))
            },
        }
    }
</script>
<template>
    <Menu :modal_menu="modal_menu" @close="modal_menu=false">
    </Menu>
    <!-- modal investisseur -->
    <Modal :isOpen="modal_details" @close="modal_details=false">
        <h3 class="font-bold text-xl w-full text-left">{{ nom }}</h3>
        <div class="text-sm w-full grid  md:grid-cols-2 items-start gap-3">
            <div class="flex flex-col items-start justify-start gap-y-3">
                <div class="items-center text-left text-sm py-1 underline font-semibold">Fiche d' investisseur</div>
                <div class="flex flex-col w-full border-b">
                    <div class=" font-semibold">Contact</div>
                    <div class="text-slate-500">{{ contact }}</div>
                </div>
                <div class="flex flex-col w-full border-b">
                    <div class="font-semibold">E-mail</div>
                    <div class="text-slate-500">{{ email }}</div>
                </div>
                <div class="flex flex-col w-full border-b">
                    <div class="font-semibold">Adresse</div>
                    <div class="text-slate-500">{{ adresse }}</div>
                </div>
            </div>
            <div class="flex flex-col items-start justify-start gap-y-3">
                <div class="items-center text-left text-sm py-1 font-semibold underline">Fiche d'argent
                </div>
                <div class="flex flex-col w-full border-b">
                    <div class="font-semibold">Montant</div>
                    <div class="text-slate-500">{{ montant + ' ' + unite }}</div>
                </div>
                <div class="flex flex-col w-full border-b">
                    <div class="font-semibold">Condition</div>
                    <div class="text-slate-500">{{ conditionUtil }}</div>
                </div>
                <div class="flex flex-col w-full border-b">
                    <div class="font-semibold">Durée</div>
                    <div class="text-slate-500">{{ duree }}</div>
                </div>
                <div class="flex flex-col w-full border-b">
                    <div class="font-semibold">Taux</div>
                    <div class="text-slate-500">{{ taux }}</div>
                </div>
                <div class="flex flex-col w-full border-b">
                    <div class="font-semibold">Observation</div>
                    <div class="text-slate-500">{{ observation }}</div>
                </div>
            </div>
        </div>
    </Modal>
    <!-- modal sary -->
    <div class="modal_ajout top-0 z-70 right-0 w-screen h-screen flex flex-col justify-center  shadow-sm items-center bg-slate-700 bg-opacity-50" v-show="modal_image">
        <div class="max-h-[90vh] max-w-[90vw]">
            <div class="w-full flex justify-end items-center">
                <div class="fa fa-times text-white" @click="modal_image = false"></div>
            </div>
            <div>
                <img src="" alt="">
            </div>
        </div>
    </div>
    <div>
        <Header2 @data_menu_mobile2="receive_data_menu_mobile"></Header2><br>
        <div class="bg-white h-fit  flex justify-end w-screen items-center rounded py-2 ">
            
                <div class="flex gap-3 max-md:flex-col max-md:items-center max-md:justify-center md:justify-end  md:items-end md:w-8/12 lg:w-5/12">
                    <div class="flex gap-2 sm:gap-5 items-center justify-center w-full max-md:w-11/12">
                        <div class="w-full flex flex-col item-start justify-end gap-3">
                            <div class=" text-start font-semibold text-slate-500">
                                Montant entre
                            </div>
                            <div>
                                <input type="number" class="w-full border border-gray-300 rounded p-1" v-model="inpMont1">
                            </div>
                        </div>
                        <div class="w-full flex flex-col item-start justify-end gap-3">
                            <div class="text-start font-semibold text-slate-500">
                                et
                            </div>
                            <div><input type="number" class="w-full border border-gray-300 rounded p-1" v-model="inpMont2">
                            </div>
                        </div>
                    </div>
                    <div class="flex justify-end items-end w-full">
                        <div class="flex justify-between items-center h-8  max-sm:my-3 max-sm:mx-2  border border-gray-200 rounded w-full">
                            <div><input type="text" placeholder="Recherche ..." class="w-auto focus:outline-none focus:placeholder-transparent placeholder:text-sm px-2 py-1" v-model="inpNom"></div>
                            <div class="  px-2 flex justify-center items-center">
                                <button type="button" class="fa fa-search text-gray-600 "></button>
                            </div>
                        </div>
                        
                            <div class="max-md:mb-2">
                                <Filtre :show_btn_ajout="false" @data_list="data_list_function"></Filtre>
                            </div>
                        
                    </div>
                </div>
            
        </div>
        <div class="w-screen flex justify-center items-center bg-slate-50 p-3">
            <div v-show="!mode_list" class="grid max-md:grid-cols-1 w-full max-lg:grid-cols-2 lg:grid-cols-3 gap-3 ">
                <div class="flex border-l-4 border-red-500  group flex-col justify-between items-center rounded bg-white shadow-gray-300 shadow-sm p-2" v-for="item in filtered_data()" :key="item.idinvest" @click="modal_details = true, nom = item.nom, contact = item.contact, email = item.email,
                                adresse = item.adresse, montant = item.montant, unite = item.unite, conditionUtil = item.conditionUtil, duree = item.duree, taux = item.taux, observation = item.observation, image = item.image">
                    <div class="flex flex-col w-full">
                        <div class="flex w-full gap-x-3">
                            <div class="max-md:w-[60px] md:w-[80px] lg:w-[100px] h-full flex justify-center items-center">
                                <img src="./../../assets/user.png" class="w-10/12" alt="">
                            </div>
                            <div class="w-full flex flex-col gap-y-1">
                                <div class="w-full text-xl font-semibold">{{ item.nom }}</div>
                                <div class="w-full flex justify-between items-center">
                                    <span class="flex justify-start items-center gap-x-3">
                                                        <span class="font-semibold">Durée :</span>
                                    <span class="text-slate-400">{{ item.duree }}</span>
                                    </span>
                                    <span class="font-semibold text-red-500">{{ item.montant }} Ar</span>
                                </div>
                                <div class="w-full flex justify-between items-center">
                                    <span class="flex justify-start items-center gap-x-3">
                                                        <span class="font-semibold">Taux :</span>
                                    <span class="text-slate-400">{{ item.taux }}%</span>
                                    </span>
                                </div>
                                <!-- <div class="flex flex-col text-slate-500 h-fit">
                                                    <div class="w-full">{{ item.observation }}</div>
                                                </div> -->
                            </div>
                        </div>
                    </div>
                    <!-- <div class="flex justify-end items-center text-white w-full">
                                        <div><button class="bg-red-400 hover:bg-red-500  p-2 lg:hidden lg:group-hover:block rounded">Details</button></div>
                                    </div> -->
                </div>
            </div>
            <div v-show="mode_list" class="flex justify-center items-center w-full max-md:px-1 md:px-2">
                <div class="overflow-x-auto  py-1 w-full flex justify-center items-center">
                    <table class="w-full max-w-full divide-y divide-gray-200">
                        <thead class="">
                            <th style="display: none;">Id</th>
                            <th class="md:px-6 md:py-3 bg-gray-100 text-left text-sm leading-4 font-medium text-gray-500  tracking-wider">
                                Nom</th>
                            <th class="md:px-6 md:py-3 bg-gray-100 text-left text-sm leading-4 font-medium text-gray-500  tracking-wider">
                                Contact</th>
                            <th class="md:px-6 md:py-3 bg-gray-100 text-left text-sm leading-4 font-medium text-gray-500  tracking-wider">
                                Montant</th>
                            <th class="md:px-6 md:py-3 bg-gray-100 max-sm:hidden text-left text-sm leading-4 font-medium text-gray-500  tracking-wider">
                                Durée</th>
                            <th class="md:px-6 md:py-3 bg-gray-100 text-left text-sm leading-4 font-medium text-gray-500  tracking-wider">
                            </th>
                        </thead>
                        <tbody class="bg-white divide-y divide-gray-200">
                            <tr v-for="item in filtered_data()" :key="item.idinvest" class="hover:cursor-pointer hover:bg-gray-50" @click="modal_details = true, nom = item.nom, contact = item.contact, email = item.email,
                                adresse = item.adresse, montant = item.montant, unite = item.unite, conditionUtil = item.conditionUtil, duree = item.duree, taux = item.taux, observation = item.observation, image = item.image">
                                <td style="display: none;">{{ item.idinvest }}</td>
                                <td class="md:px-6 sm:px-3 sm:py-2 md:py-4 text-sm whitespace-no-wrap">{{ item.nom }}
                                </td>
                                <td class="md:px-6 sm:px-3 sm:py-2 md:py-4 text-sm whitespace-no-wrap">{{ item.contact }}
                                </td>
                                <td style="display: none;">{{ item.email }}</td>
                                <td style="display: none;">{{ item.adresse }}</td>
                                <td class="md:px-6 sm:px-3 sm:py-2 md:py-4 text-sm whitespace-no-wrap">{{ item.montant }}
                                </td>
                                <td style="display: none;">{{ item.unite }}</td>
                                <td style="display: none;">{{ item.conditionUtil }}</td>
                                <td class="md:px-6 sm:px-3 sm:py-2 md:py-4 text-sm  whitespace-no-wrap max-sm:hidden">{{ item.duree }}</td>
                                <td style="display: none;">{{ item.taux }}</td>
                                <td style="display: none;">{{ item.observation }}</td>
                                <td class="md:px-6 sm:px-3 max-md:py-2 md:py-4   whitespace-no-wrap">
                                    <div @click="modal_details = true" class="fa fa-eye text-gray-500 active:bg-gray-200">
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div><br><br><br><br>
    <div v-show="investisseurs[0]==null" class="h-[35vh]"></div>
    <Footer></Footer>
</template>
<style>
    .modal-details {
        position: absolute;
        z-index: 4;
    }
    *:not(.modal-details) {
        position: relative;
        z-index: 1;
    }
    .v-enter-from,
    .v-leave-to {
        transform: translateX(1000px);
    }
    .v-enter-active,
    .v-leave-active {
        transition: transform 0.5s;
    }
</style>

<template lang="">
    <div class="modal_ajout w-screen h-screen z-[2] top-o right-0 bg-slate-900 bg-opacity-30" v-show="modal_menu" @click="close">
    </div>
    <Transition>
        <div class="modal_ajout top-0 left-0 h-[100vh] w-[30vw] z-[4] bg-white p-4 shadow-sm shadow-gray-300" v-show="modal_menu">
            <div class="w-full bg-red-50 p-1 rounded flex justify-between items-center">
                <div class=""><img src="../../../assets/serra2.png" alt="" class="  max-lg:w-24 lg:w-20 m-2"></div>
                
            </div>
            <div>
                <ul v-for="(item, index) in menu_item" :key="index" class="grid items-center">
                    <li class="hover:text-blue-700 hover:translate-x-2 hover:bg-blue-100 transition-all p-1 py-1 px-4 rounded my-1 mr-2">
                        <router-link :to="item.link">
                            <div class="inline-grid grid-flow-col gap-x-2 justify-center items-center">
                                <div :class="item.logo" class="text-gray-900"></div>
                                <div class="text-gray-900">{{ item.title }}</div>
                            </div>
                        </router-link>
                    </li>
                </ul>
            </div>
        </div>
    </Transition>
</template>
<style>
    .v-enter-active,
    .v-leave-active {
        transition: transform 0.3s;
    }
    .v-enter-from,
    .v-leave-to {
        transform: translateX(-500px)
    }
</style>
<script>
export default {
    props:{
        modal_menu:{
            type:Boolean,
            required:true
        }
    },
    data(){
        return {
            menu_item: [{
                        title: 'Tableau de board',
                        link: '/Admin/Dashboard',
                        logo: 'fa fa-house '
                    },
                    {
                        title: 'Comparaison',
                        link: '/Admin/Comparaison',
                        logo: 'fas fa-chart-line'
                    },
                    {
                        title: 'Categorie',
                        link: '/Admin/Categorie',
                        logo: 'fa fa-list-check '
                    },
                    {
                        title: 'Utilisateur',
                        link: '/Admin/Utilisateur',
                        logo: 'fa fa-users '
                    },
                    {
                        title: 'Abonnement',
                        link: '/Admin/subscription',
                        logo: 'fa fa-dollar '
                    },
                    {
                        title: 'Client',
                        link: '/Admin/Client',
                        logo: 'fa fa-user'
                    },
                    {
                        title: 'Fournisseur',
                        link: '/Admin/Fournisseur',
                        logo: 'fa fa-house '
                    },
                    {
                        title: 'Investisseur',
                        link: '/Admin/Investisseur',
                        logo: 'fa fa-money-check '
                    }, {
                        title: 'Partenaire',
                        link: '/Admin/partenaire',
                        logo: 'fas fa-handshake '
                    },
                    {
                        title: 'Message',
                        link: '/Admin/Message',
                        logo: 'fas fa-message '
                    },
                ],
        }},
    methods:{
        close(){
            this.$emit('close')
        }
    }
}
</script>
<style lang="">
    
</style>
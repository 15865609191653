<template>
    <div ref="modal_menu" class="bg-opacity-0 text-white  modal_menu w-screen bg-white  max-md:px-5 md:px-9 py-2">
        <div class="flex items-center justify-between">
            <div>
                <router-link to="/">
                    <img src="./../../assets/serra2.png" class="max-sm:w-20 sm:w-[10vw]">
                </router-link>
            </div>
            <!---->
            <div class=" flex justify-end items-center gap-x-7 ">
                <div class="max-lg:hidden  flex scroll-smooth transition-all duration-100 justify-center  items-center gap-5 ">
                    <a v-for="(section, index) in sections" :key="index" class="hover:text-red-500 " :href="`/#${section.id}`">{{
                        section.title }}</a>
                </div>
                <router-link to="/signin" class="py-1 max-lg:hidden px-4 rounded bg-red-400 text-white hover:bg-red-500   ">
                    Se connecter
                </router-link>
                <div class="lg:hidden">
                    <button ref="btn_menu" class=" fas fa-bars text-xl   p-2" @click="toggle()"></button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import $ from 'jquery'
    export default {
        name: 'Header',
        props: {
            modal_menu: {
                type: Boolean,
                required: true
            }
        },
        data() {
            return {
                sections: [{
                        id: 'Accueil',
                        title: 'Accueil'
                    },
                    {
                        id: 'Service',
                        title: 'Service'
                    },
                    {
                        id: 'About',
                        title: 'A propos'
                    },
                    {
                        id: 'Pricing',
                        title: 'Nos offres'
                    },
                    {
                        id: 'Contact',
                        title: 'Contact'
                    },
                ],
                position: {
                    x: 0,
                    y: 0
                }
            }
        },
        mounted() {
            // Add scroll event listener to the window
            const modal_menu = $('.modal_menu')
            // eslint-disable-next-line no-unused-vars
            
            window.addEventListener("scroll", () => {
                // Get the current scroll position
                const scrollY = window.scrollY;
                // Check if the scroll has reached the bottom
                if (scrollY < 70) {
                    // Add 'bg-opacity-0' class to the element with 'modal_menu' ref
                    if (modal_menu.hasClass('shadow')) {
                        modal_menu.removeClass('shadow');
                    }
                    if (modal_menu.hasClass('shadow-slate-400')) {
                        modal_menu.removeClass('shadow-slate-400');
                    }
                    modal_menu.addClass('bg-opacity-0');
                    modal_menu.addClass('text-white');
                    modal_menu.addClass('border-white');
                    modal_menu.addClass('shadow-transparent');
                } else {
                    if (modal_menu.hasClass('text-white')) {
                        modal_menu.removeClass('text-white');
                    }
                    if (modal_menu.hasClass('border-white')) {
                        modal_menu.removeClass('border-white');
                    }
                    if (modal_menu.hasClass('shadow-transparent')) {
                        modal_menu.removeClass('shadow-transparent');
                    }
                    if (modal_menu.hasClass('bg-opacity-0')) {
                        modal_menu.removeClass('bg-opacity-0');
                    }
                    modal_menu.addClass('shadow');
                    modal_menu.addClass('shadow-slate-400');
                }
            });
        },
        methods: {
            toggle() {
                this.$emit('toggle')
            }
        }
    }
</script>
<style>
    * {
        transition: all 150ms ease;
        scroll-behavior: smooth;
    }
    .img {
        background-image: url('./../../assets/header_av.png');
        background-repeat: no-repeat;
        background-size: cover;
        background-attachment: fixed;
    }
    .text-div {
        position: relative;
        top: 0;
        right: 0;
        z-index: 5;
    }
</style>
<template>
    <Menu :modal_menu="modal_menu" @close="modal_menu=false">
    </Menu>
    <Header2 @data_menu_mobile2="receive_data_menu_mobile"></Header2>
    <Modal :isOpen="modal_details" @close="modal_details=false">
        <h3 class="font-bold text-xl w-full text-left">{{ nom }}</h3>
        <div class="text-sm w-full grid   md:grid-cols-2 items-start gap-3">
            <div class="flex flex-col items-start justify-start gap-y-3">
                <div class="items-center text-left text-sm py-1 underline font-semibold">Fiche du client</div>
                <div class="flex flex-col w-full border-b">
                    <div class=" font-semibold">Contact</div>
                    <div class="text-slate-500">{{ contact }}</div>
                </div>
                <div class="flex flex-col w-full border-b">
                    <div class="font-semibold">E-mail</div>
                    <div class="text-slate-500">{{ email }}</div>
                </div>
                <div class="flex flex-col w-full border-b">
                    <div class="font-semibold">Adresse</div>
                    <div class="text-slate-500">{{ adresse }}</div>
                </div>
                <div class="flex flex-col w-full border-b">
                    <div class="font-semibold">Lieu</div>
                    <div class="text-slate-500">{{ lieu }}</div>
                </div>
                <div class="flex flex-col w-full border-b">
                    <div class="font-semibold">Besoin</div>
                    <div class="text-slate-500">{{ besoin }}</div>
                </div>
            </div>
            <div class="flex flex-col items-start justify-start gap-y-3">
                <div class="items-center text-left text-sm py-1 font-semibold underline">Fiche du produit
                </div>
                <div class="flex flex-col w-full border-b">
                    <div class="font-semibold">Produit demandé</div>
                    <div class="text-slate-500">{{ prodDemande }}</div>
                </div>
                <div class="flex flex-col w-full border-b">
                    <div class="font-semibold">Quantité</div>
                    <div class="text-slate-500">{{ quantite }} {{ unite }}</div>
                </div>
                <div class="flex flex-col w-full border-b">
                    <div class="font-semibold">Prix unitaire</div>
                    <div class="text-slate-500">{{ prixUnitaire }} Ar</div>
                </div>
                <div class="flex flex-col w-full border-b">
                    <div class="font-semibold">Description</div>
                    <div class="text-slate-500">{{ description }}</div>
                </div>
                <div class="flex flex-col w-full border-b">
                    <div class="font-semibold">Catégorie</div>
                    <div class="text-slate-500">{{ nomcat }}</div>
                </div>
            </div>
        </div>
    </Modal>
    <div class="">
        <br>
        <div class="w-screen  flex justify-center items-end">
            <div class="flex  justify-end items-end w-full px-5">
                <div class="flex max-md:flex-col gap-y-2 gap-x-4 w-full max-md:items-end max-md:justify-center md:justify-end  md:items-end">
                    <div class="flex max-md:flex-col max-md:items-end max-md:justify-center md:justify-end md:items-center gap-x-4">
                        <div class="flex gap-x-3 flex-col  justify-start  items-end max-md:w-full">
                            <div class="w-full text-start text-slate-500 font-semibold">
                                Categorie
                            </div>
                            <div class="max-md:w-full">
                                <select class="border border-gray-300 w-full h-8 max-md:w-full my-2 rounded bg-gray-100 p-1" @change="(e)=>{inpCat=e.target.value}" v-model="inpCat">
                                            <option value="">Tous</option>
                                                    <option v-for="categorie in categories" :key="categorie.nomcat"
                                                        :value="categorie.nomcat">{{ categorie.nomcat }}</option>
                                                </select>
                            </div>
                        </div>
                        <div class="flex justify-center items-end max-md:gap-2 md:gap-5 ">
                            <div class="w-full flex flex-col justify-end items-start">
                                <div class="w-full text-start text-slate-500 font-semibold">
                                    Prix entre
                                </div>
                                <div>
                                    <input type="number" class="w-full border border-gray-300 rounded h-8 p-1" v-model="inpPrix1">
                                </div>
                            </div>
                            <div class="w-full flex flex-col items-start justify-end">
                                <div class="flex justify-center items-center text-slate-500 font-semibold">
                                et
                            </div>
                            <div>
                                <input type="number" class="w-full border border-gray-300 h-8 rounded p-1" v-model="inpPrix2">
                            </div>
                            </div>
                        </div>
                    </div>
                    <!-- init div -->
                    <div class="flex justify-end items-center max-md:gap-x-3 max-md:w-full md:mb-2">
                        <div class="flex items-center    justify-between max-md:w-full   border border-gray-200 rounded ">
                            
                                <input type="text" placeholder="Produit demandée ..." class="p-1 w-auto focus:outline-none max-md:w-full  focus:placeholder-transparent" v-model="inpProduit">
                            
                            <div class="hover:bg-gray-200 h-8  flex justify-center items-center active:bg-gray-300">
                                <button type="button" class="fa fa-search text-xl text-gray-600 p-1"></button>
                            </div>
                        </div>
                        <div>
                            <Filtre @data_filter="receive_data_filter" :show_btn_ajout="false" @data_list="data_list_function"></Filtre>
                        </div>
                    </div>
                </div>
            </div>
        </div><br>
        <div class="w-screen flex  justify-center items-center px-3 bg-slate-50">
            <div v-show="!mode_list" class="grid  sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-3 md:m-1 overflow-hidden w-screen max-md:py-2 md:p-2 mr-2">
                <div class="flex border-l-4 border-red-500   group flex-col justify-between items-center rounded bg-white shadow-gray-300 shadow-sm p-2" v-for="client in filtered_data()" :key="client.idcli" @click="modal_details = true, nom = client.nom, contact = client.contact, email = client.email,
                                adresse = client.adresse, besoin = client.besoin, prodDemande = client.prodDemande, prixUnitaire = client.prixUnitaire, quantite = client.quantite,unite = client.unite, nomcat = client.nomcat, lieu = client.lieu, description = client.description">
                    <div class="flex flex-col w-full">
                        <div class="flex w-full gap-x-3">
                            <div class="max-md:w-[60px] md:w-[80px] lg:w-[100px] h-full flex justify-center items-center">
                                <img src="./../../assets/user.png" class="w-10/12" alt="">
                            </div>
                            <div class="w-full flex flex-col gap-y-2">
                                <div class="w-full text-lg font-bold overflow-y-hidden">{{ client.nom }}</div>
                                <div class="flex flex-col  h-fit">
                                    <div class="w-full flex justify-between items-center">
                                        <div class="w-full overflow-y-hidden flex justify-start items-center gap-x-3">
                                            <span class='font-semibold'>Produit :</span>
                                            <span class='text-slate-500'>{{ client.prodDemande }}</span>
                                        </div>
                                        <span class="mr-2 text-red-500 font-bold ">{{ client.prixUnitaire }}Ar</span>
                                    </div>
                                    <div class="w-full flex justify-between items-center">
                                        <div class="w-full overflow-y-hidden flex justify-start items-center gap-x-3">
                                            <span class='font-semibold'>Categorie:</span>
                                            <span class='text-slate-500'>{{ client.nomcat }}</span>
                                        </div>
                                        <div class="w-full overflow-y-hidden flex justify-end items-center gap-x-3">
                                            <span class='font-semibold'>Quantité :</span>
                                            <span class='text-slate-500'>{{ client.quantite + ' ' + client.unite
                                                                }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-show="mode_list" class="w-full flex justify-center items-center">
                <div class="overflow-x-auto w-full flex justify-center items-center  bg-white shadow-sm shadow-gray-300 max-md:m-2  md:m-4">
                    <table class="w-full  ">
                        <thead class="">
                            <th class="md:py-3 pl-2 bg-gray-100 text-left max-md:text-sm leading-4 font-semibold text-gray-500  tracking-wider">
                                Nom</th>
                            <th class="md:py-3 bg-gray-100 text-left max-md:text-sm leading-4 font-semibold text-gray-500  tracking-wider">
                                Contact</th>
                            <th class="md:py-3 bg-gray-100 text-left max-md:text-sm leading-4 font-semibold text-gray-500  tracking-wider">
                                Produit demandé</th>
                            <th class="md:py-3 bg-gray-100 text-left max-md:text-sm leading-4 font-semibold text-gray-500  tracking-wider max-sm:hidden">
                                Prix Unitaire(AR)</th>
                            <th class="md:py-3 bg-gray-100 text-left max-md:text-sm leading-4 font-semibold text-gray-500  tracking-wider">
                            </th>
                        </thead>
                        <tbody class="bg-white ">
                            <tr v-for="client in filtered_data()" :key="client.idcli" class="hover:cursor-pointer border-y   hover:bg-gray-50 " @click="modal_details = true, idcli = client.idcli, nom = client.nom, contact = client.contact, email = client.email,
                                adresse = client.adresse, besoin = client.besoin, prodDemande = client.prodDemande, prixUnitaire = client.prixUnitaire, quantite = client.quantite, unite = client.unite, lieu = client.lieu, nomcat = client.nomcat, description = client.description">
                                <td class="  max-md:py-3 pl-2 md:py-4 max-md:text-sm  whitespace-no-wrap">{{ client.nom }}
                                </td>
                                <td class=" sm:py-2 md:py-4 max-md:text-sm  whitespace-no-wrap">{{ client.contact }}
                                </td>
                                <td style="display: none;">{{ client.email }}</td>
                                <td style="display: none;">{{ client.adresse }}</td>
                                <td class=" sm:py-2 md:py-4 max-md:text-sm max-md:px-2  whitespace-no-wrap">
                                    {{ client.prodDemande }}</td>
                                <td style="display: none;">{{ client.quantite }}</td>
                                <td style="display: none;">{{ client.unite }}</td>
                                <td class=" sm:py-2 md:py-4 max-md:text-sm  whitespace-no-wrap max-sm:hidden">{{ client.prixUnitaire }}</td>
                                <td style="display: none;">{{ client.description }}</td>
                                <td style="display: none;">{{ client.lieu }}</td>
                                <td style="display: none;">{{ client.besoin }}</td>
                                <td style="display: none;">{{ client.nomcat }}</td>
                                <td class=" sm:py-2 md:py-4  whitespace-no-wrap pr-2">
                                    <div @click="modal_details = true" class="fa fa-eye text-gray-500 active:bg-gray-200">
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <Footer></Footer>
    </div>
</template>
<style>
    *:not(.modal_details) {
        position: relative;
        z-index: 1;
    }
</style>
<script>
    import axios from 'axios'
    import apiUrl from '../../ApiConfig';
    import Footer from './../../components/Client/Footer.vue'
    //import Pdf from './../../components/Client/Pdf.vue'
    //import Excel from './../../components/Client/Excel.vue'
    import Header2 from './../../components/Client/Header2.vue'
    import Filtre from './../../components/Client/Filtre.vue'
    import Menu from '@/components/Client/menu/Menu.vue'
    import {
        Transition
    } from 'vue';
    import Modal from '@/components/modal/modal.vue';
    export default {
        // eslint-disable-next-line vue/multi-word-component-names
        name: 'Client',
        components: {
            Footer,
            Header2,
            Filtre,
            Modal,
            Menu
        },
        data() {
            return {
                sections: [{
                        link: '/home',
                        title: 'Accueil'
                    },
                    {
                        link: '/customer',
                        title: 'Client'
                    },
                    {
                        link: '/supplier',
                        title: 'Fournisseur'
                    },
                    {
                        link: '/investor',
                        title: 'Investisseur'
                    },
                    {
                        link: '/comparison',
                        title: 'Comparaison'
                    },
                ],
                modal_menu: false,
                inpPrix1: 0,
                inpPrix2: 0,
                inpProduit: '',
                inpCat: '',
                inp_sel_prix: '',
                inp_search: '',
                mode_list: localStorage.getItem('seera_list_mode') !== null ? JSON.parse(localStorage.getItem('seera_list_mode')) : true,
                modal_details: false,
                clients: [],
                categories: [],
                nom: '',
                idcli: '',
                contact: '',
                email: '',
                adresse: '',
                type: '',
                prodDemande: '',
                quantite: '',
                unite: '',
                prixUnitaire: '',
                description: '',
                lieu: '',
                besoin: '',
                nomcat: '',
                province: localStorage.getItem('province'),
                region: localStorage.getItem('region')
            }
        },
        mounted() {
            this.getClients();
            this.categorieSelection();
        },
        methods: {
            receive_data_menu_mobile(data) {
                this.modal_menu = data
            },
            getClients() {
                const typeAbo = localStorage.getItem('typeAbo');
                // const province = localStorage.getItem('province');
                if (typeAbo === 'standard')
                    try {
                        axios.get(apiUrl + 'api/affichage_client_seera_standard', {
                                params: {
                                    province: this.province
                                }
                            })
                            .then(response => {
                                this.clients = response.data
                                // eslint-disable-next-line no-undef
                            }).catch(error => {
                                console.log(error)
                                this.clients = []
                            })
                    } catch (error) {
                        console.error(error);
                    }
                else if (typeAbo === 'basic') {
                    try {
                        axios.get(apiUrl + 'api/affichage_client_seera_basic', {
                                params: {
                                    region: this.region
                                }
                            })
                            .then(response => {
                                this.clients = response.data
                                // eslint-disable-next-line no-undef
                            }).catch(error => {
                                console.log(error)
                                this.clients = []
                            })
                    } catch (error) {
                        console.error(error);
                    }
                } else {
                    try {
                        axios.get(apiUrl + 'api/affichage_client_seera')
                            .then(response => {
                                this.clients = response.data
                                // eslint-disable-next-line no-undef
                            }).catch(error => {
                                console.log(error)
                                this.clients = []
                            })
                    } catch (error) {
                        console.error(error);
                    }
                }
            },
            categorieSelection() {
                try {
                    axios.get(apiUrl + 'api/affichage_nom_categorie').then(response => {
                        this.categories = response.data
                    }).catch(error => {
                        console.log(error)
                    })
                } catch (error) {
                    console.error(error);
                }
            },
            rechercheClient() {
                try {
                    axios.get(apiUrl + 'api/recherche_client_seera', {
                        params: {
                            inpProduit: this.inpProduit,
                            inpPrix1: this.inpPrix1,
                            inpPrix2: this.inpPrix2,
                            inpCat: this.inpCat
                        }
                    }).then(response => {
                        this.clients = response.data
                    }).catch(error => {
                        console.log(error)
                    })
                } catch (error) {
                    console.error(error);
                }
            },
            receive_data_filter(props1, props2, props3, props4) {
                this.inp_prix = props1
                this.inp_sel_categorie = props2
                this.inp_sel_prix = props3
                this.inp_search = props4
            },
            data_list_function(data) {
                this.mode_list = data
            },
            modal_details_show() {
                this.modal_details = !this.modal_details
            },
            receive_data_menu_mobile(data) {
                this.modal_menu = data
            },
            convert_verification(data) {
                if (data == 'vrai') {
                    return true
                }
                if (data == 'faux') {
                    return false
                }
            },
            filtered_data() {
                return this.clients.filter(item => (this.inpCat != '' ? item.nomcat.toLowerCase().includes(this.inpCat.toLowerCase()) : item))
                    .filter(item => (this.inpProduit != '' ? item.prodDemande.toLowerCase().includes(this.inpProduit.toLowerCase()) : item))
                    .filter(item => (this.inpPrix != 0 ? (this.inpPrix1 <= item.prixUnitaire) : item))
                    .filter(item => (this.inpPrix2 != 0 ? (this.inpPrix2 >= item.prixUnitaire) : item))
            },
        }
    }
</script>
<style>
    .modal_details_client {
        position: fixed;
        top: 0;
        right: 0;
        z-index: 5;
    }
    .v-enter-from,
    .v-leave-to {
        transform: translateX(1000px);
    }
    .v-enter-active,
    .v-leave-active {
        transition: transform 0.5s;
    }
</style>
<template lang="">
  <div ref="myDiv" id="Contact" class="flex flex-col justify-center items-center ">
    <div class="flex flex-col items-center justify-center">
      <div class="font-bold text-2xl text-center">
        <h1>Contact</h1>
      </div><br>
      <div class="w-screen flex justify-center items-start ">
        <p class="text-slate-500  text-center w-11/12">
          N'hésitez pas à nous contacter pour toute question, demande d'information ou assistance. Nous sommes là pour vous aide.
        </p>
      </div>
    </div>
    <div class=" my-5   md:w-[95vw]  h-fit md:flex lg:flex sm:grid sm:grid-cols-1 gap-x-5 gap-y-5  p-4 max-md:justify-center max-md:items-center max-md:w-full  md:justify-between text-start">
      <div class="md:w-4/12 lg:w-4/12 sm:w-full flex flex-col gap-y-5 gap-x-2">
        <div class=" my-5   w-[95vw]  h-fit md:flex lg:flex sm:grid sm:grid-cols-1 gap-x-5 gap-y-5  p-4 justify-between text-start">
          <div class="md:w-4/12 lg:w-4/12 sm:w-full flex flex-col gap-y-5 gap-x-2">
            <div class="flex gap-x-4">
              <div class=" flex justify-start items-center">
                <span class="fas fa-location-dot p-3 bg-red-400 rounded text-white"></span>
              </div>
              <div class="text-left">
                <div>
                  <h1 class="font-bold">Adresse:</h1>
                </div>
                <div>
                  <p class="text-gray-600 sm:text-sm: md:text-xs lg:text-sm">Lot VK 17 A Fenomanana Mahazoarivo Antananarivo
                  </p>
                </div>
              </div>
            </div>
            <div class="flex gap-x-4">
              <div class="flex justify-start items-center">
                <div class="fas fa-envelope p-3 bg-red-400 rounded text-white"></div>
              </div>
              <div class="text-left">
                <div>
                  <h1 class="font-bold">Email:</h1>
                </div>
                <div>
                  <p class="text-gray-600 sm:text-sm: md:text-xs lg:text-sm">info@haisoa.com</p>
                </div>
              </div>
            </div>
            <div class="flex gap-x-4">
              <div class="flex justify-start items-center">
                <div class="fas fa-tablet p-3 bg-red-400 rounded text-white"></div>
              </div>
              <div class="text-left">
                <div>
                  <h1 class="font-bold">Téléphone:</h1>
                </div>
                <div>
                  <p class="text-gray-600 sm:text-sm: md:text-xs lg:text-sm">+261389875472 </p>
                </div>
              </div>
            </div>
          </div>
          <div class="h-fit lg:w-8/12 md:w-8/12 max-md:w-full max-md:py-3 sm:w-full flex justify-start items-start">
            <form class="w-full max-md:py-4" @submit.prevent="envoyerMessage()">
              <div class="flex flex-col  gap-5">
                <div>
                  <input type="text" class="border border-gray-300 w-full p-1 rounded" placeholder="Objet" v-model="objet" required>
                </div>
                <div class="h-fit">
                  <textarea class="border border-gray-300 w-full rounded" rows="5" placeholder="Votre message" v-model="message" required></textarea>
                  <p class="hidden">{{ iduser }}</p>
                  <p class="hidden">{{ nomUser }}</p>
                  <p class="hidden">{{ numeroUser}}</p>
                  <p class="hidden">{{email}}</p>
                </div>
              </div>
              <div class="flex justify-center items-center max-md:w-full">
                <div class="max-md:w-full">
                  <button type="submit" class="py-2 px-5 my-4 max-md:w-full text-white bg-red-400 hover:bg-red-500 rounded flex justify-center items-center">
                            <span class="fa fa-spinner animate-spin" v-if="load.message"></span>
                            <span class="" v-else>Envoyer le message</span>
                          </button></div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <br class="md:hidden">
    </div>
  </div>
  <!-- feedback success -->
  <Feedback :isOpen="feedback.success.isOpen" @close="feedback.success.isOpen=false">
    <div class="flex justify-start items-center gap-x-4">
      <span class="fa fa-check text-xl font-bold text-white bg-green-600 rounded-full p-3"></span>
      <span class="text-slate-600">{{feedback.success.text}}</span>
    </div>
  </Feedback>
  <!-- feedback failure -->
  <Feedback :isOpen="feedback.fails.isOpen" @close="feedback.fails.isOpen=false">
    <div class="flex justify-start items-center gap-x-4">
      <span class="fa fa-times text-xl font-bold text-white bg-red-600 rounded-full px-3 py-2"></span>
      <span class="text-slate-600">{{feedback.fails.text}}</span>
    </div>
  </Feedback>
</template>
<script>
  import apiUrl from '../../ApiConfig';
  import axios from 'axios'
  import Feedback from '../modal/Feedback.vue';
  export default {
    components: {
      Feedback
    },
    data() {
      return {
        nomUser: '',
        numeroUser: '',
        email: '',
        objet: '',
        message: '',
        modalValidation: false,
        iduser: localStorage.getItem('iduser'),
        load: {
          message: false
        },
        feedback: {
          success: {
            isOpen: false,
            text: "Votre message a été bien envoyé !"
          },
          fails: {
            isOpen: false,
            text: "L'envoy du message a échoué !"
          }
        }
      }
    },
    methods: {
      envoyerMessage() {
        this.load.message = true
        try {
          const formData = new FormData();
          formData.append('nom', this.nomUser);
          formData.append('email', this.email);
          formData.append('numero', this.numeroUser);
          formData.append('objet', this.objet);
          formData.append('message', this.message);
          // eslint-disable-next-line no-unused-vars
          axios.post(apiUrl + 'api/envoyer_message', formData)
            .then(response => {
               this.message = '';
              this.objet = '';
              console.log('Message envoyer avec succès');
              this.load.message = false
              this.feedback.success.isOpen = true
              setTimeout(() => {
                this.feedback.success.isOpen = false
              }, 2500)
            })
            .catch(error => {
              console.log(error)
              this.feedback.fails.isOpen = true
              setTimeout(() => {
                this.feedback.fails.isOpen = false
              }, 2500)
            });
        } catch (error) {
          console.error(error)
          this.feedback.fails.isOpen = true
          setTimeout(() => {
            this.feedback.fails.isOpen = false
          }, 2500)
        }
      },
      getUserNameMessage() {
        axios.get(apiUrl + 'api/user_name_message', {
          idUser: this.iduser
        }).then(response => {
          this.nomUser = response.data[0].name;
          // console.log(response.data[0].name);
          this.numeroUser = response.data[0].contact;
          this.email = response.data[0].email;
          // console.log(response.data[0])
        }).catch(error => {
          console.log(error)
        })
      },
    },
    mounted() {
      // this.iduser = localStorage.getItem('iduser');
      this.getUserNameMessage();
    },
  }
</script>
<style lang="">

</style>
<template lang="">
  <div id="Partenaire" class="flex flex-col w-screen justify-center items-center gap-7 bg-slate-50 py-4">
    <div class="flex flex-col items-center justify-center ">
      <div class="px-12 w-full">
        <h1 class="text-xl font-bold text-center">Partenaires</h1><br>
        <p class="text-slate-500 text-center  w-full ">
          Nous sommes fiers de collaborer avec une multitude de partenaires engagés, qui renforcent notre capacité à offrir des solutions complètes et de qualité.
        </p>
      </div>
      <div class="max-w-11/12  w-11/12 flex flex-wrap justify-center items-center   max-md:flex-col gap-7 my-4  ">
        <div v-for="partenaire in partenaires" :key="partenaire.id" class="shadow-sm hover:scale-105  hover:shadow-red-500/50 transition-all duration-200 md:w-[200px] h-[200px] shadow-gray-300 bg-white rounded py-2 max-md:w-full p-5 flex flex-col gap-y-1  justify-center items-center ">
          <div class="">
            <a href="#">
              <img :src="getImageUrl(partenaire.logo)" class=" max-h-[200px]" alt="">
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import axios from "axios"
  import logos from "./../../assets/HAISOA.jpeg"
  import apiUrl from "@/ApiConfig"
  export default {
    data() {
      return {
        partenaires: [],
        nom: '', logo: '', lien: '',
        data: [{
            id: 1,
            label: "HAISOA",
            link: "http://",
            logo: logos
          },
          {
            id: 1,
            label: "HAISOA",
            link: "http://",
            logo: logos
          }, {
            id: 1,
            label: "HAISOA",
            link: "http://",
            logo: logos
          }, {
            id: 1,
            label: "HAISOA",
            link: "http://",
            logo: logos
          }, {
            id: 1,
            label: "HAISOA",
            link: "http://",
            logo: logos
          }, {
            id: 1,
            label: "HAISOA",
            link: "http://",
            logo: logos
          }, {
            id: 1,
            label: "HAISOA",
            link: "http://",
            logo: logos
          }
        ]
      }
    },
    mounted(){
      this.fetchData()
    },
    methods: {
      fetchData() {
        try {
          axios.get(apiUrl + "api/affichage_partenaire")
            .then(response => {
              this.partenaires = response.data
            })
            .catch(error => {
              console.error(error)
            })
        } catch (error) {
          console.error(error)
        }
      },

      getImageUrl(refImage) {
                // Utilisez la fonction asset() de Laravel pour générer l'URL de l'image
                return apiUrl + `images/partenaire/${refImage}`;
            },
    }
  }
</script>
<style lang="">

</style>
<template lang="">
    <div v-if="iduser==''||iduser==undefined">
        <Header :modal_menu="modal_menu" @toggle="modal_menu=!modal_menu"></Header>
        <br/>
        <br/>
        
    </div>
    <Header2 @data_menu_mobile2="receive_data_menu_mobile" v-else>
    </Header2>
    <br/>
    <br/>
    <div class="w-screen flex justify-center items-start ">
    <div class="w-11/12 flex flex-col gap-y-3">
        <Dropdown :title="'khakjshdkja'">
            <p class="text-slate-600">kajgshdkjhaksd</p>
        </Dropdown>
        <Dropdown :title="'khakjshdkja'">
            <p class="text-slate-600">kajgshdkjhaksd</p>
        </Dropdown>
        <Dropdown :title="'khakjshdkja'">
            <p class="text-slate-600">kajgshdkjhaksd</p>
        </Dropdown>
        <Dropdown :title="'khakjshdkja'">
            <p class="text-slate-600">kajgshdkjhaksd</p>
        </Dropdown>
        <Dropdown :title="'khakjshdkja'">
            <p class="text-slate-600">kajgshdkjhaksd</p>
        </Dropdown>
    </div>
</div>
    <Footers></Footers>
    <Menu_landing :modal_menu="modal_menu" @close="modal_menu=false" v-if="iduser==''||iduser==undefined"></Menu_landing>
    <Menu :modal_menu="modal_menu" @close="modal_menu=false" v-else></Menu>
</template>
<script>
    import Dropdown from "@/components/Dropdown/Dropdown.vue"
    import Footers from '@/components/Client/Footer.vue'
    import Header2 from '@/components/Client/Header2.vue'
    import Menu from '@/components/Client/menu/Menu.vue'
    import Footer from '@/components/Client/Footer.vue'
    import Menu_landing from '@/components/Client/menu/Menu_lainding.vue'
    import Header from '@/components/Client/Header_all.vue';
    export default {
        components: {
            Dropdown,
            Footer,
            Header2,
            Footers,
            Menu,
            Menu_landing,
            Header
        },
        data() {
            return {
                modal_menu: false,
                iduser: undefined,
            }
        },
        mounted() {
            this.iduser = localStorage.getItem('iduser')
        },
        methods: {
            receive_data_menu_mobile(data) {
                this.modal_menu = data
            },
        }
    }
</script>
<style lang="">

</style>
<template>
    <div>
      <canvas id="planet-chart"></canvas>
    </div>
  </template>
  
  <script>
  import Chart from 'chart.js'
  
  export default {
    name: 'Graph'
  }
  </script>
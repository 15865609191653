<template>
    <div class="gray w-screen max-lg:w-screen lg:w-10/12  float-right h-screen flexbox  justify-center ">
        <div class="bg-white max-sm:p-2 sm:h-fit mx-4 flex items-center rounded mb-3">
            <div class="flex p-2 justify-center items-center lg:hidden">
                <div class="fas fa-bars fa-2x text-gray-600 cursor-pointer" @click="modal_menu = !modal_menu"></div>
            </div>
            <Header :name_page="name_page" class="p-2"></Header>
        </div>
        <div class="bg-white h-fit mx-4 grid items-center rounded py-1">
            <div class="flex w-full justify-between items-center p-2">
                <div class=" flex max-sm:justify-start justify-center items-center ">
                    <Filtre @modal_ajout_show="data_modal_ajout"></Filtre>
                </div>
                <div class="flex items-center w-9/12 justify-end gap-x-3 ">
                    <label for="">Montant entre</label>
                    <input type="number" class="border rounded w-full p-1" v-model="inpMont1">
                    <span class="">et</span>
                    <input type="number" class="border rounded w-full p-1" name="" id="" v-model="inpMont2">
                    <div class="flex w-10/12 items-center h-fit justify-center border rounded border-gray-400">
                        <input type="text" name="" id="" v-model="inpDuree" placeholder="Duree ..." class="p-1 focus:outline-none w-full placeholder:text-gray-300">
                        <button class="fa fa-search p-1" @click="rechercheInvestisseur()"></button>
                    </div>
                </div>
            </div>
        </div>
        <div class="bg-white h-fit mx-4 flex items-center justify-center rounded my-3 ">
            <div class="w-full max-w-full overflow-x-auto p-3">
                <table class="w-full  max-w-full ">
                    <thead class="text-left font-semibold bg-blue-50">
                        <th class="hidden">Idinvest</th>
                        <th>Nom</th>
                        <th>Contact</th>
                        <th>Adresse</th>
                        <th>Email</th>
                        <th>Montant</th>
                        <th class="hidden">Unité</th>
                        <th class="hidden">Conddition</th>
                        <th class="hidden">Durée</th>
                        <th class="hidden">Taux</th>
                        <th>Observation</th>
                        <th></th>
                    </thead>
                    <tbody v-if="investisseurs.length > 0" class="divide-y">
                        <tr v-for="investisseur in investisseurs" :key="investisseur.idinvest" class="text text-left  ">
                            <td class="hidden">{{ investisseur.idinvest }}</td>
                            <td>{{ investisseur.nom }}</td>
                            <td>{{ investisseur.contact }}</td>
                            <td>{{ investisseur.adresse }}</td>
                            <td>{{ investisseur.email }}</td>
                            <td>{{ investisseur.montant }}</td>
                            <td class="hidden">{{ investisseur.unite }}</td>
                            <td class="hidden">{{ investisseur.conditionUtil }}</td>
                            <td class="hidden">{{ investisseur.duree }}</td>
                            <td class="hidden">{{ investisseur.taux }}</td>
                            <td>{{ investisseur.observation }}</td>
                            <td class="flex gap-4 py-3 justify-center">
                                <div @click="modal_details = true, idinvest2 = investisseur.idinvest, nom2 = investisseur.nom, contact2 = investisseur.contact, email2 = investisseur.email, adresse2 = investisseur.adresse, montant2 = investisseur.montant, province2 = investisseur.province, region2 = investisseur.region,
                                            unite2 = investisseur.unite, conditionUtil2 = investisseur.conditionUtil, duree2 = investisseur.duree, taux2 = investisseur.taux, observation2 = investisseur.observation, verification2 = investisseur.verification, refImage = investisseur.image"
                                    class="fa fa-eye text-gray-500 active:bg-gray-200"></div>
                                <div @click="modal_modif = true, idinvest2 = investisseur.idinvest, nom2 = investisseur.nom, contact2 = investisseur.contact, email2 = investisseur.email, adresse2 = investisseur.adresse, montant2 = investisseur.montant, province2 = investisseur.province, region2 = investisseur.region,
                                            unite2 = investisseur.unite, conditionUtil2 = investisseur.conditionUtil, duree2 = investisseur.duree, taux2 = investisseur.taux, observation2 = investisseur.observation, verification2 = investisseur.verification"
                                    class="fa fa-pencil-alt text-green-400  active:bg-gray-200"></div>
                                <div @click="modal_suppr = true, idinvest1 = investisseur.idinvest" class="fa fa-trash text-red-300 active:bg-gray-200"></div>
                            </td>
                        </tr>
                    </tbody>
                    <tbody v-else>
                        <tr class="text text-center">
                            <td colspan="6">Pas de résultats qui correspond à votre recherche</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
    <Menu></Menu>
    <!-- modal menu pour mobile -->
    <Sidebar :modal_menu="modal_menu" @close="modal_menu=false"></Sidebar>
    <!--modal ajout-->
    <Modal :isOpen="modal_ajout" @close="modal_ajout=false">
        <div>
            <h5 class="fonr-bold">Ajout Investisseur</h5>
        </div>
        <div class="w-full">
            <form action="http://127.0.0.1:8000/api/ajout_investisseur_seera" method="POST" @submit.prevent="ajoutInvestisseur">
                <div class="flex max-lg:flex-col w-full gap-x-9 ">
                    <div class="">
                        <div class="flex justify-between gap-5 p-1">
                            <div class=" max-sm:pr-2"><label for="Nom">Nom</label></div>
                            <div class="w-8/12"><input v-model="nom" type="text" name="Nom" class="rounded border w-full border-gray-300 p-1"></div>
                        </div>
                        <div class="flex justify-between gap-5 p-1">
                            <div class="w-fit max-sm:pr-3"><label for="Contact ">Contact</label></div>
                            <div class="w-8/12"><input v-model="contact" type="text" name="Contact" class="rounded border w-full border-gray-300 p-1"></div>
                        </div>
                        <div class="flex justify-between gap-5 p-1">
                            <div class="w-fit max-sm:pr-3"><label for="adresse">Adresse</label></div>
                            <div class="w-8/12"><input v-model="adresse" type="text" name="adresse" class="rounded border w-full border-gray-300 p-1"></div>
                        </div>
                        <div class="flex justify-between gap-5 p-1">
                            <div class="w-fit max-sm:pr-2"><label for="email">Email</label></div>
                            <div class="w-8/12"><input v-model="email" type="text" name="email" class="rounded border w-full border-gray-300 p-1"></div>
                        </div>
                        <div class="flex justify-between gap-5 p-1">
                            <div class="w-fit max-sm:pr-2"><label for="Besoin">Vérification</label></div>
                            <div class="w-8/12">
                                <select v-model="verification" class="border border-gray-300 p-1 rounded w-full ">
                                                                    <option value="vrai">Vrai</option>
                                                                    <option value="faux">Faux</option>
                                                                </select>
                            </div>
                        </div>
                        <div class="flex justify-between gap-5 p-1">
                            <div class="w-fit max-sm:pr-2"><label for="Observation">Observation</label></div>
                            <div class="w-8/12"><textarea name="" id="i" cols="45" rows="3" v-model="observation" class="border w-full border-black" required></textarea></div>
                        </div>
                        <div class="flex justify-between gap-5 p-1">
                            <div><label for="Besoin">Province</label></div>
                            <div class="w-8/12 ">
                                <select v-model="province" class="border border-gray-300 px-1 py-1 w-full rounded ">
                                                                    <option>Antananarivo</option>
                                                                    <option>Fianarantsoa</option>
                                                                    <option>Mahajanga</option>
                                                                    <option>Toliara</option>
                                                                    <option>Toamasina</option>
                                                                    <option>Antsiranana</option>
                                                                </select>
                            </div>
                        </div>
                        <div class="flex justify-between gap-5 p-1">
                            <div><label for="Besoin">Région</label></div>
                            <div class="w-8/12 ">
                                <select v-model="region" class="border border-gray-300 px-1 py-1 w-full rounded ">
                                                                    <option>Menabe</option>
                                                                    <option>Atsimo Atsinanana</option>
                                                                    <option>Vatovavy</option>
                                                                    <option>Fitovinany</option>
                                                                    <option>Analamanga</option>
                                                                    <option>Vakinakaratra</option>
                                                                </select>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div class="flex justify-between gap-5 p-1">
                            <div class="w-fit max-sm:pr-3"><label for="Montant">Montant</label></div>
                            <div class="w-8/12"><input v-model="montant" type="number" name="Montant" class="rounded border w-full border-gray-300 p-1"></div>
                        </div>
                        <div class="flex justify-between gap-5 p-1">
                            <div class="w-fit max-sm:pr-2"><label for="Unite">Unite</label></div>
                            <div class="w-8/12"><input v-model="unite" type="text" name="Unite" class="rounded border w-full border-gray-300 p-1"></div>
                        </div>
                        <div class="flex justify-between gap-5 p-1">
                            <div class="w-fit max-sm:pr-2 "><label for="Condition">Condition
                                                                    d'utilisation</label></div>
                            <div class="w-12/12"><textarea name="" id="i" cols="45" rows="3" v-model="conditionUtil" class="border w-full border-black" required></textarea></div>
                        </div>
                        <div class="flex justify-between gap-5 p-1">
                            <div class="w-fit max-sm:pr-2"><label for="Duree">Duree</label></div>
                            <div class="w-8/12"><input v-model="duree" type="text" name="Duree" class="rounded border w-full border-gray-300 p-1"></div>
                        </div>
                        <div class="flex justify-between gap-5 p-1">
                            <div class="w-fit max-sm:pr-2"><label for="Taux">Taux</label></div>
                            <div class="w-8/12"><input v-model="taux" type="number" name="Taux" class="rounded border w-full border-gray-300 p-1"></div>
                        </div>
                        <div class="flex justify-between gap-5 p-1">
                            <div class="w-fit max-sm:pr-2"><label for="">Image</label></div>
                            <div class="w-8/12"><input type="file" ref="imageInput" name="Fichier" class="rounded border w-full border-gray-300 p-1" @change="handleFileChange" required></div>
                        </div>
                    </div>
                </div>
                <div class="flex max-lg:justify-between lg:justify-end w-full items-center gap-5 py-4">
                    <div class="max-lg:w-1/2"><button @click="modal_ajout = false" class="border border-gray-600 max-lg:w-full lg:w-32 text-gray-600 px-3 py-1 rounded">Annuler</button>
                    </div>
                    <div class="max-lg:w-1/2"><button class="bg-blue-400 hover:bg-blue-500 text-white px-3 py-1 lg:w-32 max-lg:w-full rounded" type="submit">Enregistrer</button></div>
                </div>
            </form>
        </div>
    </Modal>
    <!--modal modification-->
    <Modal :isOpen="modal_modif" @close="modal_modif=false">
        <div class="flex justify-center items-center">
            <div>
                <h1 class="font-bold">Modification d'Investisseur</h1>
            </div>
        </div>
        <div class="w-full">
            <form action="http://127.0.0.1:8000/api/modification_investisseur_seera" method="POST" @submit.prevent="modificationInvestisseur">
                <input type="hidden" v-model="idinvest2">
                <div class="flex max-lg:flex-col gap-3 justify-between items-start w-full">
                    <div class="flex flex-col max-lg:w-full lg:w-1/2">
                        <div class="flex justify-between gap-5 p-1">
                            <div><label for="Nom">Nom</label></div>
                            <div class="w-8/12"><input v-model="nom2" type="text" name="Nom" class="rounded border border-gray-300 w-full p-1" required></div>
                        </div>
                        <div class="flex justify-between gap-5 p-1">
                            <div><label for="Contact">Contact</label></div>
                            <div class="w-8/12"><input v-model="contact2" type="text" name="Contact" class="rounded border border-gray-300 w-full p-1" required></div>
                        </div>
                        <div class="flex justify-between gap-5 p-1">
                            <div><label for="adresse">Adresse</label></div>
                            <div class="w-8/12"><input v-model="adresse2" type="text" name="adresse" class="rounded border border-gray-300 w-full p-1" required></div>
                        </div>
                        <div class="flex justify-between gap-5 p-1">
                            <div><label for="email">Email</label></div>
                            <div class="w-8/12"><input v-model="email2" type="text" name="email" class="rounded border border-gray-300 w-full p-1" required></div>
                        </div>
                        <div class="flex justify-between gap-5 p-1">
                            <div><label for="Observation">Observation</label></div>
                            <div class="w-8/12"><textarea name="" id="i" cols="45" rows="3" v-model="observation2" class="border w-full border-black" required></textarea></div>
                        </div>
                        <div class="flex  justify-between gap-5 p-1">
                            <div><label for="Besoin">Vérification</label></div>
                            <div class="w-8/12">
                                <select v-model="verification2" class="border border-gray-300 px2 w-full rounded ">
                                                                    <option value="vrai">Vrai</option>
                                                                    <option value="faux">Faux</option>
                                                                </select>
                            </div>
                        </div>
                        <div class="flex justify-between gap-5 p-1">
                            <div><label for="Besoin">Province</label></div>
                            <div class="w-8/12 ">
                                <select v-model="province2" class="border border-gray-300 px-1 py-1 w-full rounded ">
                                                                    <option value="Antananarivo">Antananarivo</option>
                                                                    <option value="Fianarantsoa">Fianarantsoa</option>
                                                                    <option value="Mahajanga">Mahajanga</option>
                                                                    <option value="Toliara">Toliara</option>
                                                                    <option value="Toamasina">Toamasina</option>
                                                                    <option value="Antsiranana">Antsiranana</option>
                                                                </select>
                            </div>
                        </div>
                        <div class="flex justify-between gap-5 p-1">
                            <div><label for="Besoin">Région</label></div>
                            <div class="w-8/12 ">
                                <select v-model="region2" class="border border-gray-300 px-1 py-1 w-full rounded ">
                                                                    <option value="Menabe">Menabe</option>
                                                                    <option value="Atsimo Atsinanana">Atsimo Atsinanana</option>
                                                                    <option value="Vatovavy">Vatovavy</option>
                                                                    <option value="Fitovinany">Fitovinany</option>
                                                                    <option value="Analamanga">Analamanga</option>
                                                                    <option value="Vakinakaratra">Vakinakaratra</option>
                                                                </select>
                            </div>
                        </div>
                    </div>
                    <div class="flex flex-col max-lg:w-full lg:w-1/2">
                        <div class="flex justify-between gap-5 p-1">
                            <div><label for="Montant">Montant</label></div>
                            <div class="w-8/12"><input v-model="montant2" type="number" name="Montant" class="rounded border border-gray-300 p-1 w-full" required></div>
                        </div>
                        <div class="flex justify-between gap-5 p-1">
                            <div><label for="Unite">Unite</label></div>
                            <div class="w-8/12"><input v-model="unite2" type="text" name="Unite" class="rounded border border-gray-300 p-1 w-full" required></div>
                        </div>
                        <div class="flex justify-between gap-5 p-1">
                            <div><label for="Condition">Condition</label></div>
                            <div class="w-8/12"><textarea name="" id="i" cols="45" rows="3" v-model="conditionUtil2" class="border border-black w-full" required></textarea></div>
                        </div>
                        <div class="flex justify-between gap-5 p-1">
                            <div><label for="Duree">Duree</label></div>
                            <div class="w-8/12"><input v-model="duree2" type="text" name="Duree" class="rounded border border-gray-300 p-1 w-full" required></div>
                        </div>
                        <div class="flex justify-between gap-5 p-1">
                            <div><label for="Taux">Taux</label></div>
                            <div class="w-8/12"><input v-model="taux2" type="number" name="Taux" class="rounded border border-gray-300 p-1 w-full" required></div>
                        </div>
                        <div class="flex justify-between gap-5 p-1">
                            <div><label for="Fichier">Image</label></div>
                            <div class="w-8/12"></div>
                        </div>
                    </div>
                </div>
                <div class="flex max-lg:justify-between lg:justify-end w-full items-center gap-5 p-1">
                    <div class="max-lg:w-1/2 "><button type="button" @click="modal_modif = false" class="border lg:w-32 border-gray-600 w-full text-gray-600  px-3 py-1 rounded ">Annuler</button>
                    </div>
                    <div class="max-lg:w-1/2"><button class="bg-blue-400 lg:w-32 px-3 py-1 rounded text-white w-full" type="submit">Modifier</button></div>
                </div>
            </form>
        </div>
    </Modal>
    <!--modal de suppresion-->
    <Modal :isOpen="modal_suppr" @close="modal_suppr=false">
        <div>
            <h1 class="font-bold w-full text-center">Suppression investisseur</h1>
            <input v-model="idinvest1" type="hidden">
        </div>
        <p class="text-slate-500 w-full text-center">Voulez-vous vraiment supprimer?</p>
        <div class="flex justify-betweeen w-full gap-5">
            <div @click="modal_suppr = false" class="rounded px-3 py-1 text-center w-1/2 border border-gray-600 text-gray-600">Annuler</div>
            <div class="rounded px-3 py-1 bg-blue-400 text-white w-1/2 text-center" @click="suppressionInvestisseur">Supprimer</div>
        </div>
    </Modal>
    <!--modal details-->
    <Modal :isOpen="modal_details" @close="modal_details=false">
        <div class="flex justify-center">
            <div>
                <h1 class="font-bold pb-2">Details d'Investisseur</h1>
            </div>
        </div>
        <!-- <div class="w-full">
                                        img.
                                    </div> -->
        <div class="flex justify-center gap-5">
            <div>
                <form class="flex max-lg:flex-lg justify-between items-start gap-x-2 w-full">
                    <div class="flex flex-col items-start justify-start max-lg:w-full lg:w-1/2">
                        <div class=" justify-between gap-5 p-1 hidden w-full">
                            <div><label for="id">id</label></div>
                            <div><input v-model="idinvest2" type="text" name="id" class="rounded border border-gray-300 p-1" disabled></div>
                        </div>
                        <div class="flex justify-between gap-5 p-1 w-full">
                            <div><label for="Nom">Nom</label></div>
                            <div><input v-model="nom2" type="text" name="Nom" class="rounded border border-gray-300 p-1" disabled></div>
                        </div>
                        <div class="flex justify-between gap-5 p-1 w-full">
                            <div><label for="Contact">Contact</label></div>
                            <div><input v-model="contact2" type="text" name="Contact" class="rounded border border-gray-300 p-1" disabled></div>
                        </div>
                        <div class="flex justify-between gap-5 p-1 w-full">
                            <div><label for="adresse">adresse</label></div>
                            <div><input v-model="adresse2" type="text" name="adresse" class="rounded border border-gray-300 p-1" disabled></div>
                        </div>
                        <div class="flex justify-between gap-5 p-1 w-full">
                            <div><label for="email">email</label></div>
                            <div><input v-model="email2" type="text" name="email" class="rounded border border-gray-300 p-1" disabled></div>
                        </div>
                        <div class="flex justify-between gap-5 p-1 w-full">
                            <div><label for="Observation">Vérification</label></div>
                            <div><input v-model="verification2" type="text" name="Observation" class="rounded border border-gray-300 p-1" disabled></div>
                        </div>
                        <div class="flex justify-between gap-5 p-1 w-full">
                            <div><label for="Observation">Province</label></div>
                            <div><input v-model="province2" type="text" name="Observation" class="rounded border border-gray-300 p-1" disabled></div>
                        </div>
                        <div class="flex justify-between gap-5 p-1 w-full">
                            <div><label for="Observation">Région</label></div>
                            <div><input v-model="region2" type="text" name="Observation" class="rounded border border-gray-300 p-1" disabled></div>
                        </div>
                    </div>
                    <div class="flex flex-col items-start justify-start max-lg: lg:w-1/2">
                        <div class="flex justify-between gap-5 p-1 w-full">
                            <div><label for="Montant">Montant</label></div>
                            <div><input v-model="montant2" type="text" name="Montant" class="rounded border border-gray-300 p-1" disabled></div>
                        </div>
                        <div class="flex justify-between gap-5 p-1 w-full">
                            <div><label for="Unite">Unite</label></div>
                            <div><input v-model="unite2" type="text" name="Unite" class="rounded border border-gray-300 p-1" disabled></div>
                        </div>
                        <div class="flex justify-between gap-5 p-1 w-full">
                            <div><label for="Condition">Condition</label></div>
                            <div><input v-model="conditionUtil2" type="text" name="Condition" class="rounded border border-gray-300 p-1" disabled></div>
                        </div>
                        <div class="flex justify-between gap-5 p-1 w-full">
                            <div><label for="Duree">Duree</label></div>
                            <div><input v-model="duree2" type="text" name="Duree" class="rounded border border-gray-300 p-1" disabled></div>
                        </div>
                        <div class="flex justify-between gap-5 p-1 w-full">
                            <div><label for="Taux">Taux</label></div>
                            <div><input v-model="taux2" type="text" name="Taux" class="rounded border border-gray-300 p-1" disabled></div>
                        </div>
                        <div class="flex justify-between gap-5 p-1 w-full">
                            <div><label for="Observation">Observation</label></div>
                            <div><input v-model="observation2" type="text" name="Observation" class="rounded border border-gray-300 p-1" disabled></div>
                        </div>
                        <div class="flex justify-between gap-5 p-1 w-full">
                            <div><label for="Fichier">Image</label></div>
                            <div><input v-model="refImage" type="text" name="refImage" class="rounded border border-gray-300 p-1 cursor-pointer" @click="show_modal_image()"></div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </Modal>
    <!-- modal image -->
    <Modal :isOpen="modal_image" @close="modal_image=false">
        <img :src="getImageUrl(refImage)" alt="">
    </Modal>
</template>
<style>
    .v-enter-active,
    .v-leave-active {
        transition: transform 0.6s;
    }
    .v-enter-from,
    .v-leave-to {
        transform: translateX(-500px)
    }
</style>
<script>
    import apiUrl from '../../ApiConfig';
    import axios from 'axios'
    import Menu from '@/components/Admin/Menu.vue'
    import Header from '@/components/Admin/Header.vue'
    import Filtre from '@/components/Admin/Filtre.vue'
    import '@/assets/styleAdmin/page.css'
     import Sidebar from '@/components/Admin/sidebar/sidebar.vue'
    import Modal from "@/components/modal/modal.vue"
    import {
        Transition
    } from 'vue';
    export default {
        name: 'Investisseur',
        components: {
            Menu,
            Filtre,
            Header,
            Modal,Sidebar
        },
        data() {
            return {
                //ovaina ny nom n variable name-page
                menu_item: [{
                        title: 'Dashboard',
                        link: '/Admin/Dashboard',
                        logo: 'fa fa-house '
                    },
                    {
                        title: 'Comparaison',
                        link: '/Admin/Comparaison',
                        logo: 'fas fa-chart-line'
                    },
                    {
                        title: 'Categorie',
                        link: '/Admin/Categorie',
                        logo: 'fa fa-list-check '
                    },
                    {
                        title: 'Utilisateur',
                        link: '/Admin/Utilisateur',
                        logo: 'fa fa-users '
                    },
                    {
                        title: 'Abonnement',
                        link: '/Admin/subscription',
                        logo: 'fas fa-user-check '
                    },
                    {
                        title: 'Client',
                        link: '/Admin/Client',
                        logo: 'fa fa-user'
                    },
                    {
                        title: 'Fournisseur',
                        link: '/Admin/Fournisseur',
                        logo: 'fa fa-house '
                    },
                    {
                        title: 'Investisseur',
                        link: '/Admin/Investisseur',
                        logo: 'fa fa-money-check '
                    },
                    {
                        title: 'Message',
                        link: '/Admin/Message',
                        logo: 'fas fa-message '
                    },
                ],
                name_page: this.$options.name,
                modal_modif: false,
                modal_ajout: false,
                modal_suppr: false,
                modal_details: false,
                modal_image: false,
                modal_menu: false,
                Prix1: '',
                Prix2: '',
                Categorie: '',
                Produit: '',
                Recherche: '',
                inpPrix1: '',
                inpPrix2: '',
                inpCat: '',
                inpProduit: '',
                image: '',
                //input modal ajout
                idinvest: '',
                nom: '',
                contact: '',
                adresse: '',
                email: '',
                montant: '',
                unite: '',
                conditionUtil: '',
                duree: '',
                taux: '',
                observation: '',
                verification: '',
                province: '',
                region: '',
                idinvest2: '',
                nom2: '',
                contact2: '',
                adresse2: '',
                email2: '',
                montant2: '',
                unite2: '',
                conditionUtil2: '',
                duree2: '',
                taux2: '',
                refImage: '',
                observation2: '',
                verification2: '',
                province2: '',
                region2: '',
                //input modal modif et details
                idinvest1: '',
                investisseurs: [],
                investisseur: {
                    idinvest: '',
                    nom: '',
                    contact: '',
                    adresse: '',
                    email: '',
                    montant: '',
                    unite: '',
                    conditionUtil: '',
                    duree: '',
                    taux: '',
                    observation: '',
                    image: '',
                    verification: '',
                    province: '',
                    region: ''
                }
            }
        },
        mounted() {
            this.getInvestisseurs();
        },
        methods: {
            hide() {
                if (this.modal_menu == true) {
                    this.modal_menu = false
                }
            },
            data_modal_ajout(data) {
                this.modal_ajout = data
            },
            getImageUrl(refImage) {
                // Utilisez la fonction asset() de Laravel pour générer l'URL de l'image
                return apiUrl + `images/invest/${refImage}`;
            },
            receive_data_filter(props1, props2, props3, props4, props5) {
                this.Prix1 = props1
                this.Prix2 = props2
                this.Categorie = props3
                this.Produit = props4
                this.Recherche = props5
            },
            handleFileChange(event) {
                this.image = event.target.files[0];
            },
            getInvestisseurs() {
                try {
                    axios.get(apiUrl + 'api/affichage_investisseur_seera').then(response => {
                        this.investisseurs = response.data
                        // eslint-disable-next-line no-undef
                    }).catch(error => {
                        console.log(error)
                        this.investisseurs = []
                    })
                } catch (error) {
                    console.error(error)
                }
            },
            show_modal_image() {
                // image_modal=img
                this.modal_image = true
            },
            rechercheInvestisseur() {
                try {
                    axios.get(apiUrl + 'api/recherche_investisseur_front_seera', {
                        params: {
                            inpMont1: this.inpMont1,
                            inpMont2: this.inpMont2,
                            inpTaux: this.inpTaux,
                            inpDuree: this.inpDuree
                        }
                    }).then(response => {
                        this.investisseurs = response.data
                    }).catch(error => {
                        console.log(error)
                    })
                } catch (error) {
                    console.error(error)
                }
            },
            ajoutInvestisseur() {
                try {
                    const formData = new FormData();
                    formData.append('nom', this.nom);
                    formData.append('contact', this.contact);
                    formData.append('adresse', this.adresse);
                    formData.append('email', this.email);
                    formData.append('montant', this.montant);
                    formData.append('unite', this.unite);
                    formData.append('conditionUtil', this.conditionUtil);
                    formData.append('duree', this.duree);
                    formData.append('taux', this.taux);
                    formData.append('observation', this.observation);
                    formData.append('verification', this.verification);
                    formData.append('province', this.province);
                    formData.append('region', this.region);
                    formData.append('image', this.image);
                    // eslint-disable-next-line no-unused-vars
                    axios.post(apiUrl + 'api/ajout_investisseur_seera', formData).then(response => {
                            if (confirm('Investisseur ajouté avec succès')) {
                                this.getInvestisseurs();
                                this.nom = ""; this.contact = ""; this.email = ""; this.adresse = ""; this.montant = ""; this.unite = ""; this.conditionUtil = "";
                                this.duree = ""; this.taux = ""; this.observation = ""; this.verification = ""; this.province = ""; this.region = ""; this.modal_ajout = false;
                            }
                        })
                        .catch(error => {
                            (
                                console.log(error)
                            )
                        });
                } catch (error) {
                    console.error(error)
                }
            },
            modificationInvestisseur() {
                try {
                    axios.post(apiUrl + 'api/modification_investisseur_seera', {
                        idinvest: this.idinvest2,
                        nom: this.nom2,
                        contact: this.contact2,
                        email: this.email2,
                        adresse: this.adresse2,
                        // eslint-disable-next-line no-unused-vars
                        montant: this.montant2,
                        unite: this.unite2,
                        conditionUtil: this.conditionUtil2,
                        duree: this.duree2,
                        taux: this.taux2,
                        observation: this.observation2,
                        verification: this.verification2,
                        province: this.province2,
                        region: this.region2,
                    }).then(response => {
                        if (confirm('Investisseur modifié avec succès')) {
                            this.modal_modif = false;
                            this.getInvestisseurs();
                        }
                    }).catch(error => {
                        (
                            console.log(error)
                        )
                    });
                } catch (error) {
                    console.erro(error)
                }
            },
            suppressionInvestisseur() {
                try {
                    axios.post(apiUrl + 'api/suppression_investisseur_seera', {
                        idinvest: this.idinvest1
                        // eslint-disable-next-line no-unused-vars
                    }).then(response => {
                        if (confirm('Investisseur supprimé avec succès')) {
                            this.modal_suppr = false;
                            this.getInvestisseurs();
                        }
                    }).catch(error => {
                        (
                            console.log(error)
                        )
                    });
                } catch (error) {
                    console.error(error)
                }
            },
        }
    }
</script>
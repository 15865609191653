<template lang="">
    <Modal :isOpen="isOpen.email" @close="close()">
        <form @submit.prevent="changingEmail()" class="w-full flex flex-col gap-y-2">
            <h1 class="text-slate-600 font-bold">Changement d'Email</h1>
            <div class="flex flex-col">
                <label for="nouveauEmail" class="text-slate-500">Nouveau Email</label>
                <input type="email" class="input" name="nouveauEmail" v-model="nouveauEmail" />
            </div>
            <div class="w-full flex justify-end items-center">
                <button type="submit" class="btn bg-slate-700 max-md:w-full md:w-fit text-white">Changer</button>
            </div>
        </form>
    </Modal>
    <Modal :isOpen="isOpen.password" @close="close()">
        <form @submit.prevent="changingPassword()" class="w-full flex flex-col gap-y-2">
            <h1 class="text-slate-600 font-bold">Changement de mot de passe</h1>
            <div class="flex flex-col">
                <label for="ancienPassword" class="text-slate-500">Ancien mot de passe</label>
                <input type="text" class="input" name="ancienPassword" v-model="ancienPwd" />
            </div>
            <div class="flex flex-col">
                <label for="nouveauPassword" class="text-slate-500">Nouveau mot de passe</label>
                <input type="text" class="input" name="nouveauPassword" v-model="nouveauPwd" />
            </div>
            <div class="flex flex-col">
                <label for="confirmationNouveauPassword" class="text-slate-500">Confirmation nouveau mot de passe</label>
                <input type="text" class="input" name="confirmationNouveauPassword" v-model="confirmationPwd" />
            </div>
            <div class="w-full flex justify-end items-center">
                <button type="submit" class="btn bg-slate-700 max-md:w-full md:w-fit text-white">Changer</button>
            </div>
        </form>
    </Modal>
    <div class="flex flex-col gap-y-3">
        <div class="flex flex-row justify-between items-center gap-x-2 text-slate-700 font-semibold">
            <h4 class="w-full bg-blue-50 p-2 font-bold">Personnel</h4>
            <!-- <span class="text-blue-400 fa fa-pen"></span> -->
        </div>
        <div class="flex flex-col ">
            <div class="flex flex-row justify-start items-center gap-x-2 text-slate-700 font-semibold">
                <h4>Nom</h4>
                <span></span>
            </div>
            <div class="text-slate-500 ">
                {{ nomUser }}
            </div>
        </div>
        <div class="flex flex-col ">
            <div class="flex flex-row justify-start items-center gap-x-2 text-slate-700 font-semibold">
                <h4>Adresse</h4>
                <span></span>
            </div>
            <div class="text-slate-500 ">
                {{ adresseUser }}
            </div>
        </div>
        <div class="flex flex-col ">
            <div class="flex flex-row justify-start items-center gap-x-2 text-slate-700 font-semibold">
                <h4>Telephone</h4>
                <span></span>
            </div>
            <div class="text-slate-500 ">
                {{ contactUser }}
            </div>
        </div>
        <div class="flex flex-col ">
            <div class="flex flex-row justify-between items-center gap-x-2 text-slate-700 font-semibold">
                <h4>Email</h4>
                <button class="text-blue-400" @click="isOpen.email=true">Changer l'Email</button>
            </div>
            <div class="text-slate-500 ">
                {{ emailUser }}
            </div>
        </div>
        <div class="flex flex-col ">
            <div class="flex flex-row justify-between items-center gap-x-2 text-slate-700 font-semibold">
                <h4>Mot de passe</h4>
                <button class="text-blue-400" @click="isOpen.password=true">Changer le mot de passe</button>
            </div>
            <div class="text-slate-500 ">
                {{ pwdUser }}
            </div>
        </div>
    </div>
    <!-- feedback success -->
    <Feedback :isOpen="feedback.success.isOpen" @close="feedback.success.isOpen=false">
        <div class="flex justify-start items-center gap-x-4">
            <span class="fa fa-check text-xl font-bold text-white bg-green-600 rounded-full p-3"></span>
            <span class="text-slate-600">{{feedback.success.text}}</span>
        </div>
    </Feedback>
    <!-- feedback failure -->
    <Feedback :isOpen="feedback.fails.isOpen" @close="feedback.fails.isOpen=false">
        <div class="flex justify-start items-center gap-x-4">
            <span class="fa fa-times text-xl font-bold text-white bg-red-600 rounded-full px-3 py-2"></span>
            <span class="text-slate-600">{{feedback.fails.text}}</span>
        </div>
    </Feedback>
</template>
<script>
    import apiUrl from "@/ApiConfig"
    import Modal from "@/components/modal/modal.vue"
    import Feedback from "@/components/modal/Feedback.vue"
    import axios from "axios"
    export default {
        components: {
            Modal,
            Feedback,
        },
        data() {
            return {
                iduser: '',
                nomUser: '',
                adresseUser: '',
                contactUser: '',
                emailUser: '',
                pwdUser: '',
                isOpen: {
                    email: false,
                    password: false
                },
                ancienEmail: '',
                nouveauEmail: '',
                ancienPwd: "",
                nouveauPwd: "",
                confirmationPwd: "",
                feedback: {
                    success: {
                        isOpen: false,
                        text: ""
                    },
                    fails: {
                        isOpen: false,
                        text: ""
                    }
                }
            }
        },
        mounted() {
            this.iduser = localStorage.getItem('iduser');
            this.getUserName(), this.getUserAdresse(), this.getUserContact(), this.getUserEmail(),
                this.getUserPwd()
        },
        methods: {
            close() {
                this.isOpen = {
                    email: false,
                    password: false
                }
            },
            changingEmail() {
                try {
                    axios.post(apiUrl + "api/changerEmailUser", {
                            ancienEmail: this.ancienEmail,
                            nouveauEmail: this.nouveauEmail,
                            iduser: localStorage.getItem('iduser')
                        })
                        .then(response => {
                            // Retour de la modification avec succès par un modal
                            console.log("Modification d'email avec succès !")
                            this.feedback.success.isOpen = true
                            this.feedback.success.text = "Modification d'email avec succès !"
                            this.isOpen.email = false
                            setTimeout(() => {
                                this.feedback.success.isOpen = false
                            }, 2500)
                        })
                        .catch(error => {
                            console.error(error)
                            this.feedback.fails.isOpen = true
                            this.feedback.fails.text = "Modification d'email échoué !"
                            setTimeout(() => {
                                this.feedback.fails.isOpen = false
                            }, 2500)
                        })
                } catch (error) {
                    console.error(error)
                    this.feedback.fails.isOpen = true
                    this.feedback.fails.text = "Modification d'email échoué !"
                    setTimeout(() => {
                        this.feedback.fails.isOpen = false
                    }, 2500)
                }
            },
            changingPassword() {
                try {
                    axios.post(apiUrl + "api/changerPwdUser", {
                            nouveauPwd: this.nouveauPwd,
                            iduser: localStorage.getItem('iduser')
                        })
                        .then(response => {
                            // Retour de la modification avec succès par un modal
                            console.log("Modification du mot de passe avec succès !")
                            this.feedback.success.isOpen = true
                            this.feedback.success.text = "Modification de mot de passe avec succès !"
                            this.isOpen.password = false
                            setTimeout(() => {
                                this.feedback.success.isOpen = false
                            }, 2500)
                        })
                        .catch(error => {
                            console.error(error)
                            this.feedback.fails.isOpen = true
                            this.feedback.fails.text = "Modification de mot de passe échoué !"
                            setTimeout(() => {
                                this.feedback.fails.isOpen = false
                            }, 2500)
                        })
                } catch (error) {
                    console.error(error)
                    this.feedback.fails.isOpen = true
                    this.feedback.fails.text = "Modification de mot de passe échoué !"
                    setTimeout(() => {
                        this.feedback.fails.isOpen = false
                    }, 2500)
                }
            },
            getUserName() {
                axios.get(apiUrl + 'api/user_name', {
                    params: {
                        iduser: localStorage.getItem('iduser')
                    }
                }).then(response => {
                    this.nomUser = response.data[0].name;
                }).catch(error => {
                    console.log(error)
                })
            },
            getUserPwd() {
                axios.get(apiUrl + 'api/user_mdp', {
                    params: {
                        iduser: localStorage.getItem('iduser')
                    }
                }).then(response => {
                    this.pwdUser = response.data[0].pwd;
                }).catch(error => {
                    console.log(error)
                })
            },
            getUserAdresse() {
                axios.get(apiUrl + 'api/user_adresse', {
                    params: {
                        iduser: localStorage.getItem('iduser')
                    }
                }).then(response => {
                    this.adresseUser = response.data[0].adresse;
                }).catch(error => {
                    console.log(error)
                })
            },
            getUserContact() {
                axios.get(apiUrl + 'api/user_contact', {
                    params: {
                        iduser: localStorage.getItem('iduser')
                    }
                }).then(response => {
                    this.contactUser = response.data[0].contact;
                }).catch(error => {
                    console.log(error)
                })
            },
            getUserEmail() {
                axios.get(apiUrl + 'api/user_email', {
                    params: {
                        iduser: localStorage.getItem('iduser')
                    }
                }).then(response => {
                    this.emailUser = response.data[0].email;
                }).catch(error => {
                    console.log(error)
                })
            },
        }
    }
</script>
<style lang="">

</style>
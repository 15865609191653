<template lang="">
    <div class="modal_ajout w-screen h-screen inset-0 lg:hidden" v-show="modal_menu" @click="close()">
    </div>
    <Transition>
        <div class="modal_ajout text-sm top-0 right-0 flex justify-end items-start   w-fit" v-show="modal_menu">
            <div class="flex flex-col justify-start items-center max-md:w-screen md:w-[450px] h-screen shadow-sm shadow-slate-600/80 bg-white">
                <div class="w-full justify-end items-center flex p-2">
                    <div class=" fas fa-times text-xl text-slate-600 mx-2 my-1   rounded" @click="close()"></div>
                </div>
                <div class="w-full flex flex-col justify-center py-4 divide-y-2 text-slate-600">
                    <a v-for="(section, index) in sections" @click="modal_menu = false" :href="`/#${section.id}`" :key="index" class="w-11/12  scroll-smooth transition-all duration-100 text-left   mx-2 hover:bg-red-100  hover:text-red-500 font-semibold  p-4 flex justify-start items-center gap-x-3 ">
                        <span :class="section.icon"></span>
                        <span>{{ section.title }}</span>
                    </a>
                </div>
                <div class="w-11/12 flex max-md:flex-col justify-between items-center gap-2 ">
                    <router-link to="/signup" class="w-full">
                        <button class="py-1 px-3 w-full  rounded text-slate-500 border-2 border-slate-500">S'inscrire</button>
                    </router-link>
                    <router-link to="/signin" class="w-full">
                        <button class="py-1 px-3 rounded  w-full text-white bg-red-400 shadow-sm  hover:bg-red-500  ">Se
                                connecter</button>
                    </router-link>
                </div>
            </div>
        </div>
    </Transition>
</template>
<style scoped>
    .v-enter-from,
    .v-leave-to {
        transform: translateX(400px);
    }
    .v-enter-active,
    .v-leave-active {
        transition: transform 0.3s;
    }
</style>
<script>
    export default {
        props: {
            modal_menu: Boolean,
            required: true
        },
        emits:[
            'close'
        ],
        data() {
            return {
                sections: [{
                        id: 'Accueil',
                        title: 'Accueil',
                        icon: 'fa fa-home'
                    },
                    {
                        id: 'Service',
                        title: 'Service',
                        icon: 'fa fa-concierge-bell'
                    },
                    {
                        id: 'About',
                        title: 'A propos',
                        icon: 'fa fa-info-circle'
                    },
                    {
                        id: 'Pricing',
                        title: 'Nos offres',
                        icon: 'fa fa-dollar'
                    },
                    {
                        id: 'Contact',
                        title: 'Contact',
                        icon: 'fa fa-phone'
                    },
                ],
            }
        },
        mounted() {
            const modal_menu = $('.modal_menu')
            const btn_menu = $('.btn_menu')
            window.addEventListener("scroll", () => {
                // Get the current scroll position
                const scrollY = window.scrollY;
                // Check if the scroll has reached the bottom
                if (scrollY < 70) {
                    // Add 'bg-opacity-0' class to the element with 'modal_menu' ref
                    if (modal_menu.hasClass('shadow')) {
                        modal_menu.removeClass('shadow');
                    }
                    if (modal_menu.hasClass('shadow-slate-400')) {
                        modal_menu.removeClass('shadow-slate-400');
                    }
                    modal_menu.addClass('bg-opacity-0');
                    modal_menu.addClass('text-white');
                    modal_menu.addClass('border-white');
                    modal_menu.addClass('shadow-transparent');
                } else {
                    if (modal_menu.hasClass('text-white')) {
                        modal_menu.removeClass('text-white');
                    }
                    if (modal_menu.hasClass('border-white')) {
                        modal_menu.removeClass('border-white');
                    }
                    if (modal_menu.hasClass('shadow-transparent')) {
                        modal_menu.removeClass('shadow-transparent');
                    }
                    if (modal_menu.hasClass('bg-opacity-0')) {
                        modal_menu.removeClass('bg-opacity-0');
                    }
                    modal_menu.addClass('shadow');
                    modal_menu.addClass('shadow-slate-400');
                }
            });
        },
        methods: {
            close() {
                this.$emit('close');
            }
        }
    }
</script>
<style lang="">

</style>
<template>
    <div class="w-screen h-screen flex justify-between items-center">
        <div class="min-lg:w-8/12 max-lg:w-screen h-screen bg-blue-950 max-lg:hidden">
            <img src="./../../assets/pexels-mark-stebnicki-2749165.jpg" alt="" class="h-full w-full opacity-50">
        </div>
        <div
            class="min-lg:grid max-lg:flex max-lg:flex-col  max-lg:w-screen  min-lg:w-4/12 h-screen justify-center items-center text-center bg-blue-950 text-white">
            <div class="grid gap-4 mt-20">
                <div class="overflow-hidden">
                    <h1 class="text-3xl font-bold p-4">Connexion</h1><br>
                    <p class="mx-9 text-slate-300">
                        Accédez à davantage en un clic !
                        <br />Connectez-vous pour déverrouiller une expérience personnalisée et découvrir un monde
                        d'informations exclusives.
                    </p>
                </div>
                <div class="flex flex-col justify-center items-center gap-y-4">
                    <form @submit.prevent="connexion">
                        <div class="bg-transparent border-b border-white w-fit flex gap-5 justify-center items-center">
                            <div class="fa fa-envelope text-white"></div>
                            <div>
                                <input type="email" v-model="email" placeholder="E-mail" name="email"
                                    class="py-2 px-9 w-full focus:bg-transparent focus:border-0 focus:outline-none focus:border-white bg-transparent border-0 border-white"
                                    required>
                            </div>
                        </div>
                        <div class="bg-transparent border-b border-white w-fit flex gap-5 justify-center items-center">
                            <div class="fa fa-lock text-white"></div>
                            <div v-show="!type_pwd">
                                <input v-model="password" type="password" name="password" placeholder="Mot de passe"
                                    class="py-2 px-9 w-[100%] focus:bg-transparent focus:outline-none focus:border-0 focus:border-white bg-transparent border-0 border-white"
                                    required>

                            </div>

                            <div v-show="type_pwd">
                                <input type="text" v-model="password" placeholder="Mot de passe"
                                    class="py-2 px-9 w-[100%] focus:bg-transparent focus:outline-none focus:border-0 focus:border-white bg-transparent border-0 border-white"
                                    required>

                            </div>

                        </div><br>
                        <div>
                            <p class="text-red-500">{{ message }}</p>
                        </div>
                        <br>
                        <div class="w-full flex justify-start items-center gap-x-2 ">
                            <input type="checkbox" class="p-3 rounded border border-gray-300">
                            <span class="">Se souvenir de moi</span>
                        </div>
                        <br>
                        <div class="flex gap-4 justify-center items-center">

                            <div class="w-full">

                                <button type="submit"
                                    class="py-2 px-5 w-full rounded flex justify-center items-center bg-red-400 hover:bg-red-500 active:bg-red-500">
                                    <span class="fa fa-spinner animate-spin" v-if="load.login"></span>
                                    <span class="" v-else>Se connecter</span>
                                </button>

                            </div>
                        </div>
                    </form>
                </div>



                <div>
                    <p>
                        <span class="text-slate-300">Vous n'avez pas de compte ?</span>
                        <router-link to="/signup" class="text-orange-400"> S'inscrire !</router-link>
                    </p>
                </div>

            </div>
        </div>
    </div>
    <div class="img_user">
        <img src="./../../assets/serra2.png" class="max-sm:w-20 sm:w-[10vw]">
    </div>

    <div class="modal_ajout w-screen h-screen bg-black bg-opacity-50 flex justify-center items-center" v-show="modal1">
        <div class="bg-white p-4">
            <div class="w-full flex justify-end items-center">
                <div class="fa fa-times cursor-pointer" @click="modal1 = false"></div>
            </div>
            <div class="w-full flex justify-center items-center p-3">
                <div class="rounded-full p-2 fas fa-circle-notch fa-2x animate-spin text-gray-400"></div>
            </div>
            <div class="w-full flex justify-start items-center">
                <div>
                    <div class="">
                        <p class="text-gray-600">Votre abonnement est en cours de validation, veuillez attendre s'il
                            vous
                            plaît !</p>
                    </div>
                </div>
            </div>
            <div class="w-full flex items-center justify-center">
                <div class="">
                    <button class="px-4 py-1 bg-blue-400 hover:bg-blue-500 text-white"
                        @click="modal1 = false">OK</button>
                </div>
            </div>
        </div>
    </div>


    <div class="modal_ajout w-screen h-screen bg-black bg-opacity-50 flex justify-center items-center" v-show="modal1">
        <div class="bg-white p-4">
            <div class="w-full flex justify-end items-center">
                <div class="fa fa-times cursor-pointer" @click="modal1 = false, initConnexion()"></div>
            </div>
            <div class="w-full flex justify-center items-center p-3">
                <div class="rounded-full p-2 fas fa-circle-notch fa-2x animate-spin text-gray-400"></div>
            </div>
            <div class="w-full flex justify-start items-center">
                <div>
                    <div class="">
                        <p class="text-gray-600">Votre abonnement est en cours de validation, veuillez attendre s'il
                            vous
                            plaît !</p>
                    </div>
                </div>
            </div>
            <div class="w-full flex items-center justify-center">
                <div class="">
                    <button class="px-4 py-1 bg-blue-400 hover:bg-blue-500 text-white rounded m-2"
                        @click="modal1 = false, initConnexion()">OK</button>
                </div>
            </div>
        </div>
    </div>

</template>
<script>
import axios from 'axios';
import apiUrl from '../../ApiConfig';

export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: 'Connection',
    data() {
        return {
            type_pwd: false,
            password: '',
            modal1: false,
            email: '',
            message: '',
            load: {
                login: false
            }
        }
    }, methods: {

        connexion() {
            this.load.login = true
            try {
                const formData = new FormData();

                formData.append('email', this.email);
                formData.append('password', this.password);

                axios.post(apiUrl + 'api/login', formData)
                    .then(
                        ({ data }) => {
                            console.log(data);
                            this.load.login = false;
                            try {
                                if (data.reponse === 'En attente') {
                                    // alert("Connexion avec succès");
                                    this.$router.push("/subscription/" + data.idUser);
                                }

                                else if (data.reponse === 'A valider') {
                                    //this.message = "Admin se connecte";
                                    this.modal1 = true;
                                }

                                else if (data.reponse === 'Actif') {
                                    // this.$router.push("/home");
                                    this.$router.push('list_subscription')
                                    localStorage.setItem('iduser', data.idUser);

                                }

                                else if (data.reponse === 'admin') {
                                    this.$router.push("/Admin/Dashboard");
                                }

                                else {

                                    this.message = "Email ou mot de passe incorrect !";
                                    this.email = "";
                                    this.password = ""
                                }
                            }
                            catch (err) {
                                alert("Erreur, veuillez ressayer !");
                            }
                        }
                    )
            } catch (error) {
                console.error(error)
            }
        },

        initConnexion() {
            this.email = '',
                this.password = ''
        },

        togglePassword() {
            this.type_pwd = !this.type_pwd
        },
        goBack() {
            this.$router.go(-1)
        }
    }
}
</script>
<template>
  <div class="text-sm">
    <router-view ></router-view>
  </div>
</template>
<script>
export default {
  
}
</script>



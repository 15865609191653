<template>
    <div class="gray max-lg:w-screen lg:w-10/12 float-right h-screen flexbox  justify-center">
        <div class="bg-white max-sm:p-2 flex sm:h-fit mx-4  items-center rounded mb-3">
            <div class="flex p-2 justify-center items-center lg:hidden">
                <div class="fas fa-bars fa-2x text-gray-600 cursor-pointer" @click="modal_menu = !modal_menu"></div>
            </div>
            <Header :name_page="name_page" class="p-2"></Header>
        </div>
        <div class="bg-white h-fit  w-12/12 mx-4 justify-end items-center py-2 px-1 rounded ">
            <div class="flex justify-between w-12/12 px-1 items-center md:gap-6">
                <div class="flex  justify-start w-3/12 items-center">
                    <div class="flex justify-center items-center">
                        <Filtre @modal_ajout_show="data_modal_ajout"></Filtre>
                    </div>
                </div>
                <div class="flex justify-end items-center w-7/12">
                    <div class="flex  items-center w-fit h-fit justify-end border rounded border-gray-400">
                        <div class="flex">
                            <input type="text" name="" id="" v-model="inpProduit" placeholder="Rechercher..." class="p-1 focus:outline-none placeholder:text-gray-300">
                        </div>
                        <div class="flex justify-end items-center">
                            <div class="fa fa-search p-1 cursor-pointer"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!--table-->
        <div class="bg-white h-fit mx-4 flex items-center justify-center rounded my-3 ">
            <div class="w-full p-2">
                <table class="max-w-full w-full px-4">
                    <thead class="text-left bg-blue-50 font-semibold">
                        <th class="hidden">Idcat</th>
                        <th>Nom du catégorie</th>
                        <th>Actions</th>
                    </thead>
                    <tbody v-if="categories.length > 0" class="divide-y">
                        <tr v-for="categorie in categories" :key="categorie.idcat" class="text-left hover:bg-blue-50">
                            <td class="hidden">{{ categorie.idcat }}</td>
                            <td>{{ categorie.nomcat }}</td>
                            <td class="flex gap-4 justify-start items-center">
                                <div @click="modal_modif = true, idcat1 = categorie.idcat, nomcat1 = categorie.nomcat" class="fa fa-pencil-alt text-green-500  active:bg-gray-200"></div>
                                <div @click="modal_suppr = true, idcat2 = categorie.idcat" class="fa fa-trash text-orange-500 active:bg-gray-200 py-2"></div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
    <Menu></Menu>
    <!-- modal menu pour mobile -->
    <Sidebar :modal_menu="modal_menu" @close="modal_menu=false"></Sidebar>
    <!--modal_ajout-->
    <Modal :isOpen="modal_ajout" @close="modal_ajout=false">
        <h1 class="w-full text-center font-semibold">Ajout</h1>
        <div class="w-full">
            <form method="POST" @submit.prevent="ajoutCategorie">
                <div class="flex justify-between items-center gap-x-4  p-2">
                    <label for="label">Nom </label>
                    <input v-model="categorie.nomcat" type="text" name="label" class="rounded w-full border border-gray-300 p-1">
                </div>
                <div class="flex justify-between w-full items-center gap-3 p-2">
                    <div class="w-1/2"><button @click="modal_ajout = false" class="border text-gray-600 w-full h-8 text-center border-gray-600 py-1 rounded">Annuler</button>
                    </div>
                    <div class="w-1/2"><button class="bg-blue-500 hover:bg-blue-400 text-center w-full text-white h-8 py-1 rounded" type="submit">Enregitrer</button></div>
                </div>
            </form>
        </div>
    </Modal>
    <!--modal modification-->
    <Modal :isOpen="modal_modif" @close="modal_modif=false">
        <h1 class="w-full text-center font-semibold">Modification</h1>
        <div class="w-full">
            <form class="w-full">
                <input type="hidden" v-model="idcat1">
                <div class="flex justify-center items-center gap-x-5 py-3 w-full">
                    <label for="label">Nom</label>
                    <input v-model="nomcat1" type="text" name="label" class="rounded border border-gray-300 p-1 w-full">
                </div>
                <div class="flex justify-between w-full gap-3">
                    <button @click="modal_modif = false" class="h-8 rounded text-center text-gray-600 py-1 w-1/2 border border-gray-600">Annuler
                                        </button>
                    <button class="rounded py-1 bg-blue-500 h-8 text-center hover:bg-blue-400 text-white w-1/2" @click="modificationCategorie">Enregistrer</button>
                </div>
            </form>
        </div>
    </Modal>
    <!--modal de suppresion-->
    <Modal :isOpen="modal_suppr" @close="modal_suppr=false">
        <h1 class="w-full text-center font-semibold">Suppression de Categorie</h1>
        <input type="hidden" v-model="idcat2">
        <p class="">Etes vous sûr de supprimer ?</p>
        <div class="flex justify-between gap-x-3 w-full">
            <button @click="modal_suppr = false" class="rounded py-1 w-full border h-8 text-center text-gray-600 border-gray-600">Annuler</button>
            <button class="rounded py-1 w-full bg-blue-500 hover:bg-blue-400 h-8 text-center text-white" @click="suppressionCategorie">Supprimer</button>
        </div>
    </Modal>
    <Feedback :isOpen="feedback.success.isOpen" @close="feedback.success.isOpen=false">
        <div class="flex justify-start items-center gap-x-4">
            <span class="fa fa-check text-xl font-bold text-white bg-green-600 rounded-full p-3"></span>
            <span class="text-slate-600">{{feedback.success.text}}</span>
        </div>
    </Feedback>
    <!-- feedback failure -->
    <Feedback :isOpen="feedback.fails.isOpen" @close="feedback.fails.isOpen=false">
        <div class="flex justify-start items-center gap-x-4">
            <span class="fa fa-times text-xl font-bold text-white bg-red-600 rounded-full px-3 py-2"></span>
            <span class="text-slate-600">{{feedback.fails.text}}</span>
        </div>
    </Feedback>
</template>
<style>
    .v-enter-active,
    .v-leave-active {
        transition: transform 0.6s;
    }
    .v-enter-from,
    .v-leave-to {
        transform: translateX(-500px)
    }
</style>
<script>
    import apiUrl from '../../ApiConfig';
    import axios from 'axios'
    import Menu from '@/components/Admin/Menu.vue';
    import Header from '@/components/Admin/Header.vue';
    import Filtre from '@/components/Admin/Filtre.vue';
    import Modal from '@/components/modal/modal.vue';
    import Sidebar from '@/components/Admin/sidebar/sidebar.vue'
    import Feedback from "@/components/modal/Feedback.vue"
    export default {
        // eslint-disable-next-line vue/multi-word-component-names
        name: 'Categorie',
        components: {
            Menu,
            Header,
            Filtre,
            Modal,
            Sidebar,
            Feedback
        },
        data() {
            return {
                modal_menu: false,
                menu_item: [{
                        title: 'Dashboard',
                        link: '/Admin/Dashboard',
                        logo: 'fa fa-house '
                    },
                    {
                        title: 'Comparaison',
                        link: '/Admin/Comparaison',
                        logo: 'fas fa-chart-line'
                    },
                    {
                        title: 'Categorie',
                        link: '/Admin/Categorie',
                        logo: 'fa fa-list-check '
                    },
                    {
                        title: 'Utilisateur',
                        link: '/Admin/Utilisateur',
                        logo: 'fa fa-users '
                    },
                    {
                        title: 'Abonnement',
                        link: '/Admin/subscription',
                        logo: 'fa fa-users '
                    },
                    {
                        title: 'Client',
                        link: '/Admin/Client',
                        logo: 'fa fa-user'
                    }, {
                        title: 'Fournisseur',
                        link: '/Admin/Fournisseur',
                        logo: 'fa fa-house '
                    }, {
                        title: 'Investisseur',
                        link: '/Admin/Investisseur',
                        logo: 'fa fa-money-check '
                    }, {
                        title: 'Message',
                        link: '/Admin/Message',
                        logo: 'fas fa-message '
                    },
                ],
                name_page: this.$options.name,
                modal_modif: false,
                modal_ajout: false,
                modal_suppr: false,
                modal_details: false,
                Prix1: '',
                Prix2: '',
                Categories: ['asasd', 'asdasd'],
                Produit: '',
                inpPrix1: '',
                inpPrix2: '',
                inpCat: '',
                inpProduit: '',
                //data for ajout
                id: '',
                label: '',
                //other data
                idcat1: '',
                nomcat1: '',
                categorie: {
                    idcat: '',
                    nomcat: ''
                },
                categories: [],
                idcat2: '',
                feedback: {
                    success: {
                        isOpen: false,
                        text: "Votre message a été bien envoyé !"
                    },
                    fails: {
                        isOpen: false,
                        text: "L'envoy du message a échoué !"
                    }
                }
            }
        },
        mounted() {
            this.getCategories();
        },
        methods: {
            data_modal_ajout(data) {
                this.modal_ajout = data
            },
            receive_data_filter(props1, props2, props3, props4) {
                this.inp_prix = props1
                this.inp_sel_categorie = props2
                this.inp_sel_prix = props3
                this.inp_search = props4
            },
            getCategories() {
                try {
                    axios.get(apiUrl + 'api/affichage_categorie_seera').then(response => {
                        this.categories = response.data
                        // eslint-disable-next-line no-undef
                    }).catch(error => {
                        console.log(error)
                        this.categories = []
                    })
                } catch (error) {
                    console.error(error)
                }
            },
            ajoutCategorie() {
                // eslint-disable-next-line no-unused-vars
                try {
                    axios.post(apiUrl + 'api/ajout_categorie_seera', this.categorie)
                        .then(response => {
                           
                                this.getCategories();
                                this.categorie.nomcat = "";
                                this.modal_ajout = false;
                            
                            this.feedback.success.isOpen = true
                            setTimeout(() => {
                                this.feedback.success.isOpen = false
                            }, 2500)
                        })
                        .catch(error => {
                            console.log(error)
                            this.feedback.fails.isOpen = true
                            setTimeout(() => {
                                this.feedback.fails.isOpen = false
                            }, 2500)
                        });
                } catch (error) {
                    console.error(error)
                    this.feedback.fails.isOpen = true
                    setTimeout(() => {
                        this.feedback.fails.isOpen = false
                    }, 2500)
                }
            },
            modificationCategorie() {
                // eslint-disable-next-line no-unused-vars
                try {
                    axios.post(apiUrl + 'modification_categorie_seera', {
                        idcat: this.idcat1,
                        nomcat: this.nomcat1
                    }).then(response => {
                       
                            this.modal_modif = false;
                            this.getCategories();
                        
                        this.feedback.success.isOpen = true
                        setTimeout(() => {
                            this.feedback.success.isOpen = false
                        }, 2500)
                    }).catch(error => {
                        console.log(error)
                        this.feedback.fails.isOpen = true
                        setTimeout(() => {
                            this.feedback.fails.isOpen = false
                        }, 2500)
                    });
                } catch (error) {
                    console.error(error)
                    this.feedback.fails.isOpen = true
                    setTimeout(() => {
                        this.feedback.fails.isOpen = false
                    }, 2500)
                }
            },
            suppressionCategorie() {
                try {
                    axios.post(apiUrl + 'api/suppression_categorie_seera', {
                        idcat: this.idcat2
                        // eslint-disable-next-line no-unused-vars
                    }).then(response => {
                        
                            this.modal_suppr = false;
                            this.getCategories();
                        
                        this.feedback.success.isOpen = true
                        setTimeout(() => {
                            this.feedback.success.isOpen = false
                        }, 2500)
                    }).catch(error => {
                        console.log(error)
                        this.feedback.fails.isOpen = true
                        setTimeout(() => {
                            this.feedback.fails.isOpen = false
                        }, 2500)
                    });
                } catch (error) {
                    console.error(error)
                    this.feedback.fails.isOpen = true
                    setTimeout(() => {
                        this.feedback.fails.isOpen = false
                    }, 2500)
                }
            },
        }
    }
</script>
<!-- eslint-disable vue/require-v-for-key -->
<template>
    <div class="gray max-lg:w-screen lg:w-10/12 float-right h-screen flexbox  justify-center">
        <div class="bg-white max-sm:p-2 sm:h-fit mx-4 flex items-center rounded mb-3">
            <div class="flex p-2 justify-center items-center lg:hidden">
                <div class="fas fa-bars fa-2x text-gray-600 cursor-pointer" @click="modal_menu = !modal_menu"></div>
            </div>
            <Header :name_page="name_page" class="p-2"></Header>
        </div>
        <div class="bg-white h-fit mx-4 grid items-center rounded ">
            <div class="flex  lg:justify-between w-full p-2 items-center gap-3">
                <div class="flex flex-wrap max-lg:justify-start justify-center items-center ">
                    <Filtre @modal_ajout_show="data_modal_ajout"></Filtre>
                </div>
                <div class="flex justify-end items-center gap-x-3">
                    <div class="flex items-center justify-center  gap-x-3">
                        <label for="" class="text-sm">Prix </label>
                        <input type="number" class="border w-full py-1 rounded border-gray-400" v-model="inpPrix1">
                        <span class=" text-sm">et</span>
                        <input type="number" class="border w-full py-1 rounded border-gray-400" name="" id="" v-model="inpPrix2">
                    </div>
                    <div class="flex items-center max-sm:justify-start md:justify-center ">
                        <label for="" class="mx-2 text-sm">Categorie</label>
                        <select name="" id="" v-model="inpCat" class="border py-1  w-full rounded border-gray-400">
                                        <option v-for="categorie in categories" :key="categorie.nomcat" :value="categorie.nomcat">
                                            {{
                                categorie.nomcat
                            }}
                                        </option>
                                    </select>
                    </div>
                    <div class="flex justify-between items-center border border-gray-400 rounded ">
                        <input type="text" name="" id="" v-model="inpProduit" placeholder="Produit..." class="p-1 focus:outline-none placeholder:text-gray-300">
                        <button class="fa fa-search p-1  cursor-pointer" @click="rechercheClient()">
                                    </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="bg-white h-fit mx-4 flex items-center justify-center rounded my-3 ">
            <div class="w-full text-xs sm:text-base p-2 max-lg:overflow-x-auto">
                <table class="w-full max-w-full ">
                    <thead class="text-left bg-blue-50 font-semibold">
                        <th style="display: none;">Idcli</th>
                        <th>Nom</th>
                        <th>Contact</th>
                        <th style="display: none;">Email</th>
                        <th style="display: none;">Adresse</th>
                        <th>Produit demandé</th>
                        <th class="max-sm:hidden">Quantité</th>
                        <th class="max-sm:hidden">Unité</th>
                        <th class="max-sm:hidden">PU</th>
                        <th style="display: none;">Description</th>
                        <th style="display: none;">Lieu</th>
                        <th style="display: none;">Besoin</th>
                        <th></th>
                    </thead>
                    <tbody v-if="clients.length > 0" class="divide-y">
                        <tr v-for="client in clients" :key="client.idcli" class="hover:bg-gray-100">
                            <td style="display: none;">{{ client.idcli }}</td>
                            <td>{{ client.nom }}</td>
                            <td>{{ client.contact }}</td>
                            <td style="display: none;">{{ client.email }}</td>
                            <td style="display: none;">{{ client.adresse }}</td>
                            <td>{{ client.prodDemande }}</td>
                            <td class="max-sm:hidden">{{ client.quantite }}</td>
                            <td class="max-sm:hidden">{{ client.unite }}</td>
                            <td class="max-sm:hidden">{{ client.prixUnitaire }}</td>
                            <td style="display: none;">{{ client.description }}</td>
                            <td style="display: none;">{{ client.lieu }}</td>
                            <td style="display: none;">{{ client.besoin }}</td>
                            <td style="display: none;">{{ client.nomcat }}</td>
                            <td style="display: none;">{{ client.verification }}</td>
                            <td class="flex gap-2 justify-center py-3">
                                <div @click="modal_details = true, idcli = client.idcli, nom = client.nom, contact = client.contact, email = client.email, adresse = client.adresse, prodDemande = client.prodDemande,
                                quantite = client.quantite, unite = client.unite, prixUnitaire = client.prixUnitaire, description = client.description, lieu = client.lieu, besoin = client.besoin, nomcat = client.nomcat, verification = client.verification, province = client.province, region = client.region"
                                    class="fa fa-eye text-gray-500 active:bg-gray-200"></div>
                                <div @click="modal_modif = true, idcli = client.idcli, nom = client.nom, contact = client.contact, email = client.email, adresse = client.adresse, prodDemande = client.prodDemande,
                                quantite = client.quantite, unite = client.unite, prixUnitaire = client.prixUnitaire, description = client.description, lieu = client.lieu, besoin = client.besoin, nomcat = client.nomcat, verification = client.verification, province = client.province, region = client.region"
                                    class="fa fa-pencil-alt text-green-400  active:bg-gray-200"></div>
                                <div @click="modal_suppr = true, idcli1 = client.idcli" class="fa fa-trash text-red-300 active:bg-gray-200"></div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
    <Menu></Menu>
    <!-- modal menu pour mobile -->
    <Sidebar :modal_menu="modal_menu" @close="modal_menu=false"></Sidebar>
    <!--modal ajout-->
    <Modal :isOpen="modal_ajout" @close="modal_ajout=false">
        <div class="flex justify-center pb-3 items-center">
            <h5 class="text-2xl font-semibold">Ajout</h5>
        </div>
        <div class="">
            <form action="http://127.0.0.1:8000/api/ajout_client_seera" method="POST" @submit.prevent="ajoutClient">
                <div class="md:grid md:grid-cols-2 gap-6">
                    <div>
                        <div class="flex justify-between gap-2 p-1 ">
                            <div><label for="Nom">Nom</label></div>
                            <div class="w-8/12"><input type="text" name="Nom" class="rounded w-full border border-gray-300 px-1 py-1" v-model="client.nom"></div>
                        </div>
                        <div class="flex justify-between gap-5 p-1">
                            <div><label for="Contact">Contact</label></div>
                            <div class="w-8/12"><input type="text" name="Contact" class="rounded w-full border border-gray-300 px-1 py-1" v-model="client.contact"></div>
                        </div>
                        <div class="flex justify-between gap-5 p-1">
                            <div><label for="Email">Email</label></div>
                            <div class="w-8/12"><input type="email" name="Email" class="rounded border w-full border-gray-300 px-1 py-1" v-model="client.email"></div>
                        </div>
                        <div class="flex justify-between gap-5 p-1">
                            <div><label for="Adresse">Adresse</label></div>
                            <div class="w-8/12"><input type="text" name="Adresse" class="rounded border w-full border-gray-300 px-1 py-1" v-model="client.adresse"></div>
                        </div>
                        <div class="flex justify-between gap-5 p-1">
                            <div><label for="Besoin">Vérification</label></div>
                            <div class="w-8/12 ">
                                <select v-model="client.verification" class="border border-gray-300 px-1 py-1 w-full rounded ">
                                                        <option>vrai</option>
                                                        <option>faux</option>
                                                    </select>
                            </div>
                        </div>
                        <div class="flex justify-between gap-5 p-1">
                            <div><label for="Besoin">Province</label></div>
                            <div class="w-8/12 ">
                                <select v-model="client.province" class="border border-gray-300 px-1 py-1 w-full rounded ">
                                                        <option>Antananarivo</option>
                                                        <option>Fianarantsoa</option>
                                                        <option>Mahajanga</option>
                                                        <option>Toliara</option>
                                                        <option>Toamasina</option>
                                                        <option>Antsiranana</option>
                                                    </select>
                            </div>
                        </div>
                        <div class="flex justify-between gap-5 p-1">
                            <div><label for="Besoin">Région</label></div>
                            <div class="w-8/12 ">
                                <select v-model="client.region" class="border border-gray-300 px-1 py-1 w-full rounded ">
                                                        <option>Menabe</option>
                                                        <option>Atsimo Atsinanana</option>
                                                        <option>Vatovavy</option>
                                                        <option>Fitovinany</option>
                                                        <option>Analamanga</option>
                                                        <option>Vakinakaratra</option>
                                                    </select>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div class="flex justify-between gap-5 p-1">
                            <div><label for="Produit demandé">Produit demandé</label></div>
                            <div><input type="text" name="Produit demandé" class="rounded border w-full border-gray-300 px-1 py-1" v-model="client.prodDemande"></div>
                        </div>
                        <div class="flex justify-between gap-5 p-1">
                            <div><label for="quantite">Quantite</label></div>
                            <div><input type="number" name="quantite" class="rounded border border-gray-300 w-full px-1 py-1" v-model="client.quantite"></div>
                        </div>
                        <div class="flex justify-between gap-5 p-1">
                            <div><label for="unite">Unite</label></div>
                            <div><input type="text" name="unite" class="rounded border w-full border-gray-300 px-1 py-1" v-model="client.unite"></div>
                        </div>
                        <div class="flex justify-between gap-5 p-1">
                            <div><label for="PrixUnitaire">Prix unitaire</label></div>
                            <div><input type="number" name="PrixUnitaire" class="rounded w-full border border-gray-300 px-1 py-1 " v-model="client.prixUnitaire"></div>
                        </div>
                        <div class="flex justify-between gap-5 p-1">
                            <div><label for="description">Description</label></div>
                            <div><input type="text" name="quantite" class="rounded border w-full border-gray-300 px-1 py-1" v-model="client.description"></div>
                        </div>
                        <div class="flex justify-between gap-5 p-1">
                            <div><label for="lieu">Lieu</label></div>
                            <div><input type="text" name="lieu" class="rounded border w-full border-gray-300 px-1 py-1" v-model="client.lieu"></div>
                        </div>
                        <div class="flex justify-between gap-5 p-1">
                            <div><label for="Besoin">Besoin</label></div>
                            <div><input type="text" name="Besoin" class="rounded border w-full border-gray-300 px-1 py-1 " v-model="client.besoin"></div>
                        </div>
                        <div class="flex justify-between gap-5 p-1">
                            <div><label for="Besoin">Catégorie</label></div>
                            <div class="w-7/12">
                                <select v-model="client.nomcat" class="border border-gray-300 px-1 py-1 w-full rounded ">
                                                        <option v-for="categorie in categories" :key="categorie.nomcat"
                                                            :value="categorie.nomcat">{{ categorie.nomcat }}</option>
                                                    </select>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="flex max-lg:justify-between  lg:justify-end items-center gap-x-5 w-full">
                    <div class="max-lg:w-1/2"><button @click="modal_ajout = false" class="border-gray-600 border  text-gray-600 w-full py-1 px-3 hover:cursor-pointer rounded">Annuler</button>
                    </div>
                    <div class="max-lg:w-1/2"><button class="bg-blue-400 hover:bg-blue-500 text-white py-1 px-3 rounded w-full" type="submit">Enregitrer</button></div>
                </div>
            </form>
        </div>
    </Modal>
    <!--modal modification-->
    <Modal :isOpen="modal_modif" @close="modal_modif=false">
        <div class="flex justify-center items-center">
            <h5 class="text-2xl font-semibold">Modification</h5>
        </div>
        <div class="">
            <form action="http://127.0.0.1:8000/api/modification_client_seera" method="POST" @submit.prevent="modificationClient">
                <input v-model="idcli" type="hidden">
                <div class="md:grid md:grid-cols-2 gap-x-6">
                    <div class="flex justify-between gap-5 p-1 ">
                        <div><label for="Nom">Nom :</label></div>
                        <div><input type="text" name="Nom" class="rounded border w-full border-gray-300 py-1 px-1" v-model="nom"></div>
                    </div>
                    <div class="flex justify-between gap-5 p-1">
                        <div><label for="Contact">Contact :</label></div>
                        <div><input type="text" name="Contact" class="rounded border w-full border-gray-300 py-1 px-1" v-model="contact"></div>
                    </div>
                    <div class="flex justify-between gap-5 p-1">
                        <div><label for="Email">Email :</label></div>
                        <div><input type="email" name="Email" class="rounded border w-full border-gray-300 py-1 px-1" v-model="email"></div>
                    </div>
                    <div class="flex justify-between gap-5 p-1">
                        <div><label for="Adresse">Adresse :</label></div>
                        <div><input type="text" name="Adresse" class="rounded border w-full border-gray-300 py-1 px-1" v-model="adresse"></div>
                    </div>
                    <div class="flex justify-between gap-5 p-1">
                        <div><label for="Produit demandé">Produit demandé :</label></div>
                        <div><input type="text" name="Produit demandé" class="rounded w-full border border-gray-300 py-1 px-1 " v-model="prodDemande">
                        </div>
                    </div>
                    <div class="flex justify-between gap-5 p-1">
                        <div><label for="quantite">Quantite :</label></div>
                        <div><input type="number" name="quantite" class="rounded border w-full border-gray-300 py-1 px-1" v-model="quantite">
                        </div>
                    </div>
                    <div class="flex justify-between gap-5 p-1">
                        <div><label for="unite">Unite :</label></div>
                        <div><input type="text" name="unite" class="rounded border w-full border-gray-300 py-1 px-1" v-model="unite"></div>
                    </div>
                    <div class="flex justify-between gap-5 p-1">
                        <div><label for="PrixUnitaire">Prix unitaire :</label></div>
                        <div><input type="number" name="PrixUnitaire" class="rounded border w-full border-gray-300 py-1 px-1 " v-model="prixUnitaire">
                        </div>
                    </div>
                    <div class="flex justify-between gap-5 p-1">
                        <div><label for="description">Description :</label></div>
                        <div><input type="text" name="quantite" class="rounded border w-full border-gray-300 py-1 px-1" v-model="description">
                        </div>
                    </div>
                    <div class="flex justify-between gap-5 p-1">
                        <div><label for="lieu">Lieu :</label></div>
                        <div><input type="text" name="lieu" class="rounded border w-full border-gray-300 py-1 px-1" v-model="lieu"></div>
                    </div>
                    <div class="flex justify-between gap-5 p-1">
                        <div><label for="Besoin">Besoin :</label></div>
                        <div><input type="text" name="Besoin" class="rounded border w-full border-gray-300 py-1 px-1 " v-model="besoin"></div>
                    </div>
                    <div class="flex justify-between gap-5 p-1">
                        <div><label for="Besoin">Catégorie :</label></div>
                        <div class="w-7/12">
                            <select v-model="nomcat" class="rounded border  border-gray-300 py-1 px-1 w-full">
                                                    <option v-for="categorie in categories" :key="categorie.nomcat"
                                                        :value="categorie.nomcat" required>{{ categorie.nomcat }}</option>
                                                </select>
                        </div>
                    </div>
                    <div class="flex justify-between gap-5 w-full p-1">
                        <div><label for="Besoin">Vérification</label></div>
                        <div class="w-7/12">
                            <select v-model="verification" @change="verificationChange" class="border border-gray-300 px-1 py-1 rounded w-full">
                                                    <option value="vrai">vrai</option>
                                                    <option value="faux">faux</option>
                                                </select>
                        </div>
                    </div>
                    <div class="flex justify-between gap-5 w-full p-1">
                        <div><label for="Besoin">Province</label></div>
                        <div class="w-7/12">
                            <select v-model="province" @change="provinceChange" class="border border-gray-300 px-1 py-1 rounded w-full">
                                                    <option value="Antananarivo">Antananarivo</option>
                                                    <option value="Fianarantsoa">Fianarantsoa</option>
                                                    <option value="Mahajanga">Mahajanga</option>
                                                    <option value="Toliara">Toliara</option>
                                                    <option value="Toamasina">Toamasina</option>
                                                    <option value="Antsiranana">Antsiranana</option>
                                                </select>
                        </div>
                    </div>
                    <div class="flex justify-between gap-5 w-full p-1">
                        <div><label for="Besoin">Région</label></div>
                        <div class="w-7/12">
                            <select v-model="region" @change="regionChange" class="border border-gray-300 px-1 py-1 rounded w-full">
                                                    <option value="Menabe">Menabe</option>
                                                    <option value="Atsimo Atsinanana">Atsimo Atsinanana</option>
                                                    <option value="Vatovavy">Vatovavy</option>
                                                    <option value="Fitovinany">Fitovinany</option>
                                                    <option value="Analamanga">Analamanga</option>
                                                    <option value="Vakinakaratra">Vakinakaratra</option>
                                                </select>
                        </div>
                    </div>
                </div>
                <div class="flex max-lg:justify-between lg:justify-end w-full items-center gap-3 p-1">
                    <div class="max-lg:w-1/2"><button type="button" @click="modal_modif = false" class="border-gray-600 px-3 py-1 text-gray-600 border rounded hover:cursor-pointer w-full">Annuler</button>
                    </div>
                    <div class="max-lg:w-1/2"><button class="bg-blue-400 hover:bg-blue-500 rounded px-3 py-1 w-full text-white" type="submit">Enregistrer</button></div>
                </div>
            </form>
        </div>
    </Modal>
    <!--modal de suppresion-->
    <Modal :isOpen="modal_suppr" @close="modal_suppr=false">
        <div class="flex justify-center items-center">
            <p class="text-2xl font-bold ">Suppression</p>
            <input v-model="idcli1" type="hidden">
        </div><br>
        <div>
            <h3>Voulez-vous supprimer ce client ?</h3>
        </div><br>
        <div class="flex max-lg:justify-between lg:justify-end w-full  gap-3"><br>
            <button @click="modal_suppr = false" class="rounded px-3 h-8 border text-gray-600 text-center border-gray-600  hover:cursor-pointer w-1/2 flex justify-center items-center">
                            Annuler</button>
            <button class="rounded px-3 h-8 bg-blue-400 hover:bg-blue-500 hover:cursor-pointer text-white w-1/2 flex justify-center items-center" @click="suppressionClient">Supprimer</button>
        </div>
    </Modal>
    <!--modal details-->
    <Modal :isOpen="modal_details" @close="modal_details=false">
        <div class="flex justify-center">
            <div>
                <h5 class="text-2xl font-semibold">Détails</h5>
            </div>
        </div>
        <div class="flex justify-center gap-5">
            <div>
                <form>
                    <div class="md:grid md:grid-cols-2 gap-2">
                        <div class="flex justify-between gap-5 p-1 ">
                            <div><label for="Nom">Nom :</label></div>
                            <div><input type="text" name="Nom" class="rounded border w-full border-gray-300 py-1 px-1" disabled v-model="nom">
                            </div>
                        </div>
                        <div class="flex justify-between gap-5 p-1 ">
                            <div><label for="Contact">Contact :</label></div>
                            <div><input type="text" name="Contact" class="rounded border w-full border-gray-300 py-1 px-1" disabled v-model="contact"></div>
                        </div>
                        <div class="flex justify-between gap-5 p-1 ">
                            <div><label for="Email">Email :</label></div>
                            <div><input type="email" name="Email" class="rounded border w-full border-gray-300 py-1 px-1" disabled v-model="email"></div>
                        </div>
                        <div class="flex justify-between gap-5 p-1">
                            <div><label for="Adresse">Adresse :</label></div>
                            <div><input type="text" name="Adresse" class="rounded border  w-full border-gray-300 py-1 px-1" disabled v-model="adresse"></div>
                        </div>
                        <div class="flex justify-between gap-5 p-1">
                            <div><label for="Produit demandé">Produit demandé :</label></div>
                            <div><input type="text" name="Produit demandé" class="rounded  w-full border border-gray-300 py-1 px-1 " disabled v-model="prodDemande"></div>
                        </div>
                        <div class="flex justify-between gap-5 p-1 ">
                            <div><label for="quantite">Quantite :</label></div>
                            <div><input type="number" name="quantite" class="rounded border  w-full border-gray-300 py-1 px-1" disabled v-model="quantite"></div>
                        </div>
                        <div class="flex justify-between gap-5 p-1 ">
                            <div><label for="unite">Unite :</label></div>
                            <div><input type="text" name="unite" class="rounded border  w-full border-gray-300 py-1 px-1" disabled v-model="unite"></div>
                        </div>
                        <div class="flex justify-between gap-5 p-1 ">
                            <div><label for="PrixUnitaire">Prix unitaire :</label></div>
                            <div><input type="number" name="PrixUnitaire" class="rounded border  w-full border-gray-300 py-1 px-1 " disabled v-model="prixUnitaire"></div>
                        </div>
                        <div class="flex justify-between gap-5  p-1">
                            <div><label for="description">Description :</label></div>
                            <div><input type="text" name="quantite" class="rounded border  w-full border-gray-300 py-1 px-1" disabled v-model="description"></div>
                        </div>
                        <div class="flex justify-between gap-5 p-1">
                            <div><label for="lieu">Lieu :</label></div>
                            <div><input type="text" name="lieu" class="rounded border  w-full border-gray-300 py-1 px-1" disabled v-model="lieu"></div>
                        </div>
                        <div class="flex justify-between gap-5 p-1">
                            <div><label for="Besoin">Besoin :</label></div>
                            <div><input type="text" name="Besoin" class="rounded border  w-full border-gray-300 py-1 px-1 " disabled v-model="besoin"></div>
                        </div>
                        <div class="flex justify-between gap-5t p-1">
                            <div><label for="Besoin">Catégorie :</label></div>
                            <div>
                                <input type="text" name="Besoin" class="rounded border  w-full border-gray-300 py-1 px-1 " disabled v-model="nomcat">
                            </div>
                        </div>
                        <div class="flex justify-between gap-5t p-1">
                            <div><label for="Besoin">Province :</label></div>
                            <div>
                                <input type="text" name="Besoin" class="rounded border  w-full border-gray-300 py-1 px-1 " disabled v-model="province">
                            </div>
                        </div>
                        <div class="flex justify-between gap-5t p-1">
                            <div><label for="Besoin">Région :</label></div>
                            <div>
                                <input type="text" name="Besoin" class="rounded border  w-full border-gray-300 py-1 px-1 " disabled v-model="region">
                            </div>
                        </div>
                        <div class="flex justify-between  gap-5 p-1">
                            <div><label for="description">Vérification :</label></div>
                            <div><input type="text" name="quantite" class="rounded border  w-full border-gray-300 py-1 px-1" disabled v-model="verification"></div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </Modal>
</template>
<style >
    .v-enter-active,
    .v-leave-active {
        transition: transform 0.6s;
    }
    .v-enter-from,
    .v-leave-to {
        transform: translateX(-500px)
    }
</style>
<script>
    import apiUrl from '../../ApiConfig';
    import axios from 'axios'
    import Menu from '@/components/Admin/Menu.vue'
    import Header from '@/components/Admin/Header.vue'
    import Filtre from '@/components/Admin/Filtre.vue'
    import '@/assets/styleAdmin/page.css'
    import Modal from "@/components/modal/modal.vue"
     import Sidebar from '@/components/Admin/sidebar/sidebar.vue'
    export default {
        // eslint-disable-next-line vue/multi-word-component-names
        name: 'Client',
        components: {
            Menu,
            Filtre,
            Header,
            Modal,Sidebar
        },
        data() {
            return {
                modal_menu: false,
                menu_item: [{
                        title: 'Dashboard',
                        link: '/Admin/Dashboard',
                        logo: 'fa fa-house '
                    },
                    {
                        title: 'Comparaison',
                        link: '/Admin/Comparaison',
                        logo: 'fas fa-chart-line'
                    },
                    {
                        title: 'Categorie',
                        link: '/Admin/Categorie',
                        logo: 'fa fa-list-check '
                    },
                    {
                        title: 'Utilisateur',
                        link: '/Admin/Utilisateur',
                        logo: 'fa fa-users '
                    },
                    {
                        title: 'Abonnement',
                        link: '/Admin/subscription',
                        logo: 'fa fa-users '
                    },
                    {
                        title: 'Client',
                        link: '/Admin/Client',
                        logo: 'fa fa-user'
                    },
                    {
                        title: 'Fournisseur',
                        link: '/Admin/Fournisseur',
                        logo: 'fa fa-house '
                    },
                    {
                        title: 'Investisseur',
                        link: '/Admin/Investisseur',
                        logo: 'fa fa-money-check '
                    },
                    {
                        title: 'Message',
                        link: '/Admin/Message',
                        logo: 'fas fa-message '
                    },
                ],
                //ovaina ny nom n variable name-page
                name_page: this.$options.name,
                modal_modif: false,
                modal_ajout: false,
                modal_suppr: false,
                modal_details: false,
                //input dans filtrage
                Prix1: '',
                Prix2: '',
                categories: [],
                Produit: '',
                inpCat: '',
                inpPrix1: '',
                inpPrix2: '',
                inpProduit: '',
                //input modal ajout
                idcli: '',
                nom: '',
                contact: '',
                email: '',
                adresse: '',
                prodDemande: '',
                quantite: '',
                unite: '',
                prixUnitaire: '',
                description: '',
                lieu: '',
                besoin: '',
                nomcat: '',
                province: '',
                region: '',
                verification: '',
                //input modal modif et details
                idcli1: '',
                province1: '',
                clients: [],
                item: {
                    idcat: '',
                    nomcat: ""
                },
                client: {
                    idcli: '',
                    nom: '',
                    contact: '',
                    email: '',
                    adresse: '',
                    prodDemande: '',
                    quantite: '',
                    unite: '',
                    prixUnitaire: '',
                    description: '',
                    lieu: '',
                    besoin: '',
                    nomcat: '',
                    verification: '',
                    province: '',
                    region: ''
                }
            }
        },
        mounted() {
            this.getClients();
            this.categorieSelection();
        },
        methods: {
            data_modal_ajout(data) {
                this.modal_ajout = data
            },
            receive_data_filter(props1, props2, props3, props4) {
                this.Prix1 = props1
                this.Prix2 = props2
                this.categories = props3
                this.Produit = props4
                this.rechercheClient()
            },
            rechercheClient() {
                try {
                    axios.get(apiUrl + 'api/recherche_client_seera', {
                        params: {
                            inpProduit: this.inpProduit,
                            inpPrix1: this.inpPrix1,
                            inpPrix2: this.inpPrix2,
                            inpCat: this.inpCat
                        }
                    }).then(response => {
                        this.clients = response.data
                    }).catch(error => {
                        console.log(error)
                    })
                } catch (error) {
                    console.error(error)
                }
            },
            getClients() {
                try {
                    axios.get(apiUrl + 'api/affichage_client_seera').then(response => {
                        this.clients = response.data
                        // eslint-disable-next-line no-undef
                    }).catch(error => {
                        console.log(error)
                        this.clients = []
                    })
                } catch (error) {
                    console.error(error)
                }
            },
            categorieSelection() {
                try {
                    axios.get(apiUrl + 'api/affichage_nom_categorie').then(response => {
                        this.categories = response.data
                    }).catch(error => {
                        console.log(error)
                    })
                } catch (error) {
                    console.error(error)
                }
            },
            ajoutClient() {
                // eslint-disable-next-line no-unused-vars
                try {
                    axios.post(apiUrl + 'api/ajout_client_seera', this.client).then(response => {
                            if (confirm('Client ajouté avec succès')) {
                                this.getClients();
                                this.client.nom = "";
                                this.client.contact = "";
                                this.client.email = "";
                                this.client.adresse = "";
                                this.client.prodDemande = "";
                                this.client.quantite = "";
                                this.client.unite = "";
                                this.client.prixUnitaire = "";
                                this.client.description = "";
                                this.client.lieu = "";
                                this.client.besoin = "";
                                this.modal_ajout = false;
                                this.client.nomcat = "";
                                this.client.verification = "";
                                this.client.province = "";
                                this.client.region = "";
                            }
                        })
                        .catch(error => {
                            (
                                console.log(error)
                            )
                        });
                } catch (error) {
                    console.error(error)
                }
            },
            provinceChange() {
                // console.log('Province sélectionnée :', this.province)
                this.province = this.province;
            },
            verificationChange() {
                // console.log('Vérification sélectionnée :', this.verification);
                this.verification = this.verification;
            },
            regionChange() {
                // console.log('Région sélectionnée :', this.region);
                this.region = this.region;
            },
            modificationClient() {
                try {
                    axios.post(apiUrl + 'api/modification_client_seera', {
                        idcli: this.idcli,
                        nom: this.nom,
                        contact: this.contact,
                        email: this.email,
                        adresse: this.adresse,
                        // eslint-disable-next-line no-unused-vars
                        prodDemande: this.prodDemande,
                        quantite: this.quantite,
                        unite: this.unite,
                        prixUnitaire: this.prixUnitaire,
                        description: this.description,
                        lieu: this.lieu,
                        besoin: this.besoin,
                        nomcat: this.nomcat,
                        verification: this.verification,
                        province: this.province,
                        region: this.region
                    }).then(response => {
                        if (confirm('Client modifié avec succès')) {
                            this.modal_modif = false;
                            this.getClients();
                        }
                    }).catch(error => {
                        (
                            console.log(error)
                        )
                    });
                } catch (error) {
                    console.error(error)
                }
            },
            suppressionClient() {
                try {
                    axios.post(apiUrl + 'api/suppression_client_seera', {
                        idcli: this.idcli1
                        // eslint-disable-next-line no-unused-vars
                    }).then(response => {
                        if (confirm('Client supprimé avec succès')) {
                            this.modal_suppr = false;
                            this.getClients();
                        }
                    }).catch(error => {
                        (
                            console.log(error)
                        )
                    });
                } catch (error) {
                    console.error(error)
                }
            },
            init() {
                this.inpProduit = '', this.inpPrix1 = '', this.inpPrix2 = '', this.inpCat = ''
            },
        }
    }
</script>
<!-- eslint-disable vue/require-v-for-key -->
<template>

    <div class="gray max-lg:w-screen lg:w-10/12 float-right h-screen flexbox  justify-center ">
        <div class="bg-white max-sm:p-2 sm:h-fit mx-4 flex items-center rounded mb-3">
            <div class="flex p-2 justify-center items-center lg:hidden">
                <div class="fas fa-bars fa-2x text-gray-600 cursor-pointer" @click="modal_menu = !modal_menu"></div>
            </div>
            <Header :name_page="name_page" class="p-2"></Header>
        </div>
        <div class="bg-white h-fit mx-4 grid items-center rounded p-2">

            <div class="flex  justify-between items-center gap-6">
                <div class=" w-4/12 flex max-sm:justify-start items-center">

                </div>
                <div class=" flex justify-end items-center w-7/12 ">
                    <div class="flex justify-end items-center w-6/12">
                        <div class="flex  items-center  justify-between border rounded border-gray-400 w-full">
                            <div class="flex ">
                                <input type="text" name="" id="" v-model="nomRecherche" placeholder="Rechercher..."
                                    class="p-1 focus:outline-none placeholder:text-gray-300">
                            </div>
                            <div class="flex justify-center items-center p-1  min-sm:px-4">
                                <div class="fa fa-search px-2 " @click="rechercheUtilisateur"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>


        <div class="bg-white h-fit mx-4 flex items-center justify-center rounded my-3 ">
            <div class="w-full text-xs sm:text-base px-2">
                <table class="w-full max-w-full  my-2 overflow-x-auto">
                    <thead class="text-left bg-blue-50 font-semibold">
                        <th class="hidden">Id</th>
                        <th>Nom</th>
                        <th>E-mail</th>
                        <th>Contact</th>
                        <th>Adresse</th>
                        <th>Statut</th>
                        <th class="hidden">Role</th>
                        <th style="display: none;">Mot de passe</th>
                        <th></th>
                    </thead>
                    <tbody v-if="utilisateurs.length > 0" class="divide-y">
                        <tr v-for="utilisateur in utilisateurs" :key="utilisateur.id" class="hover:bg-blue-50 ">
                            <td class="hidden">{{ utilisateur.id }}</td>
                            <td>{{ utilisateur.name }}</td>
                            <td>{{ utilisateur.email }}</td>
                            <td>{{ utilisateur.contact }}</td>
                            <td>{{ utilisateur.adresse }}</td>
                            <td>{{ utilisateur.statut }}</td>
                            <td class="hidden">{{ utilisateur.role }}</td>
                            <td style="display: none;">{{ utilisateur.password }}</td>

                            <td class="flex gap-2 mr-4 max-lg:p-3 justify-center items-center py-2">
                                <div @click="modal_details = true, id = utilisateur.id, name = utilisateur.name, email = utilisateur.email, contact = utilisateur.contact, adresse = utilisateur.adresse, role = utilisateur.role, password = utilisateur.password, statut = utilisateur.statut"
                                    class="fa fa-eye text-blue-500 active:bg-blue-400"></div>

                                <!-- <div @click="modal_modif=true, id=utilisateur.id,name=utilisateur.name,email=utilisateur.email,contact=utilisateur.contact,role=utilisateur.role,password=utilisateur.password" class="fa fa-pencil-alt text-green-400  active:bg-gray-200" ></div> -->
                                <!-- <div @click="modal_suppr=true, id1=utilisateur.id" class="fa fa-trash text-red-300 active:bg-gray-200"></div> -->
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>


    </div>
    <Menu></Menu>


    <!-- modal de suppresion
<div v-show="modal_suppr" class="modal_ajout fixed z-30 w-screen h-screen bg-black bg-opacity-60 grid justify-center items-center">
    <div class="h-fit w-fit bg-white rounded py-5 px-20 gap-y-6 gap-x-5 flex flex-col">
        <div>
            <h1>Suppression  de Client</h1>
        </div>
        <div class="flex justify-around gap-5">
            <div @click="modal_suppr=false" class="rounded p-2 bg-gray-300">Annuler</div>
            <div class="rounded p-2 bg-blue-300">Supprimer</div>
        </div>
    </div>
</div> -->
    <!--modal details-->
    
    <Modal :isOpen="modal_details" @close="modal_details=false">
<div class="flex justify-center">
                <div>
                    <h5 class="font-semibold pb-4">Détails d'utilisateur</h5>
                </div>
            </div>
            <div class="flex justify-center gap-5 w-full px-2">
                <div class="w-full">
                    <form class="w-full">
                        <div class="flex  text-left w-full">
                            <div class="w-full">
                                <div class="flex justify-between items-center gap-x-3 p-1 ">
                                    <label for="Nom" class="w-16">Nom </label>
                                    <input type="text" name="name"
                                        class=" py-1 px-1 rounded border focus:outline-none  bg-white w-full " disabled
                                        v-model="name">
                                </div>
                                <div class="flex justify-between items-center gap-x-3 p-1">
                                    <label for="Email" class="w-16">E-mail </label>
                                    <input type="text" name="Contact"
                                        class=" py-1 px-1 rounded border focus:outline-none  bg-white w-full " disabled
                                        v-model="email">
                                </div>
                                <div class="flex justify-between items-center gap-x-3 p-1">
                                    <label for="Email" class="w-16">Contact </label>
                                    <input type="text" name="Email"
                                        class=" py-1 px-1 rounded border focus:outline-none  bg-white w-full " disabled
                                        v-model="contact">
                                </div>
                                <div class="flex justify-between items-center gap-x-3 p-1">
                                    <label for="Adresse" class="w-16">Adresse </label>
                                    <input type="text" name="Adresse"
                                        class=" py-1 px-1 rounded border focus:outline-none   bg-white w-full " disabled
                                        v-model="adresse">
                                </div>
                                <div class="flex justify-between items-center gap-x-3 p-1">
                                    <label for="Adresse" class="w-16">Statut</label>
                                    <input type="text" name="Statut" class=" py-1 px-1 rounded bg-white w-full "
                                        disabled v-model="statut">
                                </div>

                            </div>

                        </div>
                    </form>
                </div>
            </div>
</Modal>
    <!-- modal menu pour mobile -->
    <Sidebar :modal_menu="modal_menu" @close="modal_menu=false"></Sidebar>
</template>
<style >
.v-enter-active,
.v-leave-active {
    transition: transform 0.6s;
}

.v-enter-from,
.v-leave-to {
    transform: translateX(-500px)
}
</style>
<script>
import apiUrl from '../../ApiConfig';
import axios from 'axios'
import Menu from '@/components/Admin/Menu.vue'
import Header from '@/components/Admin/Header.vue'
import Filtre from '@/components/Admin/Filtre.vue'
import '@/assets/styleAdmin/page.css'
 import Sidebar from '@/components/Admin/sidebar/sidebar.vue'
import Modal from "@/components/modal/modal.vue"
export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: 'Utilisateur',
    components: {
        Menu,
        // eslint-disable-next-line vue/no-unused-components
        Filtre,
        Header,Modal,Sidebar
    },
    data() {
        return {
            modal_menu: false,
            menu_item: [
                { title: 'Dashboard', link: '/Admin/Dashboard', logo: 'fa fa-house ' },
                { title: 'Comparaison', link: '/Admin/Comparaison', logo: 'fas fa-chart-line' },
                { title: 'Categorie', link: '/Admin/Categorie', logo: 'fa fa-list-check ' },
                { title: 'Utilisateur', link: '/Admin/Utilisateur', logo: 'fa fa-users ' },
                { title: 'Abonnement', link: '/Admin/subscription', logo: 'fa fa-users ' },
                { title: 'Client', link: '/Admin/Client', logo: 'fa fa-user' },
                { title: 'Fournisseur', link: '/Admin/Fournisseur', logo: 'fa fa-house ' },
                { title: 'Investisseur', link: '/Admin/Investisseur', logo: 'fa fa-money-check ' },
                {
                    title: 'Message',
                    link: '/Admin/Message',
                    logo: 'fas fa-message '
                },
            ],
            nomRecherche: '',
            utilisateurs: [],
            utilisateur: {
                id: '', name: '', email: '', contact: '', adresse: '', statut: '', role: '', password: ''
            },
            id: '', name: '', email: '', contact: '', adresse: '', statut: '', role: '', password: '',


            //ovaina ny nom n variable name-page
            name_page: this.$options.name,
            modal_modif: false,
            modal_ajout: false,
            modal_suppr: false,
            modal_details: false,
            Prix1: '',
            Prix2: '',
            Categories: ['asasd', 'asdasd'],
            Produit: '',
            Recherche: '', inpPrix1: '',
            inpPrix2: '',
            inpCat: '',
            inpProduit: '',
        }
    },

    mounted() {
        this.getUtilisateurs();
    },

    methods: {

        getUtilisateurs() {
            try {
                axios.get(apiUrl + 'api/affichage_utilisateur_seera').then(response => {
                    this.utilisateurs = response.data
                    // eslint-disable-next-line no-undef

                }).catch(error => {
                    console.log(error)
                    this.utilisateurs = []
                })
            } catch (error) {
                console.error(error)
            }
        },

        rechercheUtilisateur() {
            try {
                axios.get(apiUrl + 'api/recherche_utilisateur_seera', { params: { nomRecherche: this.nomRecherche } }).then(response => {
                    this.utilisateurs = response.data
                    // eslint-disable-next-line no-undef

                }).catch(error => {
                    console.log(error)
                    this.utilisateurs = []
                })
            } catch (error) {
                console.error(error)
            }
        },

        data_modal_ajout(data) {
            this.modal_ajout = data

        },
        receive_data_filter(props1, props2, props3, props4, props5) {
            this.Prix1 = props1
            this.Prix2 = props2
            this.Categorie = props3
            this.Produit = props4
            this.Recherche = props5

        }
    }
}
</script>
